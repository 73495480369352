import React, { useEffect, useState } from "react";
import { Button, Form, Card, ListGroup, Col } from "react-bootstrap";
import { LoginContainer, LoginHeader, UserDetailsContainer } from "./wrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import vehicleRoutingIcon from "../../assests/images/gps.png";
import productSummaryIcon from "../../assests/images/product-summary.png";
import vehicleDetailsIcon from "../../assests/images/shipped.png";
import orderDetailsIcon from "../../assests/images/order.png";
import ordersLitingIcon from "../../assests/images/orderListing.png";
import loosePackingIcon from "../../assests/images/package.png";
import inventoryIcon from "../../assests/images/inventory.png";
import routePlanningIcon from "../../assests/images/delivery.png";
import paymentManagementIcon from "../../assests/images/payments.png";
import k1 from "../../assests/images/k1.png";

import NavBar from "../Navigation bar/Navbar";
import FooterComponent from "../Footer/footer";
import fire from "../../Configs/firebase";
import {
  validateIsOperationUser,
  validateIsBillingUser,
  validateIsAdminUser,
  validateIsPackingUser,
  validateIsFinanceUser,
} from "../UserCredentials";

let allowedRoutesSet = new Set();

export default function Home(props) {
  let userType = sessionStorage.getItem("originalUserType");
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  useEffect(() => {
    if (userType && userLoggedIn == false) {
      fire
        .database()
        .ref(`User_Role_Access/DeliveryPortal/${userType}/allowed_routes`)
        .once("value")
        .then((snapshot) => {
          let userRoleDetails = snapshot.val();
          console.log(userRoleDetails);

          if (userRoleDetails) {
            allowedRoutesSet = new Set();
            let userRoutes = Object.values(userRoleDetails);
            for (let i = 0; i < userRoutes.length; i++) {
              let route = `/${userRoutes[i]}`;
              if (!allowedRoutesSet.has(route)) {
                allowedRoutesSet.add(route);
              }
            }
            console.log(allowedRoutesSet);
            sessionStorage.setItem("allowedRoutes", JSON.stringify(userRoutes));
          } else {
            sessionStorage.setItem("allowedRoutes", JSON.stringify([]));
          }
          setUserLoggedIn(true);
        });
    }
  }, []);

  const renderHeaderList = (title, variant, menuItems) => {
    const filteredItems = menuItems
      .filter(
        (item) =>
          allowedRoutesSet.has(item.path) ||
          validateIsAdminUser(JSON.parse(sessionStorage.getItem("user"))[0].email)
      )
      .map((item) => (
        <ListGroup.Item key={item.path} action href={item.path}>
          <i className="fa fa-bars" aria-hidden="true"></i> {item.label}
        </ListGroup.Item>
      ));
  
    return filteredItems.length > 0 ? (
      <ListGroup style={{ width: "200px", fontSize: "16px" }}>
        <ListGroup.Item variant={variant}>{title}</ListGroup.Item>
        {filteredItems}
      </ListGroup>
    ) : null;
  }

  return (
    <>
      <NavBar />
      <LoginContainer>
        <LoginHeader>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <span></span> {/* for logo, so don't remove the span */}
        </LoginHeader>

        <UserDetailsContainer>
          <Form style={{ margin: "10px" }}>
            <Form.Row>
            <Form.Group as={Col}>
              {renderHeaderList("Inventory", "danger", [
              { path: "/inventory-movements", label: "Inventory Details" },
              { path: "/transit-stock-transfer", label: "Transit Stock Transfer" },
              { path: "/transit-stock-return", label: "Transit Stock Return" },
              { path: "/stock-discrepancy", label: "Stock Discrepancy" },
              { path: "/stock-correction", label: "Stock Correction" },
              { path: "/bag-check-report", label: "Bag Check Report" },
              { path: "/stockCheckingReport", label: "Delivery Stock Check Report" },
              ])}
           </Form.Group>
              <Form.Group as={Col}>
                <ListGroup style={{ width: "200px", fontSize: "16px" }}>
                  <ListGroup.Item variant="primary">
                    <FontAwesomeIcon icon="fa-regular fa-truck" /> Pre Delivery
                  </ListGroup.Item>

                  {allowedRoutesSet.has("/loosepackorders") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) ? (
                    <ListGroup.Item action href="/loosepackorders">
                      <i class="fa fa-bars" aria-hidden="true"></i> Loose
                      Packing
                    </ListGroup.Item>
                  ) : (
                    ""
                  )}


            {allowedRoutesSet.has("/highValuePacks") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) ? (
                    <ListGroup.Item action href="/highValuePacks">
                      <i class="fa fa-bars" aria-hidden="true"></i> High
                      Value Packing
                    </ListGroup.Item>
                  ) : (
                    ""
                  )}    

                  {allowedRoutesSet.has("/productsummary") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) ? (
                    <ListGroup.Item action href="/productsummary">
                      <i class="fa fa-bars" aria-hidden="true"></i> Product
                      Summary
                    </ListGroup.Item>
                  ) : (
                    ""
                  )}

                  {allowedRoutesSet.has("/picking") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) ? (
                    <ListGroup.Item action href="/picking">
                      <i class="fa fa-bars" aria-hidden="true"></i> Picking
                    </ListGroup.Item>
                  ) : (
                    ""
                  )}
                  
                  <ListGroup.Item action href="/palletSticker">
                      <i class="fa fa-bars" aria-hidden="true"></i> Pallet Sticker Printing
                    </ListGroup.Item>
                  

                </ListGroup>
              </Form.Group>
              <Form.Group as={Col}>
                <ListGroup style={{ width: "200px", fontSize: "16px" }}>
                  <ListGroup.Item variant="secondary">
                    Delivery Details
                  </ListGroup.Item>

                  {allowedRoutesSet.has("/delivery-summary") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/delivery-summary">
                      <i className="fa fa-bars" aria-hidden="true"></i> Delivery
                      Summary
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/driver-performance") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/driver-performance">
                      <i className="fa fa-bars" aria-hidden="true"></i> Driver
                      Performance
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/transporter-payments") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/transporter-payments">
                      <i className="fa fa-bars" aria-hidden="true"></i>{" "}
                      Transporter Payments
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/vehicle-details") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/vehicle-details">
                      <i className="fa fa-bars" aria-hidden="true"></i> Vehicle
                      Details
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/routing") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/routing">
                      <i className="fa fa-bars" aria-hidden="true"></i> Vehicle
                      Routing
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/Fresh") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/Fresh">
                      <i className="fa fa-bars" aria-hidden="true"></i> Fresh
                      Summary
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/VariableCost") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/WeeklyVariableCost">
                      <i className="fa fa-bars" aria-hidden="true"></i> Weekly Variable Cost
                    </ListGroup.Item>
                  ) : null}
                </ListGroup>
              </Form.Group>
              <Form.Group as={Col}>
                <ListGroup style={{ width: "200px", fontSize: "16px" }}>
                  <ListGroup.Item variant="success">
                    Order Management
                  </ListGroup.Item>

                  {allowedRoutesSet.has("/orderlist") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/orderlist">
                      <i className="fa fa-bars" aria-hidden="true"></i> All
                      Orders (List)
                    </ListGroup.Item>
                  ) : null}

                  {allowedRoutesSet.has("/orders") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/orders">
                      <i className="fa fa-bars" aria-hidden="true"></i> Planned
                      Orders (Details)
                    </ListGroup.Item>
                  ) : null}
                  {allowedRoutesSet.has("/addreturnadjustmentorders") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/addreturnadjustmentorders">
                      <i className="fa fa-bars" aria-hidden="true"></i> Gate
                      Pass / Customer Returns Orders
                    </ListGroup.Item>
                  ) : null}
                  {allowedRoutesSet.has("/returnedorders") ||
                  validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem("user"))[0].email
                  ) ? (
                    <ListGroup.Item action href="/returnedorders">
                      <i className="fa fa-bars" aria-hidden="true"></i> Return
                      Orders
                    </ListGroup.Item>
                  ) : null}
                </ListGroup>
              </Form.Group>
              <Form.Group as={Col}>
                {renderHeaderList("Payments", "warning", [
                { path: "/cheque-management", label: "Cheque Management" },
                { path: "/cash-management", label: "Cash Management" },
                { path: "/payment-pending", label: "Payment Pending" },
                { path: "/super-kredit", label: "Super Kredit" },
                { path: "/scratch-card-transactions", label: "Scratch Card" },
                { path: "/Finaleap", label: "Finaleap Management" },
                { path: "/Epay", label: "Epay Management" },
                ])}
              </Form.Group>

            </Form.Row>
          </Form>
        </UserDetailsContainer>
      </LoginContainer>
      <FooterComponent />
    </>
  );
}
