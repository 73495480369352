import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import fire from "../../Configs/firebase";
import { warehouseNames } from "../../Configs/mysqlconfig";
import {
  Tab,
  Row,
  Col,
  Nav,
  Form,
  Dropdown,
  Tabs,
  Image,
  InputGroup,
  Button,
  DropdownButton,
  Breadcrumb,
  Table,
  ButtonGroup,
  Container,
  Accordion,
  Card,
  Navbar,
  Overlay,
  Tooltip,
  OverlayTrigger,
  Modal,
} from "react-bootstrap";
import {
  InputGroupAddon,
  Input,
  InputGroupText,
  CardHeader,
  CardTitle,
  CardText,
} from "reactstrap";
// CSS Modules, react-datepicker-cssmodules.css
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import Backdrop from "../backdrop/backdrop";
import Alert from "../Alert/alert";
import ModalWithStatus from "./modal_paymentDetails";
import ModalPartial from "./modal_paymentDetails";
import ModalCancelled from "./modal_cancelled";
import ModalPending from "./modal_pending";
import ModalModifiedItemQty from "./modal_modifiedItemQty";
import ModalDeletedItemQty from "./modal_deletedItemQty";
import PaymentModal from "./paymentListModal";
import ItemDetails from "./itemDetailsModal";
import RecievedReturnProductsModal from "./modal_receivedReturnProducts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faTimes,
  faUndo,
  faCircle,
  faTrash,
  faEdit,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import ModalView from "./modal_paymentDetails";
import NavBar from "../Navigation bar/Navbar";
import FooterComponent from "../Footer/footer";
import ModalEdit from "./modal_paymentDetails";
import ExportConfirmPopup from "./ExportConfirmPopup";
import { IconButton } from "@material-ui/core";
import UpdatedDate from "./UpdatedDate";
import ExportDeliveryDetails from "./ExportDeliveryDetails";
import TotalAmountModalPopup from "./TotalAmountModalPopup";
import RevisedOrderDiscountModalPopup from "./RevisedOrderDiscountModalPopup";
import RevisedOrderAmountModalPopup from "./RevisedOrderAmountModalPopup";
import VehicleDispatchModalPopup from "./VehicleDispatchModalPopup";
import SuperKreditValueUpdatePopup from "./SuperKreditValueUpdatePopup";
import CashbackUpdatePopup from "./CashbackUpdatePopup";
import DeliveryErrorsModalPopup from "./DeliveryErrorsModalPopup";
import DiscrepancyPopup from "./DiscrepancyPopup";
import VehicleStatusModal from "./VehicleStatusModal";

import Switch from "react-switch";
import {
  validateIsOperationUser,
  validateIsBillingUser,
  validateIsAdminUser,
  validateIsFinanceUser,
  validateIsFinanceHeadUser,
  validateIsFinanceMISUser,
  validateIsOtherOrdersMgmtUser,
  validateIsSystemsUser,
  validateIsExportUser
} from "../UserCredentials";
import {
  support_portal_url,
  orderingApiUrl,
  service_url,
  token,
} from "../../Configs/mysqlconfig";
import { toast } from "react-toastify";
import { warehouses } from '../../indexedDBUtility.js';



function formatDateToYYYYMMDD(date) {
  let dateList = date.split("-");
  return `${dateList[2]}-${dateList[1]}-${dateList[0]}`;
}

function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

function Orders(props) {


  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  // Declare a new state variable, which we'll call "count"
  let usermodifying = JSON.parse(sessionStorage.getItem("user"))[0].email;

  let userRoutes = sessionStorage.getItem("allowedRoutes")
    ? JSON.parse(sessionStorage.getItem("allowedRoutes"))
    : [];
  let allowedRoutesSet = new Set();

  for (let i = 0; i < userRoutes.length; i++) {
    let route = `/${userRoutes[i]}`;
    if (!allowedRoutesSet.has(route)) {
      allowedRoutesSet.add(route);
    }
  }

  // edit access check
  let isEditAccessPresent = false;
  if (
    validateIsExportUser(usermodifying) 
  ) {
    isEditAccessPresent = true;
  }
  let selectedInputDate = sessionStorage.getItem("selectedInputDate");
  let isHistoricalEditAccessPresent = false;
  let currentDay = true;
  if (selectedInputDate) {
    selectedInputDate = formatDateToYYYYMMDD(selectedInputDate);

    let dateDifference = dateDiffInDays(
      new Date(selectedInputDate),
      new Date()
    );
    currentDay = dateDifference ==0?true:false;

    if (dateDifference <= 0) {
      if (
        validateIsExportUser(usermodifying) 
      ) {
        isHistoricalEditAccessPresent = true;
      }
    }
    else if (dateDifference <= 1) {
      if (validateIsAdminUser(usermodifying)) {
        isHistoricalEditAccessPresent = true;
      }
    }
  }
  let finalUserEditAccess =
    isHistoricalEditAccessPresent && isEditAccessPresent;

  const userCity = sessionStorage.getItem("userCity") || "";

  const [getDate, setDate] = useState(new Date());
  const [getDB, setDB] = useState(null);
  const [currentDate, setCurrentDate] = useState(
    `${new Date().getDate()}-${
      new Date().getMonth() + 1
    }-${new Date().getFullYear()}`
  );

  const [pickingDate, setPickingDate] = useState(null);
  const [getOrderHistory, setOrderHistory] = useState(null);
  const [getFreshItems, setFreshItems] = useState(null);
  const [getDiscrepancyPopup, setDiscrepancyPopup] = useState(false);
  const [driverRegionNameMap, setDriverRegionNameMap] = useState({});
  const [cityList, setCityList] = useState(null);
  const [getAllocationHistory, setAllocationHistory] = useState(null);
  const [getBackupOrderHistory, setBackupOrderHistory] = useState(null);
  const [getRenderCity, setRenderCity] = useState(null);
  const [getRenderDriver, setRenderDriver] = useState(null);
  const [getRenderOrder, setRenderOrder] = useState(null);
  const [getRenderOrderDetails, setRenderOrderDetails] = useState([]);
  const [getDBExtend, setDBExtend] = useState(null);
  const [getCardAmount, setCardAmount] = useState("");
  const [getMessage, setMessage] = useState("");
  const [getBreadCrumb, setBreadCrumb] = useState([]);
  const [getBreadCrumb2, setBreadCrumb2] = useState([]);
  const [getModal, setModal] = useState(false);
  const [getModalData, setModalData] = useState([0, 0]);
  const [getOrdersPerShop, setOrdersPerShop] = useState({});
  const [keyList, setKeyList] = useState({});
  const [getFireBaseMin, setFireBaseMin] = useState();
  const [getFireBaseMax, setFireBaseMax] = useState();
  const [getFireBaseDriverId, setFireBaseDriverId] = useState();
  const [getFireBaseOrderId, setFireBaseOrderId] = useState();
  const [getBackDrop, setBackDrop] = useState(false);
  const [getItemData, setItemData] = useState();
  const [itemDesc, setItemDesc] = useState("");
  const [itemId, setItemId] = useState("");
  const [orderedItemId, setOrderedItemId] = useState("");
  const [orderedItemDesc, setOrderedItemDesc] = useState("");
  const [getModifiedQty, setModifiedQty] = useState(null);
  const [totalAmtToBePaid, setTotalAmtToBePaid] = useState(null);
  const [getOffersObject, setOffersObject] = useState({});
  const [getCustomOffersObject, setCustomOffersObject] = useState({});
  const [priceDetails, setPriceDetails] = useState([]);
  const [superKreditPopup, setSuperKreditPopup] = useState(false);
  const [superKreditObj, setSuperKreditObj] = useState({});
  const [getSuperZopKreditObj, setSuperZopKreditObj] = useState({});
  const [orderPaymentMode, setOrderPaymentMode] = useState();
  const [totalSuperKreditAmt, setTotalSuperKreditAmt] = useState();
  const [cashbackPopup, setCashbackPopup] = useState(false);
  const [cashbackObj, setCashbackObj] = useState({});
  const [getDeliveryErrorsPopup, setDeliveryErrorsPopup] = useState(false);
  const [showVehicleStatusModal, setShowVehicleStatusModal] = useState(false);
  const [warehouse, setWarehouse] = useState(
    sessionStorage.getItem("warehouse")
  );
  const [isTransitDone, setIsTransitDone] = useState({});

  const [getAccessibleRoutes, setAccessibleRoutes] = useState({});

  //vehicle dispatch
  const [dispatchedVehicleFlag, setDispatchedVehicleFlag] = useState(false);
  const [dispatchedVehiclePopupOpen, setDispatchedVehiclePopupOpen] =
    useState(false);
  const [dispatchedVehicleSwitchChecked, setDispatchedVehicleSwitchChecked] =
    useState(false);
  const [vehicleNotReturnedMap, setVehicleNotReturnedMap] = useState({});

  const [routeOrdersConsolidationFlag, setRouteOrdersConsolidationFlag] =
    useState({});
  const [isRoutePickingCompletedFlag, setIsRoutePickingCompletedFlag] =
    useState({});
  const [opsDispatchedVehicleFlag, setOpsDispatchedVehicleFlag] = useState({});
  const [billingStockChecked, setBillingStockChecked] = useState({});

  const [getAlertStatus, setAlertStatus] = useState(false);
  const [getAlertMessage, setAlertMessage] = useState("Test Message");
  const [getAlertType, setAlertType] = useState("success");

  const [getFireBaseMin2, setFireBaseMin2] = useState();
  const [getFireBaseMax2, setFireBaseMax2] = useState();

  const [getModalWithView, setModalWithView] = useState(false);
  const [getPrimaryDropDownMessage, setPrimaryDropDownMessage] =
    useState("Select Driver");
  const [getRouteToMoveSelected, setRouteToMoveSelected] = useState("Select Route");
  const [moveDriverOptions, setMoveDriverOptions] = useState([]);

  const [getFireBaseStatus, setFireBaseStatus] = useState();
  const [getComplete, setComplete] = useState(true);

  const [getModalPartial, setModalPartial] = useState(false);
  const [getModalCancelled, setModalCancelled] = useState(false);
  const [getModalPending, setModalPending] = useState(false);
  const [getModalModifiedItemQty, setModalModifiedItemQty] = useState(false);
  const [getModalDeletedItemQty, setModalDeletedItemQty] = useState(false);
  const [getReturnOrdersModal, setReturnOrdersModal] = useState(false);
  const [orderStatusCheck, setOrderStatusCheck] = useState("");

  const [getFireBaseValue, setFireBaseValue] = useState();

  const [getPaymentSummary, setPaymentSummary] = useState({});

  const [getPaymentSummaryVisibility, setPaymentSummaryVisibility] =
    useState(false);
  const [getFilter, setFilter] = useState(null);
  const [itemQuant, setItemQuant] = useState("");
  const [itemOrderQty, setItemOrderQty] = useState("");
  const [getFilterButton, setFilterButton] = useState([]);
  const [getPaymentButton, setPaymentButton] = useState([]);
  const [getItemDetails, setItemDetails] = useState({});
  const [getItemDetailsStatus, setItemDetailsStatus] = useState(false);
  const [getOrderVarietyMap, setOrderVarietyMap] = useState({});

  const [getCounter, setCounter] = useState(0);
  const [getCounterMax, setCounterMax] = useState();

  const [getOnce, setOnce] = useState(true);
  const [getModalEdit, setModalEdit] = useState(false);

  const [searchOrderNumber, setSearchOrderNumber] = useState("");
  const [orderRouteStr, setOrderRouteStr] = useState("");

  const [getCallback, setCallback] = useState({
    driver_id: "",
    order_id: "",
    status: "",
  });
  const [confirmPopupShow, setConfirmPopupShow] = useState(false);
  const [getDownloadOrders, setDownloadOrders] = useState(true);
  const [bulkFilterStatus, setBulkFilterStatus] = useState("");
  const [bulkUpdationConfirm, setBulkUpdationConfirm] = useState(false);
  const [revisedAmtPresent, setRevisedAmtPresent] = useState(false);
  const [newDriverId, setNewDriverId] = useState("");
  const [newOrderId, setNewOrderId] = useState("");
  const [changeBulkStatus, setChangeBulkStatus] = useState(false);
  const [bulkOrdersRouteWise, setBulkOrdersRouteWise] = useState("");
  const [dataDownload, setDataDownload] = useState(false);
  const [showFlag, setShowFlag] = useState(false);
  const [endDeliveryConfirm, setEndDeliveryConfirm] = useState(false);
  const [deliveryEndFlagMap, setDeliveryEndFlagMap] = useState({});
  const [superKreditEndFlag, setSuperKreditEndFlag] = useState(false);
  const [cashbackEndFlag, setCashbackEndFlag] = useState(false);
  const [notificationFlag, setNotificationFlag] = useState(false);
  const [showStatusEndDel, setShowStatusEndDel] = useState(false);
  const [totalAmountUpdatePopupShow, setTotalAmountUpdatePopupShow] =
    useState(false);
  const [revisedOrderDiscUpdatePopupShow, setRevisedOrderDiscUpdatePopupShow] =
    useState(false);
  const [revisedOrderDiscToBeUpdated, setRevisedOrderDiscToBeUpdated] =
    useState(0);

  const [revisedOrderAmtUpdatePopupShow, setRevisedOrderAmtUpdatePopupShow] =
    useState(false);
  const [revisedOrderAmtToBeUpdated, setRevisedOrderAmtToBeUpdated] =
    useState(0);

  const [freshTotalLoadedCrateCount, setFreshTotalLoadedCrateCount] =
    useState(null);

  const [
    receivedReturnedProductsModalOpen,
    setReceivedReturnedProductsModalOpen,
  ] = useState(false);
  const [selectedReturnOrderStatus, setSelectedReturnOrderStatus] =
    useState("");

  const dialogRef = useRef();
  const activeOrderRef = useRef();
  const amtDiffRef = useRef();

  const handleChange = (date) => {
    setOnce(true);

    if (
      date.toString() == getDate.toString() &&
      date.toString() != new Date().toString()
    ) {
      setAlertType("info");
      setAlertMessage("Date already selected");
      setAlertStatus(true);
      return;
    }

    setOrdersPerShop({}); // very,very important
    setPrimaryDropDownMessage("Select Driver");
    setFilter(null);
    setBackDrop(true);
    setDate(date);
    setCurrentDate(
      `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
    );
    setDownloadOrders(true);
    if (sessionStorage.getItem("warehouse")) {
      setWarehouse(sessionStorage.getItem("warehouse"));
    }

    sessionStorage.setItem(
      `selectedInputDate`,
      `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
    );
    sessionStorage.removeItem("AmtDiff");
  };

  useEffect(() => {
    let dict = JSON.parse(sessionStorage.getItem("key"));
    fetchCityList();
    if (dict) {
      setBackDrop(true);
      setOnce(false);
      setDB(dict.db_url);
    } else {
      let temp_filter = JSON.parse(sessionStorage.getItem("filter"));
      if (temp_filter) {
        setBackDrop(true);
        setOnce(false);
        setFilter(temp_filter.filter);
        setDB(temp_filter.db_url);
      } else {
        if (!dict) handleChange(new Date());
      }
    }

    if (sessionStorage.getItem("warehouse")) {
      setWarehouse(sessionStorage.getItem("warehouse"));
    }
  }, []);

  useEffect(() => {
    if (getDB != null) {
      console.log(getDB);
      getOrders();
      getAllocations();
    }
  }, [getDB, warehouse]);

  useEffect(() => {
    console.log("debug orders history", getOrderHistory);

    if (!currentDate || !getPrimaryDropDownMessage) return;

    // fetchFreshOrders(currentDate,getPrimaryDropDownMessage)
  }, [currentDate, getPrimaryDropDownMessage, getOrderHistory]);

  const getOrders = () => {
    //reset the views
    setRenderDriver([]);
    setMoveDriverOptions([]);
    setRenderOrder([]);
    setRenderOrderDetails([]);
    setBreadCrumb([]);
    setBreadCrumb2([]);
    setRenderDriver(null);

    let temp = JSON.parse(sessionStorage.getItem("key"));
    let temp_filter = JSON.parse(sessionStorage.getItem("filter"));

    if (temp) {
      setOrderHistory(temp.db_data);
      setBackupOrderHistory(temp.db_data);
      return;
    }

    if (temp_filter) {
      setOrderHistory(temp_filter.db_data);
      setBackupOrderHistory(temp_filter.db_data);
      return;
    }
    if (getDownloadOrders) {
      Promise.all([
        fire.database().ref(getDB).once("value"),
        fire.database().ref(`Region_List`).once("value"),
      ]).then(([results, regList]) => {
        let driverOrdersObj = results.val() || {};
        let regionSnap = regList.val() || {};
        let updatedDriverOrdersObj = {};
        let routesAccessibleByUser = {};

        Object.keys(driverOrdersObj).map((rkey, rindex) => {
          let routeOrdersObj = driverOrdersObj[rkey];

          let vehicleOrdersObj = {};

          Object.keys(routeOrdersObj).map((okey, oindex) => {
            let ordersObj = routeOrdersObj[okey];

            delete ordersObj["agent_id"];
            delete ordersObj["cheque_date"];
            delete ordersObj["delivery_date"];
            delete ordersObj["final_order_amt"];
            delete ordersObj["created_by"];
            delete ordersObj["asm_id"];
            delete ordersObj["asmphone"];
            delete ordersObj["created_from"];
            delete ordersObj["distributor_id"];
            delete ordersObj["final_order_amt"];
            delete ordersObj["exp_delivery_date"];
            delete ordersObj["goni_image_url"];
            delete ordersObj["image_url"];
            delete ordersObj["itemModifiedUpdatedAt"];
            delete ordersObj["itemModifiedUpdatedBy"];
            delete ordersObj["itemDeliveredUpdatedAt"];
            delete ordersObj["itemDeliveredUpdatedBy"];
            delete ordersObj["long_description_hindi"];
            delete ordersObj["orderDate"];
            delete ordersObj["paid_date"];
            delete ordersObj["region"];
            delete ordersObj["se_phone_number"];
            delete ordersObj["signature_url"];
            // delete ordersObj["saved_at"];
            delete ordersObj["updatedAt"];
            delete ordersObj["updatedBy"];
            delete ordersObj["updated_by_app"];
            delete ordersObj["warehouse"];

            let city = routeOrdersObj[okey]["city"] || "";

            if (
              (((userCity && userCity == city) ||
                (!userCity &&
                  (!warehouse || warehouse == city || warehouse == "All"))) &&
                rkey != "9998" &&
                rkey != "9999") ||
              validateIsAdminUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              )
            ) {
              vehicleOrdersObj[okey] = ordersObj;
              routesAccessibleByUser[rkey] = true;
            }
          });

          if (Object.keys(vehicleOrdersObj).length > 0) {
            updatedDriverOrdersObj[rkey] = vehicleOrdersObj;
          }
        });

        let regNameMap = {};
        Object.keys(regionSnap).map((key, index) => {
          let id = regionSnap[key].id;
          let region = regionSnap[key].region;

          regNameMap[id] = region;
        });
        regNameMap["9999"] = "Retuns";
        regNameMap["9998"] = "Cancels";
        regNameMap["8989"] = "Distributors";
        regNameMap["8888"] = "DC / Khetika";
        regNameMap["8889"] = "Mill Direct";
        setDriverRegionNameMap(regNameMap);
        setAccessibleRoutes(routesAccessibleByUser);
        setOrderHistory(updatedDriverOrdersObj);
        setBackupOrderHistory(updatedDriverOrdersObj);
        if (!results.val()) {
          setBackDrop(false);

          setAlertMessage(
            "No entry for date :" +
              `${getDate.getDate()}-${
                getDate.getMonth() + 1
              }-${getDate.getFullYear()}` +
              " found."
          );
          setAlertType("error");
          setAlertStatus(true);
          setDownloadOrders(false);
        } else setDownloadOrders(true);
      });
    }
  };

  const fetchFreshOrders = async (date, route) => {
    if (!date || !route || !route?.startsWith("F")) return;
    const currDate = date.split("-");
    const api_date_format = `${currDate[2]}-${currDate[1]}-${currDate[0]}`;
    // console.log("debug fetching fresh orders", api_date_format, route);
    let url = `${service_url}/api/loaded-inventory-cumulative?token=${token}&start_date=${api_date_format}&end_date=${api_date_format}&route=${route}`;
    const freshOrdersByItems = {};
    const response = await fetch(url);
    const resData = await response.json();
    console.log("res data", resData);
    resData.data.forEach((item) => (freshOrdersByItems[item.item_id] = item));
    return freshOrdersByItems;
  };

  const getAllocations = () => {
    let year = getDate.getFullYear();
    let month = getDate.getMonth() + 1;
    let date = getDate.getDate() + "-" + month + "-" + year;

    fire
      .database()
      .ref("Driver_Allocation_History/" + year + "/" + month + "/" + date + "/")
      .once("value", (snapshot) => {
        if (snapshot.hasChildren()) {
          const snap_data = snapshot.val();
          setAllocationHistory(snap_data);
          // console.log("DEBUG setting orders history state", snap_data);
        } else {
          setAllocationHistory({});
        }
      });
  };

  const fetchCityList = () => {
    fire
      .database()
      .ref("Region_List")
      .once("value")
      .then((snapshot) => {
        const data = snapshot.val();
        // console.log("setting selected city to : ", cities[0].value);
        setCityList(data);
      })
      .catch((err) => {
        console.log(
          "An error occured while attempting to fetch the city list",
          err
        );
      });
  };

  const fireBasePartialWithMulti = (driver_id, order_id, status, data) => {
    console.log("fireBasePartialWithMulti");
    setBackDrop(true);
    console.log(getOrderHistory[driver_id][order_id]["order_qty"]);
    console.log(data);

    if (status) {
      data.status = status;
    }

    let singlePaymentPush;
    if (Object.keys(data).length == 1) singlePaymentPush = true;
    var order_details =
      getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]];
    setCounterMax(order_details.length);

    //To restrict marking as Delivered when modified during Shipping
    if (status && status == "Delivered") {
      console.log(order_details);
      for (let key in order_details) {
        var order_key =
          order_id.split("-")[0] +
          "-" +
          order_id.split("-")[1] +
          "-" +
          order_details[key];
        let ordered_qty = getOrderHistory[driver_id][order_key]["order_qty"];
        let modified_qty =
          getOrderHistory[driver_id][order_key]["item_modified_qty"];

        if (modified_qty && Number(modified_qty) != Number(ordered_qty)) {
          alert(
            "Items are modified. Order status can't be updated as Delivered"
          );
          setAlertMessage(
            "Items are modified. Order status can't be updated as Delivered"
          );
          setAlertType("error");
          setBackDrop(false);
          return;
        }
      }
    }

    for (let key in order_details) {
      var order_key =
        order_id.split("-")[0] +
        "-" +
        order_id.split("-")[1] +
        "-" +
        order_details[key];
      let item_data = data;
      let ordered_qty = getOrderHistory[driver_id][order_key]["order_qty"];
      let modified_qty =
        getOrderHistory[driver_id][order_key]["item_modified_qty"];

      if (modified_qty && Number(modified_qty) != Number(ordered_qty)) {
        item_data.item_delivered_qty = modified_qty;
      }else if (getOrderHistory[driver_id][order_key]["status"] == "Partially-Delivered") {
        // Retain existing item_delivered_qty if status is partially-delivered
        item_data.item_delivered_qty = getOrderHistory[driver_id][order_key]["item_delivered_qty"];
      }else {
        item_data.item_delivered_qty = ordered_qty;
      }

      var current_url = getDB + "/" + driver_id + "/" + order_key;
      fire.database().ref(current_url).child("reason").remove();
      getOrderHistory[driver_id][order_key].reason = "";
      if (status && status == "Delivered") {
        fire.database().ref(current_url).child("item_status").remove();
        getOrderHistory[driver_id][order_key].item_status = "";
        //for delivered status, item_delivered_qty is updated here
        item_data.item_delivered_qty = ordered_qty;
        let customerReturnedItemsFlag = getOrderHistory[driver_id][order_key][
          "customer_returned_items"
        ]
          ? getOrderHistory[driver_id][order_key]["customer_returned_items"]
          : "";
        if (customerReturnedItemsFlag == "No") {
          item_data.item_returned_qty = ordered_qty;
        } else {
          delete item_data.item_returned_qty;
        }
        if (order_id.includes("OR")) {
          delete item_data.item_delivered_qty;
        }
      }

      //update local copy
      let temp_order_history = getOrderHistory;
      Object.keys(item_data).forEach((local_key) => {
        temp_order_history[driver_id][order_key][local_key] =
          item_data[local_key];
      });

      fire
        .database()
        .ref(current_url)
        .update({
          ...item_data,
          updatedBy: usermodifying,
          updatedAt: UpdatedDate(),
        })
        .then(() => {
          setCounter((getCounter) => getCounter + 1);
        });

      setOrderHistory(temp_order_history);
      generateItemSummary(driver_id);
      RenderOrdersDetails(driver_id, order_id);
    }
    setTimeout(function () {
      if (getBackDrop) {
        setBackDrop(false);
      }
    }, 3000);
  };

  const updateLocalRouteOrders = (driver_id, order_id) => {
    console.log("updateLocalRouteOrders");
    let orderkey = order_id.split("-")[0] + "-" + order_id.split("-")[1];

    let routeOrders = getOrderHistory[driver_id];
    let fbobj = {};

    Object.keys(routeOrders).map((key, value) => {
      if (key.includes(orderkey)) {
        let ele = routeOrders[key];
        let item_delivered_qty = routeOrders[key]["order_qty"];
        ele["item_delivered_qty"] = item_delivered_qty;
        ele["item_returned_qty"] = item_delivered_qty;
        ele["status"] = selectedReturnOrderStatus;
        ele["updatedBy"] = JSON.parse(sessionStorage.getItem("user"))[0].email;
        ele["updatedAt"] = UpdatedDate();
        fbobj[key] = ele;
      }
    });

    if (Object.keys(fbobj).length > 0) {
      fire
        .database()
        .ref(getDB + "/" + driver_id + "/")
        .update(fbobj)
        .then(() => {
          Object.keys(routeOrders).map((key, value) => {
            getOrderHistory[driver_id][key] = routeOrders[key];
          });
          setCounter((getCounter) => getCounter + Object.keys(fbobj).length);
        });
    }

    setCounterMax(Object.keys(fbobj).length);
  };

  const fireBaseUpdateReturnDeliveredOrders = (driver_id, order_id, status) => {
    let data = {};
    console.log(getOrderHistory[driver_id][order_id]["order_qty"]);
    if (status) {
      data.status = status;
    }

    let item_delivered_qty = getOrderHistory[driver_id][order_id]["order_qty"];
    let ordernumber = getOrderHistory[driver_id][order_id]["order_number"];
    let customerReturnedItemsFlag = getOrderHistory[driver_id][order_id][
      "customer_returned_items"
    ]
      ? getOrderHistory[driver_id][order_id]["customer_returned_items"]
      : "";

    if (ordernumber.includes("-R") && customerReturnedItemsFlag == "No") {
      setSelectedReturnOrderStatus(status);
      setReceivedReturnedProductsModalOpen(true);
    } else {
      setBackDrop(true);
      if (status.includes("Delivered")) {
        data.item_delivered_qty = item_delivered_qty;
        data.item_returned_qty = item_delivered_qty;

        if (order_id.includes("_R")) {
          let customerReturnedItemsFlag = getOrderHistory[driver_id][order_id][
            "customer_returned_items"
          ]
            ? getOrderHistory[driver_id][order_id]["customer_returned_items"]
            : "";
          if (customerReturnedItemsFlag == "No") {
            data.item_returned_qty = item_delivered_qty;
          } else {
            delete data.item_returned_qty;
          }
        } else if (order_id.includes("_OR")) {
          delete data.item_delivered_qty;
        }
      }

      data.updatedBy = usermodifying;
      data.updatedAt = UpdatedDate();

      let orderkey = order_id.split("-")[0] + "-" + order_id.split("-")[1];

      fire
        .database()
        .ref(getDB + "/" + driver_id + "/")
        .once("value", (snapshot) => {
          if (snapshot.hasChildren()) {
            let driverordersdata = snapshot.val();

            let driverorderobj = {};
            Object.keys(driverordersdata).map((key, value) => {
              if (key.includes(orderkey)) {
                let ele = driverordersdata[key];
                Object.keys(data).map((dkey, dvalue) => {
                  ele[dkey] = data[dkey];
                  getOrderHistory[driver_id][key][dkey] = data[dkey];
                });
                driverorderobj[key] = ele;
              }
            });

            if (Object.keys(driverorderobj).length > 0) {
              fire
                .database()
                .ref(getDB + "/" + driver_id + "/")
                .update(driverorderobj)
                .then(() => {
                  setBackDrop(false);
                  setCounter(
                    (getCounter) =>
                      getCounter + Object.keys(driverorderobj).length
                  );
                });
            }
            setCounterMax(Object.keys(driverorderobj).length);
            setBackDrop(false);
          }
        });
    }
  };

  function checkTime(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  const fireBasePendingUpdate = (driver_id, order_id, data) => {
    setBackDrop(true);
    console.log(data);

    let order_details =
      getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]];
    setCounterMax(order_details.length);

    let temp_order_history = getOrderHistory;
    for (let keys in order_details) {
      var current_url =
        getDB +
        "/" +
        driver_id +
        "/" +
        order_id.split("-")[0] +
        "-" +
        order_id.split("-")[1] +
        "-" +
        order_details[keys];
      fire.database().ref(current_url).child("item_delivered_qty").remove();
      fire.database().ref(current_url).child("payment_details").remove();
      fire.database().ref(current_url).child("item_status").remove();

      //update local copy
      delete temp_order_history[driver_id][
        order_id.split("-")[0] +
          "-" +
          order_id.split("-")[1] +
          "-" +
          order_details[keys]
      ]["item_delivered_qty"];
      delete temp_order_history[driver_id][
        order_id.split("-")[0] +
          "-" +
          order_id.split("-")[1] +
          "-" +
          order_details[keys]
      ]["payment_details"];
      delete temp_order_history[driver_id][
        order_id.split("-")[0] +
          "-" +
          order_id.split("-")[1] +
          "-" +
          order_details[keys]
      ]["item_status"];
      Object.keys(data).forEach((local_key) => {
        temp_order_history[driver_id][
          order_id.split("-")[0] +
            "-" +
            order_id.split("-")[1] +
            "-" +
            order_details[keys]
        ][local_key] = data[local_key];
      });

      fire
        .database()
        .ref(current_url)
        .update({ ...data, updatedBy: usermodifying, updatedAt: UpdatedDate() })
        .then(() => {
          console.log("test");
        });
    }

    console.log(data);
    if (data["status"] == "Cancelled") {
      let cashback_redeemed =
        Number(getOrderHistory[driver_id][order_id].cashback_redeemed) || 0;
      if (cashback_redeemed > 0) {
        let retailer_id =
          Number(getOrderHistory[driver_id][order_id].retailer_id) || 0;
        let phone = Number(getOrderHistory[driver_id][order_id].phone) || 0;
        let order_number =
          getOrderHistory[driver_id][order_id].order_number || "";
        updateRetailerWallet(
          cashback_redeemed,
          retailer_id,
          phone,
          order_id,
          order_number
        );
      }

      if (order_id.includes("SC")) {
        let phone = Number(getOrderHistory[driver_id][order_id].phone) || 0;
        let order_number =
          getOrderHistory[driver_id][order_id].order_number || "";

        fire
          .database()
          .ref(`Super_Coin_Transactions/${phone}`)
          .once("value", (snapshot) => {
            let supercoinTransObj = snapshot.val() || {};

            let balanced_super_coins = 0;
            let super_coins = 0;
            Object.keys(supercoinTransObj).map((key, index) => {
              let orderID = supercoinTransObj[key].order_id;
              balanced_super_coins =
                supercoinTransObj[key].balanced_super_coins;

              if (order_number == orderID) {
                fire
                  .database()
                  .ref(`Super_Coin_Transactions/${phone}/${key}`)
                  .update({ status: "Cancelled" });
                super_coins = supercoinTransObj[key].super_coins;
              }
            });

            super_coins = -1 * Number(super_coins);

            var today = new Date();
            var month = "" + (today.getMonth() + 1);
            var date = "" + today.getDate();
            var year = today.getFullYear();
            if (month.length < 2) {
              month = "0" + month;
            }
            if (date.length < 2) {
              date = "0" + date;
            }
            var h = today.getHours();
            var m = today.getMinutes();
            if (m < 10) {
              m = "0" + m;
            }
            var s = today.getSeconds();
            if (s < 10) {
              s = "0" + s;
            }

            let newObj = {};
            newObj["status"] = "Refunded";
            newObj["balanced_super_coins"] = balanced_super_coins + super_coins;
            newObj[
              "activity_type"
            ] = `Earned for Cancellation - ${order_number}`;
            newObj["order_id"] = order_number;
            newObj["super_coins"] = `+${super_coins}`;
            newObj[
              "transaction_date"
            ] = `${date}-${month}-${year} ${h}:${m}:${s}`;

            fire
              .database()
              .ref(`Super_Coin_Transactions/${phone}`)
              .push(newObj)
              .then(() => {
                setCounter(order_details.length);
                setOrderHistory(temp_order_history);
                generateItemSummary(driver_id);
                RenderOrdersDetails(driver_id, order_id);
              });
          });
      } else {
        setCounter(order_details.length);
        setOrderHistory(temp_order_history);
        generateItemSummary(driver_id);
        RenderOrdersDetails(driver_id, order_id);
      }
    } else if (data["reason"] == "Customer wants on different day") {
      // only for pending status
      let deliveryDate = data["customer_selected_date"];
      let deliveryDateList = deliveryDate.split("-");
      let year = deliveryDateList[2];
      let month = deliveryDateList[1];
      let date = deliveryDateList[0];
      date = date < 10 ? "0" + date : date;
      month = month < 10 ? "0" + month : month;
      let formattedDeliveryDate = `${year}-${month}-${date}`;

      let updatedetails = {
        order_number: getOrderHistory[driver_id][order_id].order_number,
        comment: data["reason"],
        created_by: usermodifying,
        delivery_date: formattedDeliveryDate,
      };

      fetch(`${support_portal_url}/api/addcomments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": sessionStorage.getItem("apiToken"),
        },
        body: JSON.stringify(updatedetails),
      })
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          if (result["success"] != 1) {
            let errMessage = result["message"] ? result["message"] : "";
            alert("Error in adding comments:" + errMessage);
          }
          setCounter(order_details.length);
          setOrderHistory(temp_order_history);
          generateItemSummary(driver_id);
          RenderOrdersDetails(driver_id, order_id);
        })
        .catch((err) => {
          alert("Error in adding comments:" + err);
          setCounter(order_details.length);
          setOrderHistory(temp_order_history);
          generateItemSummary(driver_id);
          RenderOrdersDetails(driver_id, order_id);
        });
    } else {
      setCounter(order_details.length);
      setOrderHistory(temp_order_history);
      generateItemSummary(driver_id);
      RenderOrdersDetails(driver_id, order_id);
    }

    // try {
    //   sessionStorage.setItem('key', JSON.stringify({ history: true, db_url: getDB, driver_id: getBreadCrumb[0], order_id: getBreadCrumb2, current_date: getDate.toString(), db_data: getOrderHistory }))
    //   window.location.reload(false);
    // } catch (e) {
    //     alert("Session Storage is full. Clearing data.");
    //     sessionStorage.clear();
    // }
  };

  const updateRetailerWallet = (
    cashback_redeemed,
    retailer_id,
    phone,
    order_id,
    order_number
  ) => {
    fire
      .database()
      .ref(`Retailer_Master_New/${phone}`)
      .once("value")
      .then((retailer_snapshot) => {
        let retailerData = retailer_snapshot.val() || {};
        let wallet = Number(retailerData.wallet) || 0;
        let finalWalletAmt = wallet + cashback_redeemed;
        finalWalletAmt = finalWalletAmt.toFixed(2);
        finalWalletAmt = "" + finalWalletAmt;

        let currentDate = new Date();
        let date = currentDate.getDate();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();
        let halfYear = year.toString().substr(-2);

        let formattedCurrDate = `${date}-${month}-${year}`;
        let monthNameList = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let monthName = monthNameList[month - 1];

        let walletMasterObj = {};
        walletMasterObj[`/${retailer_id}/${order_id}/order_id`] = order_number;
        walletMasterObj[`/${retailer_id}/${order_id}/retailer_id`] =
          retailer_id;
        walletMasterObj[`/${retailer_id}/${order_id}/wallet_amt`] =
          "" + cashback_redeemed;
        walletMasterObj[
          `/${retailer_id}/${order_id}/transaction_date`
        ] = `${date} ${monthName} ${halfYear}`;

        let retailerMasterObj = {};
        retailerMasterObj[`/wallet`] = finalWalletAmt;

        Promise.all([
          fire.database().ref("Wallet_Master").update(walletMasterObj),
          fire
            .database()
            .ref(`Retailer_Master_New/${phone}`)
            .update(retailerMasterObj),
        ]).then(() => {
          setAlertType("success");
          setAlertMessage(`Updated wallet for retailer : ${retailer_id}`);
          setAlertStatus(true);
        });
      });
  };

  const firebaseOrderStatusUpdate = (driver_id, order_id, status) => {
    setBackDrop(true);

    let driverOrders = getOrderHistory[driver_id];
    let orderKey = order_id.split("-")[0] + "-" + order_id.split("-")[1];
    let temp_order_history = getOrderHistory;
    let order_details =
      getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]];
    let count = 0;

    Object.keys(driverOrders).map((key, value) => {
      if (key.includes(orderKey)) {
        count++;
        let temp_order_history = getOrderHistory;
        delete temp_order_history[driver_id][key]["item_delivered_qty"];
        delete temp_order_history[driver_id][key]["payment_details"];
        temp_order_history[driver_id][key]["status"] = status;
        var current_url = getDB + "/" + driver_id + "/" + key;
        fire.database().ref(current_url).child("item_delivered_qty").remove();

        if (status == "Shipped") {
          temp_order_history[driver_id][key]["item_status"] = "";
          temp_order_history[driver_id][key]["item_reason"] = "";
          temp_order_history[driver_id][key]["item_primary_reason"] = "";
          delete temp_order_history[driver_id][key]["item_modified_qty"];
          delete temp_order_history[driver_id][key]["revised_order_disc"];
          delete temp_order_history[driver_id][key]["item_delivered_qty"];
          delete temp_order_history[driver_id][key]["revised_item_price"];
          fire.database().ref(current_url).child("item_status").remove();
          fire.database().ref(current_url).child("item_reason").remove();
          fire
            .database()
            .ref(current_url)
            .child("item_primary_reason")
            .remove();
          fire.database().ref(current_url).child("item_modified_qty").remove();
          fire.database().ref(current_url).child("revised_order_amt").remove();
          fire.database().ref(current_url).child("revised_order_disc").remove();
          fire.database().ref(current_url).child("revised_item_price").remove();
        }

        fire
          .database()
          .ref(current_url)
          .update({
            status: status,
            updatedBy: usermodifying,
            updatedAt: UpdatedDate(),
          })
          .then(() => {
            setCounter((getCounter) => getCounter + 1);
          });
      }
    });
    setCounterMax(count);
    setOrderHistory(temp_order_history);
    generateItemSummary(driver_id);
    RenderOrdersDetails(driver_id, order_id);

    setTimeout(function () {
      if (getBackDrop) {
        setBackDrop(false);
      }
    }, 3000);
  };

  useEffect(() => {
    if (getCounter == getCounterMax) {
      try {
        console.log("updating");
        sessionStorage.setItem(
          "key",
          JSON.stringify({
            history: true,
            db_url: getDB,
            driver_id: getBreadCrumb[0],
            order_id: getBreadCrumb2,
            current_date: getDate.toString(),
            db_data: getOrderHistory,
          })
        );
        window.location.reload(false);
      } catch (e) {
        alert("Session Storage is full. Clearing data.");
        sessionStorage.clear();
      }
    }
  }, [getCounter]);

  const fireBaseStatusUpdate = (driver_id, order_id, status) => {
    // setBackDrop(true)
    setFireBaseDriverId(driver_id);
    setFireBaseOrderId(order_id);
    setFireBaseStatus(status);

    let min = 0;
    console.log("Firebase status update");
    let temp_order_history = getOrderHistory;

    setFireBaseMax2(
      getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]].length
    );
    let revisedOrderAmt = revisedAmt(
      temp_order_history[driver_id],
      order_id
    ).toFixed(2);
    for (let keys in getOrdersPerShop[
      order_id.split("-")[0] + order_id.split("-")[1]
    ]) {
      var current_url =
        getDB +
        "/" +
        driver_id +
        "/" +
        order_id.split("-")[0] +
        "-" +
        order_id.split("-")[1] +
        "-" +
        getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][keys];
      if (!status.includes("Delivered")) {
        fire.database().ref(current_url).child("item_delivered_qty").remove();
        fire.database().ref(current_url).child("payment_details").remove();
        fire.database().ref(current_url).child("item_status").remove();
        fire
          .database()
          .ref(current_url)
          .update({ revised_order_amt: revisedOrderAmt });
        temp_order_history[driver_id][
          order_id.split("-")[0] +
            "-" +
            order_id.split("-")[1] +
            "-" +
            getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][
              keys
            ]
        ].revised_order_amt = revisedOrderAmt;
        temp_order_history[driver_id][
          order_id.split("-")[0] +
            "-" +
            order_id.split("-")[1] +
            "-" +
            getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][
              keys
            ]
        ].item_status = "";
      }
      if (Object.keys(status).length == 2) {
        fire
          .database()
          .ref(current_url)
          .update({
            status: status.status,
            reason: status.reason,
            updatedBy: usermodifying,
            updatedAt: UpdatedDate(),
          })
          .then(() => {
            setFireBaseMin2(++min);
          });
      } else {
        fire
          .database()
          .ref(current_url)
          .update({
            status: status,
            updatedBy: usermodifying,
            updatedAt: UpdatedDate(),
          })
          .then(() => {
            setFireBaseMin2(++min);
          });
      }
    }
    let secondPartOfOrderNumber = order_id.split("-")[1];
    let isFirstOrder = secondPartOfOrderNumber == "1000001" ? true : false;

    let couponCode = temp_order_history[driver_id][order_id].coupon_code
      ? temp_order_history[driver_id][order_id].coupon_code
      : "";
    let originalOrderDisc = temp_order_history[driver_id][order_id].order_disc
      ? Number(temp_order_history[driver_id][order_id].order_disc)
      : 0;
    if (originalOrderDisc > 0) {
      revisedOrderDiscount(
        driver_id,
        temp_order_history[driver_id],
        order_id,
        revisedOrderAmt,
        couponCode,
        true
      );
    }
  };

  useEffect(() => {
    if (getOrdersPerShop && getFireBaseStatus && !getComplete) {
      fireBaseStatusUpdate(
        getFireBaseDriverId,
        getFireBaseOrderId,
        getFireBaseStatus
      );
    }
  }, [getOrdersPerShop, getFireBaseStatus, getComplete]);

  useEffect(() => {
    if (getFireBaseMin && getFireBaseMax) {
      if (getFireBaseMin == getFireBaseMax) {
        if (getFireBaseStatus) {
          fireBaseStatusUpdate(
            getFireBaseDriverId,
            getFireBaseOrderId,
            getFireBaseStatus
          );
        } else {
          updateLocalCopy(getFireBaseDriverId, getFireBaseOrderId);
        }
      }
    }
  }, [getFireBaseMin, getFireBaseMax]);

  useEffect(() => {
    if (getFireBaseMin2 && getFireBaseMax2) {
      if (getFireBaseMin2 == getFireBaseMax2) {
        //sessionStorage.setItem('key',JSON.stringify({history:true,db_url:getDB,driver_id:getFireBaseDriverId,order_id:getFireBaseOrderId,current_date:getDate.toString(),db_data:getOrderHistory}))
        //window.location.reload(false);
        updateLocalCopy(getFireBaseDriverId, getFireBaseOrderId);
      }
    }
  }, [getFireBaseMin2, getFireBaseMax2]);

  const updateLocalCopy = (driver_id, order_id) => {
    console.log(driver_id);
    console.log(order_id);
    let value = getFireBaseValue;
    let status = getFireBaseStatus;

    let local_copy = getOrderHistory;

    for (let keys in getOrdersPerShop[
      order_id.split("-")[0] + order_id.split("-")[1]
    ]) {
      var current_url =
        order_id.split("-")[0] +
        "-" +
        order_id.split("-")[1] +
        "-" +
        getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][keys];

      if (value) {
        local_copy[driver_id][current_url].payment_details = value;
      }

      if (status && Object.keys(status).length != 2) {
        local_copy[driver_id][current_url].status = status;
      }

      if (status && Object.keys(status).length == 2) {
        local_copy[driver_id][current_url].status = status.status;
        local_copy[driver_id][current_url]["reason"] = status.reason;
      }
    }

    sessionStorage.setItem(
      "key",
      JSON.stringify({
        history: true,
        db_url: getDB,
        driver_id: driver_id,
        order_id: order_id,
        current_date: getDate.toString(),
        db_data: local_copy,
      })
    );
    window.location.reload(false);
  };

  const getItemKeys = (driver_id) => {
    let temp_dict = {};
    for (var order_id in getOrderHistory[driver_id]) {
      if (!getOrderHistory[driver_id][order_id].hasOwnProperty("status"))
        return;
      if (!(order_id.split("-")[0] + order_id.split("-")[1] in temp_dict))
        temp_dict[order_id.split("-")[0] + order_id.split("-")[1]] = [];
      temp_dict[order_id.split("-")[0] + order_id.split("-")[1]].push(
        order_id.split("-")[2]
      );
    }
    return temp_dict;
  };

  const fireBaseItemStatusUpdate = (driver_id, order_id, data) => {
    let current_url = getDB + "/" + driver_id + "/" + order_id;
    let temp_order_history = getOrderHistory;
    let keyList = getItemKeys(driver_id);
    console.log("fireBaseItemStatusUpdate");

    if (
      validateIsBillingUser(
        JSON.parse(sessionStorage.getItem(`user`))[0].email
      ) &&
      data.item_status != "cancel" &&
      temp_order_history[driver_id][order_id].status.includes("Shipped")
    ) {
      setAlertMessage("Revert not allowed");
      setAlertType("error");
      setAlertStatus(true);
      return;
    }

    if (data.item_status != "cancel") {
      if (
        temp_order_history[driver_id][order_id].status.includes("Delivered")
      ) {
        data.itemDeliveredUpdatedAt = UpdatedDate();
        data.itemDeliveredUpdatedBy = usermodifying;
        data.item_delivered_qty = temp_order_history[driver_id][order_id]
          .item_modified_qty
          ? temp_order_history[driver_id][order_id].item_modified_qty
          : temp_order_history[driver_id][order_id].order_qty;
        temp_order_history[driver_id][order_id].item_delivered_qty =
          data.item_delivered_qty;
        temp_order_history[driver_id][order_id].itemDeliveredUpdatedAt =
          UpdatedDate();
        temp_order_history[driver_id][order_id].itemDeliveredUpdatedBy =
          usermodifying;
      }
      if (temp_order_history[driver_id][order_id].status.includes("Shipped")) {
        data.itemModifiedUpdatedAt = UpdatedDate();
        data.itemModifiedUpdatedBy = usermodifying;
        data.item_modified_qty =
          temp_order_history[driver_id][order_id].order_qty;
        temp_order_history[driver_id][order_id].item_modified_qty =
          data.item_modified_qty;
        temp_order_history[driver_id][order_id].itemModifiedUpdatedAt =
          UpdatedDate();
        temp_order_history[driver_id][order_id].itemModifiedUpdatedBy =
          usermodifying;
      }

      if (temp_order_history[driver_id][order_id].item_modified_qty != "0") {
        delete temp_order_history[driver_id][order_id].item_status;
        fire.database().ref(current_url).child("item_status").remove();
      }

      if (temp_order_history[driver_id][order_id].item_modified_qty == "0") {
        data.item_status = "cancel";
      }
    } else {
      if (
        temp_order_history[driver_id][order_id].status.includes("Delivered")
      ) {
        data.itemDeliveredUpdatedAt = UpdatedDate();
        data.itemDeliveredUpdatedBy = usermodifying;
        data.item_delivered_qty = "0";
        temp_order_history[driver_id][order_id].item_delivered_qty = "0";
        temp_order_history[driver_id][order_id].itemDeliveredUpdatedAt =
          UpdatedDate();
        temp_order_history[driver_id][order_id].itemDeliveredUpdatedBy =
          usermodifying;
      }
      if (temp_order_history[driver_id][order_id].status.includes("Shipped")) {
        data.itemModifiedUpdatedAt = UpdatedDate();
        data.itemModifiedUpdatedBy = usermodifying;
        data.item_modified_qty = "0";
        temp_order_history[driver_id][order_id].item_modified_qty = "0";
        temp_order_history[driver_id][order_id].itemModifiedUpdatedAt =
          UpdatedDate();
        temp_order_history[driver_id][order_id].itemModifiedUpdatedBy =
          usermodifying;
      }
      temp_order_history[driver_id][order_id].item_status = data.item_status;
      temp_order_history[driver_id][order_id].item_reason = data.item_reason;
    }

    let customerReturnedItemsFlag = temp_order_history[driver_id][order_id][
      "customer_returned_items"
    ]
      ? temp_order_history[driver_id][order_id]["customer_returned_items"]
      : "";
    if (customerReturnedItemsFlag == "No") {
      data.item_returned_qty = data.item_delivered_qty;
    }

    setBackDrop(true);
    let updateToAll = {};
    if (
      temp_order_history[driver_id][order_id].status &&
      temp_order_history[driver_id][order_id].status.includes("Shipped")
    ) {
      updateToAll["status"] = "Shipped-Modified";
    }

    let revisedOrderAmt = revisedAmt(
      temp_order_history[driver_id],
      order_id
    ).toFixed(2);
    updateToAll["revised_order_amt"] = revisedOrderAmt;
    //after refresh getOrdersPerShop[order_id.split('-')[0] + order_id.split('-')[1]] becomes undefined  as getOrdersPerShop is {}
    //some issue with the state getOrdersPerShop, beacuse ff which revised order amount is not getting updated
    console.log(keyList);
    let count = 0;
    for (let key in keyList[order_id.split("-")[0] + order_id.split("-")[1]]) {
      console.log("updation orderKey:: " + key);
      count++;
      let modified_url =
        getDB +
        "/" +
        driver_id +
        "/" +
        order_id.split("-")[0] +
        "-" +
        order_id.split("-")[1] +
        "-" +
        keyList[order_id.split("-")[0] + order_id.split("-")[1]][key];
      fire
        .database()
        .ref(modified_url)
        .update(updateToAll)
        .then(() => {
          console.log("updation count:: " + getCounter + 1);
          setCounter(count);
        })
        .catch(function (error) {
          setBackDrop(false);
          setAlertMessage("Error occurred in updating Item status");
          RenderOrdersDetails(driver_id, order_id);
          setAlertType("error");
          setAlertStatus(true);
          setModalDeletedItemQty(false);
        });
      if (
        temp_order_history[driver_id][order_id].status &&
        temp_order_history[driver_id][order_id].status.includes("Shipped")
      ) {
        temp_order_history[driver_id][
          `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
            keyList[order_id.split("-")[0] + order_id.split("-")[1]][key]
          }`
        ].status = "Shipped-Modified";
      }
      temp_order_history[driver_id][
        `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
          keyList[order_id.split("-")[0] + order_id.split("-")[1]][key]
        }`
      ].revised_order_amt = revisedOrderAmt;
    }
    setCounterMax(count);
    setOrderHistory(temp_order_history);

    fire
      .database()
      .ref(current_url)
      .update({ ...data })
      .then(() => {
        setBackDrop(false);
        setAlertType("success");
        setAlertMessage(
          data.item_status == "cancel"
            ? "Removed item successfully"
            : "Added item back again"
        );
        setAlertStatus(true);
        generateItemSummary(driver_id);
        RenderOrdersDetails(driver_id, order_id);
        setModalDeletedItemQty(false);

        let secondPartOfOrderNumber = order_id.split("-")[1];
        let isFirstOrder = secondPartOfOrderNumber == "1000001" ? true : false;

        let couponCode = temp_order_history[driver_id][order_id].coupon_code
          ? temp_order_history[driver_id][order_id].coupon_code
          : "";
        let originalOrderDisc = temp_order_history[driver_id][order_id]
          .order_disc
          ? Number(temp_order_history[driver_id][order_id].order_disc)
          : 0;

        if (originalOrderDisc > 0) {
          revisedOrderDiscount(
            driver_id,
            temp_order_history[driver_id],
            order_id,
            updateToAll["revised_order_amt"],
            couponCode,
            true
          );
        }
      })
      .catch(function (error) {
        setBackDrop(false);
        setAlertMessage("Error occurred in updating Item status");
        RenderOrdersDetails(driver_id, order_id);
        setAlertType("error");
        setAlertStatus(true);
        setModalDeletedItemQty(false);
      });
  };

  const fireBaseItemQuantityUpdate = (
    driver_id,
    order_id,
    item_qty,
    data,
    replacedItem
  ) => {
    let current_url = getDB + "/" + driver_id + "/" + order_id;
    console.log("Item Quantity Update::: ", data);

    if (Object.keys(data).length > 0) {
      let temp_order_history = getOrderHistory;
      temp_order_history[driver_id][order_id].item_status = data.item_status;
      temp_order_history[driver_id][order_id].item_reason = data.item_reason;
      temp_order_history[driver_id][order_id].item_modified_qty =
        data.item_modified_qty;
      temp_order_history[driver_id][order_id].item_delivered_qty =
        data.item_delivered_qty;
      if (
        temp_order_history[driver_id][order_id].status.includes("Delivered")
      ) {
        data.itemDeliveredUpdatedAt = UpdatedDate();
        data.itemDeliveredUpdatedBy = usermodifying;
        if (data.item_delivered_qty == "0") {
          data.item_status = "cancel";
          temp_order_history[driver_id][order_id].item_status = "cancel";
        } else if (Number(data.item_delivered_qty) > 0) {
          data.item_status = "";
          temp_order_history[driver_id][order_id].item_status = "";
        }
      }
      if (temp_order_history[driver_id][order_id].status.includes("Shipped")) {
        data.itemModifiedUpdatedAt = UpdatedDate();
        data.itemModifiedUpdatedBy = usermodifying;
        if (data.item_modified_qty == "0") {
          data.item_status = "cancel";
          temp_order_history[driver_id][order_id].item_status = "cancel";
        } else if (Number(data.item_modified_qty) > 0) {
          data.item_status = "";
          temp_order_history[driver_id][order_id].item_status = "";
        }
      }
      setBackDrop(true);
      let updateToAll = {};
      if (
        temp_order_history[driver_id][order_id].status &&
        temp_order_history[driver_id][order_id].status.includes("Shipped")
      ) {
        updateToAll["status"] = "Shipped-Modified";
      }

      updateToAll["revised_order_amt"] = revisedAmt(
        temp_order_history[driver_id],
        order_id
      ).toFixed(2);

      let count = 0;
      for (let key in getOrdersPerShop[
        order_id.split("-")[0] + order_id.split("-")[1]
      ]) {
        count++;
        let modified_url =
          getDB +
          "/" +
          driver_id +
          "/" +
          order_id.split("-")[0] +
          "-" +
          order_id.split("-")[1] +
          "-" +
          getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][
            key
          ];
        fire
          .database()
          .ref(modified_url)
          .update(updateToAll)
          .then(() => {
            console.log("updation count:: " + getCounter + 1);
            setCounter(count);
          })
          .catch(function (error) {
            setBackDrop(false);
            setAlertMessage("Error occurred in updating Item status");
            RenderOrdersDetails(driver_id, order_id);
            setAlertType("error");
            setAlertStatus(true);
            setModalDeletedItemQty(false);
          });
        if (
          temp_order_history[driver_id][order_id].status &&
          temp_order_history[driver_id][order_id].status.includes("Shipped")
        ) {
          temp_order_history[driver_id][
            `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
              getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][
                key
              ]
            }`
          ].status = "Shipped-Modified";
        }
        temp_order_history[driver_id][
          `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
            getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][
              key
            ]
          }`
        ].revised_order_amt = revisedAmt(
          temp_order_history[driver_id],
          order_id
        ).toFixed(2);
      }
      setCounterMax(count);

      let customerReturnedItemsFlag = getOrderHistory[driver_id][order_id][
        "customer_returned_items"
      ]
        ? getOrderHistory[driver_id][order_id]["customer_returned_items"]
        : "";
      if (customerReturnedItemsFlag == "No") {
        data.item_returned_qty = data.item_delivered_qty;
      }
      temp_order_history[driver_id][order_id].item_modified_qty =
        data.item_modified_qty;
      temp_order_history[driver_id][order_id].item_delivered_qty =
        data.item_delivered_qty;
      temp_order_history[driver_id][order_id].item_reason = data.item_reason;

      //To remove item reasons when it's equal to order qty
      if (
        (temp_order_history[driver_id][order_id].status.includes("Delivered") &&
          Number(data.item_delivered_qty) ==
            Number(temp_order_history[driver_id][order_id].order_qty)) ||
        (temp_order_history[driver_id][order_id].status.includes("Shipped") &&
          Number(data.item_modified_qty) ==
            Number(temp_order_history[driver_id][order_id].order_qty))
      ) {
        fire.database().ref(current_url).child("item_reason").remove();
        fire.database().ref(current_url).child("item_primary_reason").remove();
        fire.database().ref(current_url).child("item_status").remove();
        temp_order_history[driver_id][order_id].item_reason = "";
      }

      if (
        (temp_order_history[driver_id][order_id].status.includes("Delivered") &&
          Number(data.item_delivered_qty) > 0) ||
        (temp_order_history[driver_id][order_id].status.includes("Shipped") &&
          Number(data.item_modified_qty) > 0)
      ) {
        fire.database().ref(current_url).child("item_status").remove();
      }

      let secondPartOfOrderNumber = order_id.split("-")[1];
      let isFirstOrder = secondPartOfOrderNumber == "1000001" ? true : false;

      let couponCode = temp_order_history[driver_id][order_id].coupon_code
        ? temp_order_history[driver_id][order_id].coupon_code
        : "";
      let originalOrderDisc = temp_order_history[driver_id][order_id].order_disc
        ? Number(temp_order_history[driver_id][order_id].order_disc)
        : 0;

      if (originalOrderDisc > 0) {
        revisedOrderDiscount(
          driver_id,
          temp_order_history[driver_id],
          order_id,
          updateToAll["revised_order_amt"],
          couponCode,
          true
        );
      }

      fire
        .database()
        .ref(current_url)
        .update({ ...data })
        .then(() => {
          setAlertType("success");
          setAlertMessage("updated quantity");
          setAlertStatus(true);
          setOrderHistory(temp_order_history);
          generateItemSummary(driver_id);
          RenderOrdersDetails(driver_id, order_id);
          setBackDrop(false);
          setModalModifiedItemQty(false);
        })
        .catch(function (error) {
          setBackDrop(false);
          setAlertMessage("Error occurred in updating Item quantity");
          RenderOrdersDetails(driver_id, order_id);
          setAlertType("error");
          setAlertStatus(true);
          setModalModifiedItemQty(false);
        });
      setTimeout(function () {
        if (getBackDrop) {
          setBackDrop(false);
        }
      }, 3000);
    } else if (Object.keys(replacedItem).length > 0) {
      let temp_order_history = getOrderHistory;
      if (replacedItem.ordered_item_id) {
        let isExistingItemUpdated = false;
        for (let key in getOrdersPerShop[
          order_id.split("-")[0] + order_id.split("-")[1]
        ]) {
          let modified_url =
            getDB +
            "/" +
            driver_id +
            "/" +
            order_id.split("-")[0] +
            "-" +
            order_id.split("-")[1] +
            "-" +
            getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][
              key
            ];
          let existingItem =
            getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][
              key
            ];
          if (existingItem == replacedItem.item_id) {
            isExistingItemUpdated = true;

            let existingItemOrderedQty =
              temp_order_history[driver_id][
                `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
                  getOrdersPerShop[
                    order_id.split("-")[0] + order_id.split("-")[1]
                  ][key]
                }`
              ].order_qty;
            let updatedItemQty =
              Number(replacedItem.item_modified_qty) +
              Number(existingItemOrderedQty);

            let itemObj = {};

            itemObj.ordered_item_id = replacedItem.ordered_item_id;
            itemObj.ordered_item_disc = replacedItem.ordered_item_disc;
            itemObj.status = replacedItem.status;
            itemObj.item_primary_reason = replacedItem.item_primary_reason;
            itemObj.item_reason = replacedItem.item_reason;
            itemObj.order_qty = updatedItemQty + "";
            itemObj.ordered_item_qty = replacedItem.item_modified_qty;

            if (itemObj.status.includes("Delivered")) {
              itemObj.replacement_after_delivery =
                replacedItem.replacement_after_delivery
                  ? replacedItem.replacement_after_delivery
                  : "";

              updatedItemQty =
                Number(replacedItem.item_delivered_qty) +
                Number(existingItemOrderedQty);
              itemObj.order_qty = updatedItemQty + "";
              itemObj.ordered_item_qty = replacedItem.item_delivered_qty;
              itemObj.new_mrp = replacedItem.new_mrp;
            }

            fire.database().ref(modified_url).update(itemObj);
            temp_order_history[driver_id][
              `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
                getOrdersPerShop[
                  order_id.split("-")[0] + order_id.split("-")[1]
                ][key]
              }`
            ].order_qty = updatedItemQty;
            temp_order_history[driver_id][
              `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
                getOrdersPerShop[
                  order_id.split("-")[0] + order_id.split("-")[1]
                ][key]
              }`
            ].ordered_item_qty = replacedItem.item_delivered_qty
              ? replacedItem.item_delivered_qty
              : replacedItem.item_modified_qty;

            temp_order_history[driver_id][
              `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
                getOrdersPerShop[
                  order_id.split("-")[0] + order_id.split("-")[1]
                ][key]
              }`
            ].ordered_item_id = replacedItem.ordered_item_id;
            temp_order_history[driver_id][
              `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
                getOrdersPerShop[
                  order_id.split("-")[0] + order_id.split("-")[1]
                ][key]
              }`
            ].ordered_item_disc = replacedItem.ordered_item_disc;
            temp_order_history[driver_id][
              `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
                getOrdersPerShop[
                  order_id.split("-")[0] + order_id.split("-")[1]
                ][key]
              }`
            ].status = replacedItem.status;
            temp_order_history[driver_id][
              `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
                getOrdersPerShop[
                  order_id.split("-")[0] + order_id.split("-")[1]
                ][key]
              }`
            ].item_primary_reason = replacedItem.item_primary_reason;
            temp_order_history[driver_id][
              `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
                getOrdersPerShop[
                  order_id.split("-")[0] + order_id.split("-")[1]
                ][key]
              }`
            ].item_reason = replacedItem.item_reason;

            if (itemObj.status.includes("Delivered")) {
              temp_order_history[driver_id][
                `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
                  getOrdersPerShop[
                    order_id.split("-")[0] + order_id.split("-")[1]
                  ][key]
                }`
              ].replacement_after_delivery =
                replacedItem.replacement_after_delivery;
              temp_order_history[driver_id][
                `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
                  getOrdersPerShop[
                    order_id.split("-")[0] + order_id.split("-")[1]
                  ][key]
                }`
              ].new_mrp = replacedItem.new_mrp;
            }
          }
        }

        if (isExistingItemUpdated) {
          // 1. when updated item already exists in order
          fire.database().ref(current_url).remove();
          delete temp_order_history[driver_id][order_id];

          let existingItemKey =
            order_id.split("-")[0] +
            "-" +
            order_id.split("-")[1] +
            "-" +
            replacedItem.item_id;

          let updateToAll = {};
          if (
            temp_order_history[driver_id][existingItemKey].status &&
            temp_order_history[driver_id][existingItemKey].status.includes(
              "Shipped"
            )
          ) {
            updateToAll["status"] = "Shipped-Modified";
          }

          updateToAll["revised_order_amt"] = revisedAmt(
            temp_order_history[driver_id],
            order_id
          ).toFixed(2);

          let updatedShopItemList = [];
          for (let key in getOrdersPerShop[
            order_id.split("-")[0] + order_id.split("-")[1]
          ]) {
            let currItemId =
              getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][
                key
              ];
            if (currItemId != replacedItem.ordered_item_id) {
              updatedShopItemList.push(currItemId);
              let modified_url =
                getDB +
                "/" +
                driver_id +
                "/" +
                order_id.split("-")[0] +
                "-" +
                order_id.split("-")[1] +
                "-" +
                getOrdersPerShop[
                  order_id.split("-")[0] + order_id.split("-")[1]
                ][key];
              fire.database().ref(modified_url).update(updateToAll);
              if (
                temp_order_history[driver_id][existingItemKey].status &&
                temp_order_history[driver_id][existingItemKey].status.includes(
                  "Shipped"
                )
              ) {
                temp_order_history[driver_id][
                  `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
                    getOrdersPerShop[
                      order_id.split("-")[0] + order_id.split("-")[1]
                    ][key]
                  }`
                ].status = "Shipped-Modified";
              }
              temp_order_history[driver_id][
                `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
                  getOrdersPerShop[
                    order_id.split("-")[0] + order_id.split("-")[1]
                  ][key]
                }`
              ].revised_order_amt = revisedAmt(
                temp_order_history[driver_id],
                order_id
              ).toFixed(2);
            }
          }

          let tempShopOrders = getOrdersPerShop;
          tempShopOrders[order_id.split("-")[0] + order_id.split("-")[1]] =
            updatedShopItemList;

          setOrdersPerShop(tempShopOrders);
          setAlertType("success");
          setAlertMessage("replaced existing item");
          setAlertStatus(true);

          setOrderHistory(temp_order_history);
          generateItemSummary(driver_id);
          generatePaymentSummary(driver_id);
          RenderOrdersDetails(driver_id, existingItemKey);
          setBackDrop(false);
          setModalModifiedItemQty(false);

          let secondPartOfOrderNumber = order_id.split("-")[1];
          let isFirstOrder =
            secondPartOfOrderNumber == "1000001" ? true : false;

          let couponCode = temp_order_history[driver_id][order_id].coupon_code
            ? temp_order_history[driver_id][order_id].coupon_code
            : "";
          let originalOrderDisc = temp_order_history[driver_id][order_id]
            .order_disc
            ? Number(temp_order_history[driver_id][order_id].order_disc)
            : 0;
          if (originalOrderDisc > 0) {
            revisedOrderDiscount(
              driver_id,
              temp_order_history[driver_id],
              order_id,
              updateToAll["revised_order_amt"],
              couponCode,
              true
            );
          }
        } else {
          // 2. when updated item doesn't exists in order

          fire
            .database()
            .ref(current_url)
            .once("value", (snapshot) => {
              let newItemKey =
                order_id.split("-")[0] +
                "-" +
                order_id.split("-")[1] +
                "-" +
                replacedItem.item_id;
              let temp_order_history = getOrderHistory;
              let oldItemData = snapshot.val() || {};
              oldItemData.item_id = replacedItem.item_id;
              oldItemData.item_disc = replacedItem.item_disc;
              oldItemData.ordered_item_id = replacedItem.ordered_item_id;
              oldItemData.ordered_item_disc = replacedItem.ordered_item_disc;
              oldItemData.status = replacedItem.status;
              oldItemData.new_dealer_price = replacedItem.new_dealer_price;

              oldItemData.item_primary_reason =
                replacedItem.item_primary_reason;
              oldItemData.item_reason = replacedItem.item_reason;

              if (replacedItem.status.includes("Delivered")) {
                oldItemData.replacement_after_delivery =
                  replacedItem.replacement_after_delivery
                    ? replacedItem.replacement_after_delivery
                    : "";
                oldItemData.item_delivered_qty =
                  replacedItem.item_delivered_qty;
                oldItemData.new_mrp = replacedItem.new_mrp;
              } else {
                oldItemData.item_modified_qty = replacedItem.item_modified_qty;
              }

              fire.database().ref(current_url).remove();
              delete temp_order_history[driver_id][order_id];

              let modified_url =
                getDB +
                "/" +
                driver_id +
                "/" +
                order_id.split("-")[0] +
                "-" +
                order_id.split("-")[1] +
                "-" +
                replacedItem.item_id;

              fire
                .database()
                .ref(modified_url)
                .update({ ...oldItemData })
                .then(() => {
                  temp_order_history[driver_id][newItemKey] = oldItemData;
                  temp_order_history[driver_id][newItemKey].item_id =
                    replacedItem.item_id;
                  temp_order_history[driver_id][newItemKey].item_disc =
                    replacedItem.item_disc;
                  temp_order_history[driver_id][newItemKey].ordered_item_id =
                    replacedItem.ordered_item_id;
                  temp_order_history[driver_id][newItemKey].ordered_item_disc =
                    replacedItem.ordered_item_disc;
                  temp_order_history[driver_id][newItemKey].status =
                    replacedItem.status;
                  temp_order_history[driver_id][newItemKey].new_dealer_price =
                    replacedItem.new_dealer_price;

                  temp_order_history[driver_id][newItemKey].item_modified_qty =
                    replacedItem.item_modified_qty;
                  temp_order_history[driver_id][
                    newItemKey
                  ].item_primary_reason = replacedItem.item_primary_reason;
                  temp_order_history[driver_id][newItemKey].item_reason =
                    replacedItem.item_reason;

                  if (replacedItem.status.includes("Delivered")) {
                    temp_order_history[driver_id][
                      newItemKey
                    ].replacement_after_delivery =
                      replacedItem.replacement_after_delivery;
                    temp_order_history[driver_id][
                      newItemKey
                    ].item_delivered_qty = replacedItem.item_delivered_qty;
                    temp_order_history[driver_id][newItemKey].new_mrp =
                      replacedItem.new_mrp;
                  }

                  let updateToAll = {};
                  if (
                    temp_order_history[driver_id][newItemKey].status &&
                    temp_order_history[driver_id][newItemKey].status.includes(
                      "Shipped"
                    )
                  ) {
                    updateToAll["status"] = "Shipped-Modified";
                  }
                  let revisedOrderrAmt = revisedAmt(
                    temp_order_history[driver_id],
                    order_id
                  ).toFixed(2);
                  updateToAll["revised_order_amt"] = revisedOrderrAmt;

                  let updateShopOrderItemList = [];
                  for (let key in getOrdersPerShop[
                    order_id.split("-")[0] + order_id.split("-")[1]
                  ]) {
                    let currItemId =
                      getOrdersPerShop[
                        order_id.split("-")[0] + order_id.split("-")[1]
                      ][key];
                    if (currItemId == replacedItem.ordered_item_id) {
                      updateShopOrderItemList.push(replacedItem.item_id);

                      let item_modified_url =
                        getDB +
                        "/" +
                        driver_id +
                        "/" +
                        order_id.split("-")[0] +
                        "-" +
                        order_id.split("-")[1] +
                        "-" +
                        replacedItem.item_id;
                      fire
                        .database()
                        .ref(item_modified_url)
                        .update(updateToAll);
                      temp_order_history[driver_id][
                        `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
                          replacedItem.item_id
                        }`
                      ].status = "Shipped-Modified";
                      temp_order_history[driver_id][
                        `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
                          replacedItem.item_id
                        }`
                      ].revised_order_amt = revisedOrderrAmt;
                    } else {
                      updateShopOrderItemList.push(currItemId);

                      let item_modified_url =
                        getDB +
                        "/" +
                        driver_id +
                        "/" +
                        order_id.split("-")[0] +
                        "-" +
                        order_id.split("-")[1] +
                        "-" +
                        currItemId;
                      fire
                        .database()
                        .ref(item_modified_url)
                        .update(updateToAll);
                      temp_order_history[driver_id][
                        `${order_id.split("-")[0]}-${
                          order_id.split("-")[1]
                        }-${currItemId}`
                      ].status = "Shipped-Modified";
                      temp_order_history[driver_id][
                        `${order_id.split("-")[0]}-${
                          order_id.split("-")[1]
                        }-${currItemId}`
                      ].revised_order_amt = revisedOrderrAmt;
                    }
                  }
                  let tempShopOrders = getOrdersPerShop;
                  tempShopOrders[
                    order_id.split("-")[0] + order_id.split("-")[1]
                  ] = updateShopOrderItemList;

                  setOrdersPerShop(tempShopOrders);
                  setAlertType("success");
                  setAlertMessage("replaced item");
                  setAlertStatus(true);

                  setOrderHistory(temp_order_history);
                  generateItemSummary(driver_id);
                  generatePaymentSummary(driver_id);
                  RenderOrdersDetails(driver_id, newItemKey);
                  setBackDrop(false);
                  setModalModifiedItemQty(false);

                  let secondPartOfOrderNumber = order_id.split("-")[1];
                  let isFirstOrder =
                    secondPartOfOrderNumber == "1000001" ? true : false;

                  let couponCode = temp_order_history[driver_id][order_id]
                    .coupon_code
                    ? temp_order_history[driver_id][order_id].coupon_code
                    : "";
                  let originalOrderDisc = temp_order_history[driver_id][
                    order_id
                  ].order_disc
                    ? Number(temp_order_history[driver_id][order_id].order_disc)
                    : 0;

                  if (originalOrderDisc > 0) {
                    revisedOrderDiscount(
                      driver_id,
                      temp_order_history[driver_id],
                      order_id,
                      updateToAll["revised_order_amt"],
                      couponCode,
                      true
                    );
                  }
                })
                .catch(function (error) {
                  setBackDrop(false);
                  console.log(error);
                  // setAlertMessage("Error occurred in replacing Item : "+error)
                  generatePaymentSummary(driver_id);
                  RenderOrdersDetails(driver_id, newItemKey);
                  // setAlertType("error")
                  // setAlertStatus(true)
                  setModalModifiedItemQty(false);
                });

              setTimeout(function () {
                if (getBackDrop) {
                  setBackDrop(false);
                }
              }, 3000);
            });
        }
      }
    }
  };

  const generateItemSummary = (driver_id, freshItems = {}) => {
    let temp_item_status;
    let total_loaded_crates = null;
    let item_summary = {
      delivered: [],
      undelivered: [],
      short: [],
      notLoaded: [],
      loaded: [],
      replacements: [],
    };

    console.log("debug generating item summary for", driver_id);
    for (var order_id in getOrderHistory[driver_id]) {
      const order_status_enum = {
        Processed: "Processed",
        "Shipped-Modified": "Shipped-Modified",
        Shipped: "Shipped",
        "Partially-Delivered": "Partially-Delivered",
        Pending: "Pending",
        Cancelled: "Cancelled",
        Hold: "Hold",
      };

      if (driver_id != "9999" && driver_id != "9998") {
        let short_reason_exists = false;

        let order = JSON.parse(
          JSON.stringify(getOrderHistory[driver_id][order_id])
        );
        let variety = order.variety || "";
        let order_number = order.order_number || "";

        // console.log('debug checking', driver_id,driver_id.startsWith('F'))

        const orderedQty = Number(order.order_qty).toFixed(0);
        const orderedAmt = Number(order.mrp).toFixed(2);
        const modifiedQty = order.hasOwnProperty("item_modified_qty")
          ? Number(order.item_modified_qty).toFixed(1)
          : orderedQty;

        const modifiedAmt = (orderedAmt * (modifiedQty / orderedQty)).toFixed(
          2
        );

        let loadedQty = 0;
        if (driver_id.startsWith("F")) {
          loadedQty = freshItems[order.item_id]?.total_qty || 0;
        } else {
          loadedQty = Number(modifiedQty);
        }

        const loadedAmt = Number(modifiedAmt || orderedAmt);

        const deliveredQty =
          order.status == "Partially-Delivered" || order.status == "Delivered"
            ? order.item_delivered_qty
              ? Number(order.item_delivered_qty).toFixed(1)
              : order.item_modified_qty
              ? Number(order.item_modified_qty).toFixed(1)
              : orderedQty
            : 0;

        const deliveredAmt = (orderedAmt * (deliveredQty / orderedQty)).toFixed(
          2
        );
        let notLoadedQty = 0;

        if (!order.status) {
          order.status = "Processed";
        }

        const primary_reason = order.item_primary_reason || "";

        if (
          order.item_primary_reason &&
          (order.status.toLowerCase() === "partially-delivered" ||
            order.status.toLowerCase() === "shipped-modified")
        ) {
          if (
            getOrderHistory[driver_id][
              order_id
            ].item_primary_reason.toLowerCase() == "short"
          ) {
            temp_item_status = "short";
            short_reason_exists = true;
          }
        }

        let notLoadedAmt = 0;

        if (order.status === order_status_enum.Hold) {
          notLoadedQty = orderedQty;
          notLoadedAmt = Number(orderedAmt).toFixed(2);
          console.log("debug issue  orderedAmt", Number(orderedAmt).toFixed(2));
        } else if (order.item_modified_qty !== null) {
          notLoadedQty = Number(orderedQty - modifiedQty).toFixed(1);
          notLoadedAmt = Number(orderedAmt - modifiedAmt).toFixed(2);
        }

        if (
          (order.item_delivered_qty || order.item_modified_qty) &&
          order.status &&
          (order.status == "Partially-Delivered" ||
            order.status == "Delivered" ||
            order.status === "Shipped-Modified")
        ) {
          // console.log('check not loaded reason',  order.item_primary_reason)

          const unDeliveredQty = modifiedQty
            ? Number(modifiedQty - deliveredQty).toFixed(1)
            : Number(orderedQty - deliveredQty).toFixed(1);

          const unDeliveredAmt = modifiedQty
            ? Number(modifiedAmt - deliveredAmt).toFixed(1)
            : (orderedAmt - deliveredAmt).toFixed(2);

          if (
            deliveredQty > 0 &&
            (order.status == "Partially-Delivered" ||
              order.status == "Delivered")
          ) {
            item_summary = checkExistingItems(item_summary, "delivered", {
              item_id: order.item_id,
              item_disc: order.item_disc,
              order_qty: deliveredQty,
              mrp: deliveredAmt,
              variety: variety,
              order_number: order_number,
            });
          }
          if (short_reason_exists && unDeliveredQty > 0) {
            item_summary = checkExistingItems(item_summary, "short", {
              item_id: order.item_id,
              item_disc: order.item_disc,
              order_qty: unDeliveredQty,
              mrp: unDeliveredAmt,
              variety: variety,
              order_number: order_number,
              item_primary_reason: order.item_primary_reason,
              item_reason: order.item_reason,
              status: order.status,
            });
          }
        }

        if (
          order.status !== order_status_enum.Hold &&
          primary_reason.toLowerCase() !== "short"
        ) {
          let unDeliveredQty = null;
          let unDeliveredAmt = null;
          if (
            order.status === order_status_enum.Shipped ||
            order.status === order_status_enum["Shipped-Modified"] ||
            order.status === order_status_enum.Cancelled ||
            order.status === order_status_enum.Pending
          ) {
            unDeliveredQty = modifiedQty ?? order.order_qty;
            unDeliveredAmt = modifiedQty
              ? Number(modifiedAmt).toFixed(1)
              : orderedAmt.toFixed(2);
          } else if (
            order.status === order_status_enum["Partially-Delivered"]
          ) {
            unDeliveredQty = modifiedQty
              ? modifiedQty - deliveredQty
              : orderedQty - deliveredQty;
            unDeliveredAmt = modifiedQty
              ? Number(modifiedAmt - deliveredAmt).toFixed(1)
              : (orderedAmt - deliveredAmt).toFixed(2);
          }

          // if (driver_id.startsWith("F")) {
          //   unDeliveredQty = order.item_delivered_qty;
          //   unDeliveredAmt = deliveredAmt;
          // }

          if (unDeliveredQty > 0) {
            item_summary = checkExistingItems(item_summary, "undelivered", {
              item_id: order.item_id,
              item_disc: order.item_disc,
              order_qty: unDeliveredQty,
              mrp: unDeliveredAmt,
              variety: variety,
              order_number: order_number,
              delivered_qty: order.item_delivered_qty,
            });
          }
        }

        if (
          !order_number.includes("OR") &&
          order.status !== order_status_enum.Hold &&
          loadedQty > 0
        ) {
          item_summary = checkExistingItems(item_summary, "loaded", {
            item_id: order.item_id,
            item_disc: order.item_disc,
            order_qty: loadedQty,
            mrp: loadedAmt,
            variety: variety,
            order_number: order_number,
          });
        }

        if (notLoadedQty > 0)
          item_summary = checkExistingItems(item_summary, "notLoaded", {
            item_id: order.item_id,
            item_disc: order.item_disc,
            order_qty: notLoadedQty,
            mrp: notLoadedAmt,
            variety: variety,
            order_number: order_number,
          });
        if (order.ordered_item_id) {
          item_summary = checkExistingItems(item_summary, "replacements", {
            item_id: order.item_id,
            item_disc: order.item_disc,
            order_qty: order.order_qty,
            variety: variety,
            order_number: order_number,
            original_item_id: order.ordered_item_id,
            item_modified_qty: order.item_modified_qty,
          });
        }
      }
    }

    if (driver_id.startsWith("F")) {
      let undeliveredNew = [];
      let deliveredItemsDoH = {};

      let freshItemsLI = Object.fromEntries(
        Object.entries(freshItems).map(([key, value]) => [key, {...value}])
      );

      item_summary.delivered.forEach((item) => {
        deliveredItemsDoH[item.item_id] = item
      });

  Object.keys(freshItemsLI).forEach((fresh_LI)=>{
    const deliveredItem = deliveredItemsDoH[fresh_LI];
    let undeliveredQty = 0;
    let price_per_item = 0;

    if(deliveredItem){
      undeliveredQty = (Number(freshItemsLI[fresh_LI]?.total_qty) || 0) - (Number(deliveredItem?.order_qty) || 0);
      price_per_item = (Number(deliveredItem?.mrp) || 0)/(Number(deliveredItem?.order_qty) || 0) ;
      freshItemsLI[fresh_LI].item_disc = freshItemsLI[fresh_LI].long_description || '--';
      freshItemsLI[fresh_LI].variety = deliveredItem?.variety || '--';
    }else{
      undeliveredQty = (Number(freshItemsLI[fresh_LI].total_qty) || 0);
      let undeliveredItem = Object.values(getOrderHistory[driver_id]).find(dohItem => dohItem.item_id == fresh_LI);
      price_per_item = (Number(undeliveredItem?.mrp) || 0) / (Number(undeliveredItem?.order_qty) || 1);
      freshItemsLI[fresh_LI].item_disc = freshItemsLI[fresh_LI].long_description;
      freshItemsLI[fresh_LI].variety = deliveredItem?.variety || '--';
     
        }
    

      
        if (undeliveredQty > 0) {
          freshItemsLI[fresh_LI].order_qty = undeliveredQty;
          freshItemsLI[fresh_LI].mrp = Number(undeliveredQty * price_per_item).toFixed(2);
          undeliveredNew.push(freshItemsLI[fresh_LI]);
        }
      })

      Object.keys(freshItems).forEach((key) => {
        freshItems[key].order_qty = freshItems[key].total_qty;
        freshItems[key].item_disc = freshItems[key].long_description;
        freshItems[key].variety = freshItems[key]?.variety || "--";
      });
      item_summary.loaded = Object.keys(freshItems).map(
        (key) => freshItems[key]
      );

      item_summary.undelivered = undeliveredNew;

      try {
        total_loaded_crates =
          freshItems[Object.keys(freshItems)[0]].total_crate_count;
      } catch (err) {
        console.log(
          "Error occured while fetching loaded inventory table : ",
          err
        );
      }
      // console.log("DEBUG loaded crate count", total_loaded_crates);
    }
    console.log("DEBUG item summary", item_summary);

    setItemDetails(item_summary);
    setFreshTotalLoadedCrateCount(total_loaded_crates);
  };

  const checkExistingItems = (item_summary, status, newItem) => {
    if (!item_summary[status].some((item) => item.item_id == newItem.item_id)) {
      let order_list = [];
      order_list.push(newItem.order_number);
      newItem.order_list = order_list;
      item_summary[status].push(newItem);
    } else {
      for (var index = 0; index < item_summary[status].length; index++) {
        if (item_summary[status][index].item_id == newItem.item_id) {
          item_summary[status][index].order_qty =
            parseFloat(item_summary[status][index].order_qty) +
            parseFloat(newItem.order_qty);
          item_summary[status][index].mrp = Number(
            eval(item_summary[status][index].mrp) + eval(newItem.mrp)
          ).toFixed(2);
          item_summary[status][index].order_list.push(newItem.order_number);
          console.log("amount", item_summary[status][index].mrp);
          break;
        }
      }
    }
    return item_summary;
  };

  const generatePaymentSummary = (driver_id) => {
    let temp_payment_summary = {
      paytm: 0,
      razorpay: 0,
      card: 0,
      cash: 0,
      rupifi: 0,
      cheque: 0,
      internalsuperkredit: 0,
      superkredit: 0,
      paymentpending: 0,
      adjustment: 0,
      adjustments: 0,
      amount_diff_replacement: 0,
      total_amount_paid: 0,
    };

    let unique_orders = [];

    console.log("debug running payment summary");

    //order specific payment summary (will take values from first item of the order)
    for (var order_id in getOrderHistory[driver_id]) {
      if (
        unique_orders.includes(order_id.split("-")[0] + order_id.split("-")[1])
      )
        continue;
      unique_orders.push(order_id.split("-")[0] + order_id.split("-")[1]);
      if (
        getOrderHistory[driver_id][order_id].status &&
        (getOrderHistory[driver_id][order_id].status
          .toLowerCase()
          .indexOf("delivered") != -1 ||
          getOrderHistory[driver_id][order_id].status
            .toLowerCase()
            .indexOf("partial") != -1) &&
        "payment_details" in getOrderHistory[driver_id][order_id]
      ) {
        if (
          Object.keys(
            getOrderHistory[driver_id][order_id]["payment_details"]
          ).indexOf("payment_mode") != -1
        ) {
          let payment_mode =
            getOrderHistory[driver_id][order_id]["payment_details"][
              "payment_mode"
            ].toLowerCase();
          if (
            payment_mode == "payment_pending" ||
            payment_mode == "paymentpending"
          ) {
            temp_payment_summary["paymentpending"] += Number(
              "total_amount_paid" in
                getOrderHistory[driver_id][order_id]["payment_details"]
                ? getOrderHistory[driver_id][order_id]["payment_details"][
                    "total_amount_paid"
                  ]
                : getOrderHistory[driver_id][order_id]["payment_details"][
                    "total_amt_paid"
                  ]
            );
          } else {
            temp_payment_summary[
              getOrderHistory[driver_id][order_id]["payment_details"][
                "payment_mode"
              ].toLowerCase()
            ] += Number(
              "total_amount_paid" in
                getOrderHistory[driver_id][order_id]["payment_details"]
                ? getOrderHistory[driver_id][order_id]["payment_details"][
                    "total_amount_paid"
                  ]
                : getOrderHistory[driver_id][order_id]["payment_details"][
                    "total_amt_paid"
                  ]
            );
          }

          temp_payment_summary["total_amount_paid"] += Number(
            "total_amount_paid" in
              getOrderHistory[driver_id][order_id]["payment_details"]
              ? getOrderHistory[driver_id][order_id]["payment_details"][
                  "total_amount_paid"
                ]
              : getOrderHistory[driver_id][order_id]["payment_details"][
                  "total_amt_paid"
                ]
          );
        } else {
          let x = Object.keys(
            getOrderHistory[driver_id][order_id]["payment_details"]
          );
          x.splice(x.indexOf("total_amount_paid"), 1);
          x.forEach((key) => {
            let payment_mode =
              getOrderHistory[driver_id][order_id]["payment_details"][key][
                "payment_mode"
              ].toLowerCase();
            if (
              payment_mode == "payment_pending" ||
              payment_mode == "paymentpending"
            ) {
              temp_payment_summary["paymentpending"] += Number(
                "total_amount_paid" in
                  getOrderHistory[driver_id][order_id]["payment_details"][key]
                  ? getOrderHistory[driver_id][order_id]["payment_details"][
                      key
                    ]["total_amount_paid"]
                  : getOrderHistory[driver_id][order_id]["payment_details"][
                      key
                    ]["total_amt_paid"]
              );
            } else {
              temp_payment_summary[
                getOrderHistory[driver_id][order_id]["payment_details"][key][
                  "payment_mode"
                ].toLowerCase()
              ] += Number(
                "total_amount_paid" in
                  getOrderHistory[driver_id][order_id]["payment_details"][key]
                  ? getOrderHistory[driver_id][order_id]["payment_details"][
                      key
                    ]["total_amount_paid"]
                  : getOrderHistory[driver_id][order_id]["payment_details"][
                      key
                    ]["total_amt_paid"]
              );
            }
          });

          temp_payment_summary["total_amount_paid"] += Number(
            "total_amount_paid" in
              getOrderHistory[driver_id][order_id]["payment_details"]
              ? getOrderHistory[driver_id][order_id]["payment_details"][
                  "total_amount_paid"
                ]
              : getOrderHistory[driver_id][order_id]["payment_details"][
                  "total_amt_paid"
                ]
          );
        }
      }
    }

    //item replacement specific payment summary
    for (var order_id in getOrderHistory[driver_id]) {
      let replacement_after_delivery = getOrderHistory[driver_id][order_id][
        "replacement_after_delivery"
      ]
        ? getOrderHistory[driver_id][order_id]["replacement_after_delivery"]
        : "";
      let item_reason = getOrderHistory[driver_id][order_id]["item_reason"]
        ? getOrderHistory[driver_id][order_id]["item_reason"]
        : "";

      if (
        replacement_after_delivery == "Yes" &&
        item_reason == "Wrongly Delivered"
      ) {
        let new_mrp = getOrderHistory[driver_id][order_id]["new_mrp"]
          ? Number(getOrderHistory[driver_id][order_id]["new_mrp"])
          : 0;
        let mrp = getOrderHistory[driver_id][order_id]["mrp"]
          ? Number(getOrderHistory[driver_id][order_id]["mrp"])
          : 0;

        let amtDiffDueToReplacement = new_mrp - mrp;

        amtDiffDueToReplacement = amtDiffDueToReplacement
          ? Number(amtDiffDueToReplacement)
          : 0;

        let existingAmtDiffReplacement = temp_payment_summary[
          "amount_diff_replacement"
        ]
          ? Number(temp_payment_summary["amount_diff_replacement"])
          : 0;
        temp_payment_summary["amount_diff_replacement"] =
          existingAmtDiffReplacement + amtDiffDueToReplacement;
      }
    }

    temp_payment_summary["amount_diff_replacement"] = Number(
      temp_payment_summary["amount_diff_replacement"]
    ).toFixed(2);

    console.log("debug", temp_payment_summary);
    setPaymentSummary(temp_payment_summary);
  };

  const RenderOrders = async (driver_id) => {
    //apply filters if needed

    if (getBreadCrumb[0] == driver_id) {
      setBackDrop(false);

      return;
    }
    console.log("debug running payment summary");

    setRenderOrderDetails([]);
    let temp_payment_summary = {
      paytm: 0,
      razorpay: 0,
      card: 0,
      cash: 0,
      returns: 0,
      rupifi: 0,
      cheque: 0,
      internalsuperkredit: 0,
      superkredit: 0,
      paymentpending: 0,
      adjustment: 0,
      adjustments: 0,
      amount_diff_replacement: 0,
      total_amount_paid: 0,
    };
    let color_code = {
      cancelled: "red",
      "not visited": "blue",
      hold: "blue",
      "shop closed": "blue",
      pending: "blue",
      delivered: "green",
      "partially-delivered": "yellow",
      shipped: "orange",
      "shipped-modified": "purple",
      default: "grey",
    };
    setOrdersPerShop({});
    let temp = [];
    let temp_data = JSON.parse(sessionStorage.getItem("key"));
    let unique_orders = [];
    let unique_bulk_orders = [];
    let priority_order = {};
    const freshItems = await fetchFreshOrders(currentDate, driver_id);
    generateItemSummary(driver_id, freshItems);
    setOrdersPerShop(getItemKeys(driver_id));

    //order specific payment summary (will take values from first item of the order)
    for (var order_id in getOrderHistory[driver_id]) {
      if (
        unique_orders.includes(order_id.split("-")[0] + order_id.split("-")[1])
      )
        continue;
      unique_orders.push(order_id.split("-")[0] + order_id.split("-")[1]);
      unique_bulk_orders.push(
        `${order_id.split("-")[0]}/${order_id.split("-")[1]}`
      );

      if (order_id.includes("OR")) {
        //OR return order
        console.log("OR order found", order_id);
        temp_payment_summary.returns += Number(
          getOrderHistory[driver_id][order_id].total_amt
        );
      }

      if (
        getOrderHistory[driver_id][order_id].status &&
        (getOrderHistory[driver_id][order_id].status
          .toLowerCase()
          .indexOf("delivered") != -1 ||
          getOrderHistory[driver_id][order_id].status
            .toLowerCase()
            .indexOf("partial") != -1) &&
        "payment_details" in getOrderHistory[driver_id][order_id]
      ) {
        if (
          Object.keys(
            getOrderHistory[driver_id][order_id]["payment_details"]
          ).indexOf("payment_mode") != -1
        ) {
          let payment_mode =
            getOrderHistory[driver_id][order_id]["payment_details"][
              "payment_mode"
            ].toLowerCase();
          if (
            payment_mode == "payment_pending" ||
            payment_mode == "paymentpending"
          ) {
            temp_payment_summary["paymentpending"] += Number(
              "total_amount_paid" in
                getOrderHistory[driver_id][order_id]["payment_details"]
                ? getOrderHistory[driver_id][order_id]["payment_details"][
                    "total_amount_paid"
                  ]
                : getOrderHistory[driver_id][order_id]["payment_details"][
                    "total_amt_paid"
                  ]
            );
          } else {
            temp_payment_summary[
              getOrderHistory[driver_id][order_id]["payment_details"][
                "payment_mode"
              ].toLowerCase()
            ] += Number(
              "total_amount_paid" in
                getOrderHistory[driver_id][order_id]["payment_details"]
                ? getOrderHistory[driver_id][order_id]["payment_details"][
                    "total_amount_paid"
                  ]
                : getOrderHistory[driver_id][order_id]["payment_details"][
                    "total_amt_paid"
                  ]
            );
          }

          temp_payment_summary["total_amount_paid"] += Number(
            "total_amount_paid" in
              getOrderHistory[driver_id][order_id]["payment_details"]
              ? getOrderHistory[driver_id][order_id]["payment_details"][
                  "total_amount_paid"
                ]
              : getOrderHistory[driver_id][order_id]["payment_details"][
                  "total_amt_paid"
                ]
          );
        } else {
          let x = Object.keys(
            getOrderHistory[driver_id][order_id]["payment_details"]
          );
          x.splice(x.indexOf("total_amount_paid"), 1);
          x.forEach((key) => {
            let payment_mode =
              getOrderHistory[driver_id][order_id]["payment_details"][key][
                "payment_mode"
              ].toLowerCase();
            if (
              payment_mode == "payment_pending" ||
              payment_mode == "paymentpending"
            ) {
              temp_payment_summary["paymentpending"] += Number(
                "total_amount_paid" in
                  getOrderHistory[driver_id][order_id]["payment_details"][key]
                  ? getOrderHistory[driver_id][order_id]["payment_details"][
                      key
                    ]["total_amount_paid"]
                  : getOrderHistory[driver_id][order_id]["payment_details"][
                      key
                    ]["total_amt_paid"]
              );
            } else {
              temp_payment_summary[
                getOrderHistory[driver_id][order_id]["payment_details"][key][
                  "payment_mode"
                ].toLowerCase()
              ] += Number(
                "total_amount_paid" in
                  getOrderHistory[driver_id][order_id]["payment_details"][key]
                  ? getOrderHistory[driver_id][order_id]["payment_details"][
                      key
                    ]["total_amount_paid"]
                  : getOrderHistory[driver_id][order_id]["payment_details"][
                      key
                    ]["total_amt_paid"]
              );
            }
          });

          temp_payment_summary["total_amount_paid"] += Number(
            "total_amount_paid" in
              getOrderHistory[driver_id][order_id]["payment_details"]
              ? getOrderHistory[driver_id][order_id]["payment_details"][
                  "total_amount_paid"
                ]
              : getOrderHistory[driver_id][order_id]["payment_details"][
                  "total_amt_paid"
                ]
          );
        }
      }
      if (!(getOrderHistory[driver_id][order_id].priority in priority_order))
        priority_order[getOrderHistory[driver_id][order_id].priority] = [];
      priority_order[getOrderHistory[driver_id][order_id].priority].push(
        order_id
      );
    }

    //item replacement specific payment summary
    for (var order_id in getOrderHistory[driver_id]) {
      let replacement_after_delivery = getOrderHistory[driver_id][order_id][
        "replacement_after_delivery"
      ]
        ? getOrderHistory[driver_id][order_id]["replacement_after_delivery"]
        : "";
      let item_reason = getOrderHistory[driver_id][order_id]["item_reason"]
        ? getOrderHistory[driver_id][order_id]["item_reason"]
        : "";

      if (
        replacement_after_delivery == "Yes" &&
        item_reason == "Wrongly Delivered"
      ) {
        let new_mrp = getOrderHistory[driver_id][order_id]["new_mrp"]
          ? Number(getOrderHistory[driver_id][order_id]["new_mrp"])
          : 0;
        let mrp = getOrderHistory[driver_id][order_id]["mrp"]
          ? Number(getOrderHistory[driver_id][order_id]["mrp"])
          : 0;

        let amtDiffDueToReplacement = new_mrp - mrp;

        amtDiffDueToReplacement = amtDiffDueToReplacement
          ? Number(amtDiffDueToReplacement)
          : 0;

        let existingAmtDiffReplacement = temp_payment_summary[
          "amount_diff_replacement"
        ]
          ? Number(temp_payment_summary["amount_diff_replacement"])
          : 0;
        temp_payment_summary["amount_diff_replacement"] =
          existingAmtDiffReplacement + amtDiffDueToReplacement;
      }
    }

    let bulkOrdersNew = "(";
    unique_bulk_orders.forEach((orderNo) => {
      bulkOrdersNew += `'${orderNo}',`;
    });
    let bulkModifiedOrders = bulkOrdersNew.slice(0, -1);
    bulkModifiedOrders += ")";
    setBulkOrdersRouteWise(bulkModifiedOrders);
    temp_payment_summary["amount_diff_replacement"] = Number(
      temp_payment_summary["amount_diff_replacement"]
    ).toFixed(2);

    temp_payment_summary["total_amount_paid"] +=
      temp_payment_summary["returns"];

    setPaymentSummary(temp_payment_summary);
    let temp_existance = [];
    console.log("Priority Order");
    console.log(priority_order);
    Object.keys(priority_order).forEach((pval) => {
      for (var lists in priority_order[pval]) {
        //apply the filter here
        let current_order_color = "";
        if (getOrderHistory[driver_id][priority_order[pval][lists]].status) {
          current_order_color =
            getOrderHistory[driver_id][
              priority_order[pval][lists]
            ].status.toLowerCase() in color_code
              ? color_code[
                  getOrderHistory[driver_id][
                    priority_order[pval][lists]
                  ].status.toLowerCase()
                ]
              : color_code["default"];
        }
        if (getFilter == null || getFilter == "") {
          temp_existance.push(priority_order[pval][lists]);
          temp.push(
            <Nav.Item>
              <Nav.Link eventKey={priority_order[pval][lists]}>
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{
                    fontSize: "10px",
                    verticalAlign: "middle",
                    color: current_order_color,
                  }}
                />
                {"  " +
                  priority_order[pval][lists].split("-")[0].replace("_", "-") +
                  "/" +
                  priority_order[pval][lists].split("-")[1] +
                  " | " +
                  pval}
              </Nav.Link>
            </Nav.Item>
          );
          console.log(
            "Listing of Orders for the selected Route " +
              driver_id +
              ":: " +
              getOrderHistory[driver_id][priority_order[pval][lists]]
                .order_number
          );
          continue;
        }
        if (getFilter === null) return;
        if (getFilter.toLowerCase() == "delivered") {
          if (
            getOrderHistory[driver_id][priority_order[pval][lists]].status
              .toLowerCase()
              .indexOf("delivered") != -1 &&
            getOrderHistory[driver_id][priority_order[pval][lists]].status
              .toLowerCase()
              .indexOf("partial") == -1
          ) {
            temp_existance.push(priority_order[pval][lists]);
            temp.push(
              <Nav.Item>
                <Nav.Link eventKey={priority_order[pval][lists]}>
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{
                      fontSize: "10px",
                      verticalAlign: "middle",
                      color: current_order_color,
                    }}
                  />
                  {"  " +
                    priority_order[pval][lists].split("-")[0] +
                    "/" +
                    priority_order[pval][lists].split("-")[1] +
                    " | " +
                    pval}
                </Nav.Link>
              </Nav.Item>
            );

            continue;
          }
        }

        if (getFilter.toLowerCase() == "partial") {
          if (
            getOrderHistory[driver_id][priority_order[pval][lists]].status
              .toLowerCase()
              .indexOf("partial") != -1
          ) {
            temp_existance.push(priority_order[pval][lists]);
            temp.push(
              <Nav.Item>
                <Nav.Link eventKey={priority_order[pval][lists]}>
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{
                      fontSize: "10px",
                      verticalAlign: "middle",
                      color: current_order_color,
                    }}
                  />
                  {"  " +
                    priority_order[pval][lists].split("-")[0] +
                    "/" +
                    priority_order[pval][lists].split("-")[1] +
                    " | " +
                    pval}
                </Nav.Link>
              </Nav.Item>
            );

            continue;
          }
        }

        if (getFilter.toLowerCase() == "cancel order") {
          if (
            getOrderHistory[driver_id][priority_order[pval][lists]].status
              .toLowerCase()
              .indexOf("cancel") != -1
          ) {
            temp_existance.push(priority_order[pval][lists]);
            temp.push(
              <Nav.Item>
                <Nav.Link eventKey={priority_order[pval][lists]}>
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{
                      fontSize: "10px",
                      verticalAlign: "middle",
                      color: current_order_color,
                    }}
                  />
                  {"  " +
                    priority_order[pval][lists].split("-")[0] +
                    "/" +
                    priority_order[pval][lists].split("-")[1] +
                    " | " +
                    pval}
                </Nav.Link>
              </Nav.Item>
            );

            continue;
          }
        }

        //
        if (getFilter.toLowerCase() == "hold") {
          if (
            getOrderHistory[driver_id][priority_order[pval][lists]].status
              .toLowerCase()
              .indexOf("hold") != -1
          ) {
            temp_existance.push(priority_order[pval][lists]);
            temp.push(
              <Nav.Item>
                <Nav.Link eventKey={priority_order[pval][lists]}>
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{
                      fontSize: "10px",
                      verticalAlign: "middle",
                      color: current_order_color,
                    }}
                  />
                  {"  " +
                    priority_order[pval][lists].split("-")[0] +
                    "/" +
                    priority_order[pval][lists].split("-")[1] +
                    " | " +
                    pval}
                </Nav.Link>
              </Nav.Item>
            );

            continue;
          }
        }

        if (getFilter.toLowerCase() == "shop closed") {
          if (
            getOrderHistory[driver_id][priority_order[pval][lists]].status
              .toLowerCase()
              .indexOf("shop") != -1 &&
            getOrderHistory[driver_id][priority_order[pval][lists]].status
              .toLowerCase()
              .indexOf("closed") != -1
          ) {
            temp_existance.push(priority_order[pval][lists]);
            temp.push(
              <Nav.Item>
                <Nav.Link eventKey={priority_order[pval][lists]}>
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{
                      fontSize: "10px",
                      verticalAlign: "middle",
                      color: current_order_color,
                    }}
                  />
                  {"  " +
                    priority_order[pval][lists].split("-")[0] +
                    "/" +
                    priority_order[pval][lists].split("-")[1] +
                    " | " +
                    pval}
                </Nav.Link>
              </Nav.Item>
            );
          }

          continue;
        }

        if (getFilter.toLowerCase() == "pending") {
          if (
            getOrderHistory[driver_id][priority_order[pval][lists]].status
              .toLowerCase()
              .indexOf("pending") != -1
          ) {
            temp_existance.push(priority_order[pval][lists]);
            temp.push(
              <Nav.Item>
                <Nav.Link eventKey={priority_order[pval][lists]}>
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{
                      fontSize: "10px",
                      verticalAlign: "middle",
                      color: current_order_color,
                    }}
                  />
                  {"  " +
                    priority_order[pval][lists].split("-")[0] +
                    "/" +
                    priority_order[pval][lists].split("-")[1] +
                    " | " +
                    pval}
                </Nav.Link>
              </Nav.Item>
            );
          }
          continue;
        }

        if (getFilter.toLowerCase() == "not visited") {
          if (
            getOrderHistory[driver_id][priority_order[pval][lists]].status
              .toLowerCase()
              .indexOf("not") != -1 &&
            getOrderHistory[driver_id][priority_order[pval][lists]].status
              .toLowerCase()
              .indexOf("visited") != -1
          ) {
            temp_existance.push(priority_order[pval][lists]);
            temp.push(
              <Nav.Item>
                <Nav.Link eventKey={priority_order[pval][lists]}>
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{
                      fontSize: "10px",
                      verticalAlign: "middle",
                      color: current_order_color,
                    }}
                  />
                  {"  " +
                    priority_order[pval][lists].split("-")[0] +
                    "/" +
                    priority_order[pval][lists].split("-")[1] +
                    " | " +
                    pval}
                </Nav.Link>
              </Nav.Item>
            );
          }

          continue;
        }

        if (getFilter.toLowerCase() == "processed") {
          if (
            getOrderHistory[driver_id][priority_order[pval][lists]].status
              .toLowerCase()
              .indexOf("processed") != -1
          ) {
            temp_existance.push(priority_order[pval][lists]);
            temp.push(
              <Nav.Item>
                <Nav.Link eventKey={priority_order[pval][lists]}>
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{
                      fontSize: "10px",
                      verticalAlign: "middle",
                      color: current_order_color,
                    }}
                  />
                  {"  " +
                    priority_order[pval][lists].split("-")[0] +
                    "/" +
                    priority_order[pval][lists].split("-")[1] +
                    " | " +
                    pval}
                </Nav.Link>
              </Nav.Item>
            );
          }

          continue;
        }
        if (getFilter.toLowerCase() == "shipped") {
          if (
            getOrderHistory[driver_id][
              priority_order[pval][lists]
            ].status.toLowerCase() === "shipped"
          ) {
            temp_existance.push(priority_order[pval][lists]);
            temp.push(
              <Nav.Item>
                <Nav.Link eventKey={priority_order[pval][lists]}>
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{
                      fontSize: "10px",
                      verticalAlign: "middle",
                      color: current_order_color,
                    }}
                  />
                  {"  " +
                    priority_order[pval][lists].split("-")[0] +
                    "/" +
                    priority_order[pval][lists].split("-")[1] +
                    " | " +
                    pval}
                </Nav.Link>
              </Nav.Item>
            );
          }

          continue;
        }
        if (getFilter === "shipped-modified") {
          if (
            getOrderHistory[driver_id][
              priority_order[pval][lists]
            ].status.toLowerCase() === "shipped-modified"
          ) {
            temp_existance.push(priority_order[pval][lists]);
            temp.push(
              <Nav.Item>
                <Nav.Link eventKey={priority_order[pval][lists]}>
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{
                      fontSize: "10px",
                      verticalAlign: "middle",
                      color: current_order_color,
                    }}
                  />
                  {"  " +
                    priority_order[pval][lists].split("-")[0] +
                    "/" +
                    priority_order[pval][lists].split("-")[1] +
                    " | " +
                    pval}
                </Nav.Link>
              </Nav.Item>
            );
          }

          continue;
        }
      }
    });

    if (temp_existance.length == 0) {
      setAlertMessage(`No Orders found for ${getFilter} status`);
      setAlertType("error");
      setAlertStatus(true);
    }

    setRenderOrder([
      <Tab.Container
        id="left-tabs-example"
        onSelect={(e) => {
          setBreadCrumb2(e);
          RenderOrdersDetails(driver_id, e);
        }}
        defaultActiveKey={temp_data ? temp_data.order_id : temp_existance[0]}
        key={temp_data ? temp_data.order_id : temp_existance[0]}
      >
        <Col sm={2} style={{ overflowY: "scroll", maxHeight: "80vh" }}>
          <Nav
            ref={activeOrderRef}
            variant="pills"
            style={{ backgroundColor: "#faf9f5" }}
            className="flex-column"
          >
            {temp}
          </Nav>
        </Col>
      </Tab.Container>,
    ]);
    console.log(activeOrderRef.current);

    if (temp_data) {
      RenderOrdersDetails(temp_data.driver_id, temp_data.order_id);
      setAlertStatus(true);
      setAlertType("success");
      setAlertMessage("Successfully Updated database");
      setDate(new Date(temp_data.current_date));
      setPrimaryDropDownMessage(temp_data.driver_id);
    }

    let vehicleReloadedRoute = sessionStorage.getItem("vehicleReloaded");

    let temp_filter = JSON.parse(sessionStorage.getItem("filter"));
    if (temp_filter) {
      setDate(new Date(temp_filter.current_date));
      setPrimaryDropDownMessage(temp_filter.driver_id);
      setBackDrop(false);
      sessionStorage.removeItem("filter");
    } else if (vehicleReloadedRoute) {
      setPrimaryDropDownMessage(vehicleReloadedRoute);
      setBackDrop(false);
      sessionStorage.removeItem("vehicleReloaded");
    } else {
      setBackDrop(false);
    }

    sessionStorage.removeItem("key");
    sessionStorage.removeItem("filterdate");

    if (!temp_data && temp_existance.length != 0) {
      // RenderOrdersDetails(driver_id,priority_order['1'][0])

      //do modifications here

      setBreadCrumb2(temp_existance[0]);
      RenderOrdersDetails(driver_id, temp_existance[0]);
    }
  };

  useEffect(() => {
    if (currentDate) {
      let deliveryDateList = currentDate.split("-");
      let year = deliveryDateList[2];
      let month = deliveryDateList[1];
      let date = deliveryDateList[0];
      date = date < 10 ? "0" + date : date;
      month = month < 10 ? "0" + month : month;
      let formattedDeliveryDate = `${year}-${month}-${date}`;

      Promise.all([
        fetch(
          `${service_url}/delivery-picking-date?delivery_date=${formattedDeliveryDate}`
        ).then((response) => response.json()),
        fetch(
          `${service_url}/api/transit-stock-transfer?delivery_date=${formattedDeliveryDate}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MTU0OWIwMTIwMWUyZjMzZWE3NmFkZjYiLCJlbWFpbCI6InNtdHdpbmtsZTQ1MkBnbWFpbC5jb20iLCJpYXQiOjE2MzI5MzQ2NTgsImV4cCI6MTYzMjkzODI1OH0._oHr3REme2pjDDdRliArAeVG_HuimbdM5suTw8HI7ut`
        ).then((response) => response.json()),
        fetch(`${service_url}/api/invoice-type`).then((response) => response.json())
      ])
        .then(([dateData, transitData, invoiceType]) => {
          let dateObj = dateData.data || {};
          let transitList = transitData.data || [];
          let invoiceList = invoiceType.data || [];
          sessionStorage.setItem("invoiceList", JSON.stringify(invoiceList));
          let transitObj = {};
          for (let transit of transitList) {
            let route = transit.route || "";
            let transit_id = transit.id || "";

            transitObj[route] = transit_id;
          }
          setIsTransitDone(transitObj);

          if (Object.keys(dateObj).length > 0) {
            let picking_date = new Date(dateObj.picking_date);
            let pickYear = picking_date.getFullYear();
            let pickMonth = picking_date.getMonth() + 1;
            let pickDate = picking_date.getDate();
            let pickingStr = `${pickYear}/${pickMonth}/${pickDate}-${pickMonth}-${pickYear}`;

            setPickingDate(`${pickDate}-${pickMonth}-${pickYear}`);
            generateFlagMaps(currentDate, pickingStr);
          } else {
            alert(
              `No picking date present corresponding to delivery date - ${formattedDeliveryDate}`
            );
            generateFlagMaps(currentDate, "");
          }
        })
        .catch((err) => {
          alert(err);
          alert(
            `No picking date present corresponding to delivery date - ${formattedDeliveryDate}`
          );
          generateFlagMaps(currentDate, "");
        });
    }
  }, [currentDate]);

  const generateFlagMaps = (currentDate, pickingStr) => {
    let pickingString = `${currentDate.split("-")[2]}/${
      currentDate.split("-")[1]
    }/${currentDate}`;
    if (pickingStr) {
      pickingString = pickingStr;
    }

    Promise.all([
      fire
        .database()
        .ref(
          `Driver_Allocation_History/${currentDate.split("-")[2]}/${
            currentDate.split("-")[1]
          }/${currentDate}`
        )
        .once("value"),
      fire
        .database()
        .ref(`Picking_Allocation_History/${pickingString}`)
        .once("value"),
    ]).then(([snapshot, pickingSnap]) => {
      let allocationObj = pickingSnap.val() || {};
      let pickingRouteStatusMap = {};
      Object.keys(allocationObj).map((pkey, pindex) => {
        let routeObj = allocationObj[pkey] || {};

        Object.keys(routeObj).map((rkey, rindex) => {
          let status = routeObj[rkey].status || "";

          pickingRouteStatusMap[rkey] = status;
        });
      });

      sessionStorage.setItem(
        "pickingRouteStatusMap",
        JSON.stringify(pickingRouteStatusMap)
      );

      if (snapshot.hasChildren()) {
        let driverObj = snapshot.val();

        let driverOrderConsolidationFlag = {};
        let operationsDispatchedVehicle = {};
        let billingCheckedStocks = {};
        let vehicleNotReturned = {};
        let deliveryEndFlag = {};
        Object.keys(driverObj).map((key, index) => {
          let route = driverObj[key].current_Route_Id;
          let completed_consolidation =
            driverObj[key].completed_conslidation == true ? true : false;
          let dispatched_vehicle =
            driverObj[key].dispatched_vehicle == true ? true : false;
          let stock_checked =
            driverObj[key].stock_checked == true ? true : false;
          operationsDispatchedVehicle[route] = dispatched_vehicle;

          deliveryEndFlag[route] =
            driverObj[key].endDeliveryFlag == true ? true : false;

          if (
            validateIsAdminUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            )
          ) {
            completed_consolidation = false;
          }

          driverOrderConsolidationFlag[route] = completed_consolidation;
          vehicleNotReturned[route] =
            driverObj[key].vehicle_not_returned == true ? true : false;
          billingCheckedStocks[route] = stock_checked;
        });
        console.log(driverOrderConsolidationFlag);
        console.log(operationsDispatchedVehicle);
        console.log(billingCheckedStocks);

        setRouteOrdersConsolidationFlag(driverOrderConsolidationFlag);
        setOpsDispatchedVehicleFlag(operationsDispatchedVehicle);
        setBillingStockChecked(billingCheckedStocks);
        setVehicleNotReturnedMap(vehicleNotReturned);
        setDeliveryEndFlagMap(deliveryEndFlag);
        setIsRoutePickingCompletedFlag(pickingRouteStatusMap);
      } else {
        setRouteOrdersConsolidationFlag({});
        setOpsDispatchedVehicleFlag({});
        setBillingStockChecked({});
        setVehicleNotReturnedMap({});
        setDeliveryEndFlagMap({});
        setIsRoutePickingCompletedFlag(pickingRouteStatusMap);
      }
    });
  };

  useEffect(() => {
    if (currentDate) {
      let currentDate = sessionStorage.getItem(`selectedInputDate`);
      setEndDeliveryConfirm(false);
      setSuperKreditEndFlag(false);
      setCashbackEndFlag(false);
      setNotificationFlag(false);

      const Driver_allocation = fire
        .database()
        .ref(
          `Driver_Allocation_History/${currentDate.split("-")[2]}/${
            currentDate.split("-")[1]
          }/${currentDate}`
        );
      Driver_allocation.orderByKey()
        .limitToLast(1)
        .once("value", (snapShot) => {
          // this was causing page blank issue for historical dates
          setDB(
            `Driver_Orders_History/${currentDate.split("-")[2]}/${
              currentDate.split("-")[1]
            }/${currentDate}`
          ); // Calling Driver Orders History
          const driverRouteUniq = snapShot.val();
          console.log("Driver Allocation Data:: ");
          console.log(driverRouteUniq);
          if (!driverRouteUniq) return;

          Object.keys(driverRouteUniq).forEach((item) => {
            if (driverRouteUniq[item].hasOwnProperty("endDeliveryFlag")) {
              setEndDeliveryConfirm(driverRouteUniq[item].endDeliveryFlag);
              console.log(
                "End Delivery Flag:: " + driverRouteUniq[item].endDeliveryFlag
              );
            }

            if (driverRouteUniq[item].hasOwnProperty("superkredit_flag")) {
              setSuperKreditEndFlag(driverRouteUniq[item].superkredit_flag);
            }

            if (driverRouteUniq[item].hasOwnProperty("cashback_flag")) {
              setCashbackEndFlag(driverRouteUniq[item].cashback_flag);
            }

            if (driverRouteUniq[item].notification_sent) {
              setNotificationFlag(driverRouteUniq[item].notification_sent);
            }
          });
        });
    }
  }, [currentDate]);

  useEffect(() => {
    if (
      getCallback.driver_id != "" &&
      (getCallback.order_id != "") & (getCallback.status != "")
    ) {
      fireBaseStatusUpdate(
        getCallback.driver_id,
        getCallback.order_id,
        getCallback.status
      );
    }
  }, [getCallback, getOrdersPerShop]);

  const firebaseUpdateShippedStatus = (driver_id, order_id) => {
    console.log("firebaseUpdateShippedStatus");
    setBackDrop(true);

    let driverOrders = getOrderHistory[driver_id];
    let orderKey = order_id.split("-")[0] + "-" + order_id.split("-")[1];
    let temp_order_history = getOrderHistory;

    console.log(driverOrders);

    Object.keys(driverOrders).map((key, value) => {
      if (key.includes(orderKey)) {
        var current_url = getDB + "/" + driver_id + "/" + key;
        fire.database().ref(current_url).child("item_modified_qty").remove();
        fire.database().ref(current_url).child("item_status").remove();
        fire.database().ref(current_url).child("item_reason").remove();
        fire.database().ref(current_url).child("item_primary_reason").remove();
        fire.database().ref(current_url).child("revised_order_amt").remove();
        fire.database().ref(current_url).child("revised_order_disc").remove();
        fire.database().ref(current_url).child("item_delivered_qty").remove();
        fire.database().ref(current_url).child("revised_item_price").remove();

        fire.database().ref(current_url).update({
          status: "Shipped",
          updatedBy: usermodifying,
          updatedAt: UpdatedDate(),
        });

        delete temp_order_history[driver_id][key]["revised_order_amt"];
        delete temp_order_history[driver_id][key]["item_modified_qty"];
        delete temp_order_history[driver_id][key]["revised_order_disc"];
        delete temp_order_history[driver_id][key]["item_delivered_qty"];
        delete temp_order_history[driver_id][key]["revised_item_price"];
        temp_order_history[driver_id][key]["item_status"] = "";
        temp_order_history[driver_id][key]["item_reason"] = "";
        temp_order_history[driver_id][key]["item_primary_reason"] = "";
        temp_order_history[driver_id][key]["status"] = "Shipped";
      }
    });

    setOrderHistory(temp_order_history);
    generateItemSummary(driver_id);
    RenderOrdersDetails(driver_id, order_id);

    setTimeout(function () {
      if (getBackDrop) {
        setBackDrop(false);
      }
    }, 3000);
  };

  const revisedAmt = (itemsInOrders, orderNo) => {
    let undeliveredItemAmt = 0;
    let totalAmtPaid = 0;
    let totalOrderValue;
    Object.keys(itemsInOrders).forEach((orderNumber) => {
      if (
        !orderNumber.includes(
          `${orderNo.split("-")[0]}-${orderNo.split("-")[1]}`
        )
      )
        return;

      if (
        (itemsInOrders[orderNumber].item_delivered_qty &&
          itemsInOrders[orderNumber].mrp) ||
        (itemsInOrders[orderNumber].item_modified_qty &&
          itemsInOrders[orderNumber].mrp)
      ) {
        setRevisedAmtPresent(true);
        if (
          itemsInOrders[orderNumber].status
            .toLowerCase()
            .includes(`partially-delivered`) &&
          itemsInOrders[orderNumber].item_delivered_qty
        ) {
          // console.log( itemsInOrders[orderNumber].item_id+" ItemDeliveredQty is present::: "+itemsInOrders[orderNumber].order_qty+" - DeliveredQty:: "+itemsInOrders[orderNumber].item_delivered_qty)
          undeliveredItemAmt +=
            (Number(itemsInOrders[orderNumber].mrp) /
              Number(itemsInOrders[orderNumber].order_qty)) *
            (Number(itemsInOrders[orderNumber].order_qty) -
              Number(itemsInOrders[orderNumber].item_delivered_qty));
        } else if (
          (itemsInOrders[orderNumber].status
            .toLowerCase()
            .includes(`shipped`) ||
            itemsInOrders[orderNumber].status
              .toLowerCase()
              .includes(`partially-delivered`)) &&
          itemsInOrders[orderNumber].item_modified_qty
        ) {
          // console.log(itemsInOrders[orderNumber].item_id+" ItemModifiedQty is present::: "+itemsInOrders[orderNumber].order_qty+" - ModifiedQty:: "+itemsInOrders[orderNumber].item_modified_qty)
          undeliveredItemAmt +=
            (Number(itemsInOrders[orderNumber].mrp) /
              Number(itemsInOrders[orderNumber].order_qty)) *
            (Number(itemsInOrders[orderNumber].order_qty) -
              Number(itemsInOrders[orderNumber].item_modified_qty));
        }
      }

      totalOrderValue = Number(itemsInOrders[orderNumber].total_amt);
      totalAmtPaid = itemsInOrders[orderNumber]["payment_details"]
        ? itemsInOrders[orderNumber]["payment_details"]["total_amount_paid"]
        : 0;
    });
    console.log("UndeliveredAmt:: " + undeliveredItemAmt);
    console.log("TotalOrderValue:: " + totalOrderValue);
    if (!undeliveredItemAmt) {
      undeliveredItemAmt = 0;
    }
    let orderDisc = itemsInOrders[orderNo].order_disc
      ? Number(itemsInOrders[orderNo].order_disc)
      : 0;
    let revisedOrderDisc = itemsInOrders[orderNo].revised_order_disc
      ? Number(itemsInOrders[orderNo].revised_order_disc)
      : orderDisc;
    sessionStorage.setItem(
      "AmtDiff",
      (
        totalOrderValue -
        undeliveredItemAmt -
        totalAmtPaid +
        orderDisc -
        revisedOrderDisc
      ).toFixed(2)
    );

    return totalOrderValue - undeliveredItemAmt;
  };

  const calculateTotalAmountToBePaid = (itemsInOrders, orderNo) => {
    let totalOrderAmountToBePaid = 0;
    let index = 0;
    Object.keys(itemsInOrders).forEach((orderNumber) => {
      if (
        !orderNumber.includes(
          `${orderNo.split("-")[0]}-${orderNo.split("-")[1]}`
        )
      )
        return;
      let itemAmt = Number(itemsInOrders[orderNumber].mrp);
      if (!isNaN(itemAmt) && itemAmt) {
        totalOrderAmountToBePaid += itemAmt;
      }
      let wallet = Number(itemsInOrders[orderNumber].cashback_redeemed);
      let discount = Number(itemsInOrders[orderNumber].order_disc);

      if (!isNaN(wallet) && wallet && index == 0) {
        totalOrderAmountToBePaid -= wallet;
      }
      if (!isNaN(discount) && discount && index == 0) {
        totalOrderAmountToBePaid -= discount;
      }

      index++;
    });
    return totalOrderAmountToBePaid.toFixed(2);
  };

  const revisedAmtForOffersWithExcludedProducts = (
    itemsInOrders,
    orderNo,
    excludedProducts,
    offerCategory
  ) => {
    if (offerCategory) {
      offerCategory = offerCategory.toLowerCase();
    }

    let totalOrderAmount = 0;

    Object.keys(itemsInOrders).forEach((orderNumber) => {
      if (
        !orderNumber.includes(
          `${orderNo.split("-")[0]}-${orderNo.split("-")[1]}`
        )
      )
        return;

      let itemAmt = 0;
      let itemID = itemsInOrders[orderNumber].item_id;

      let category = itemsInOrders[orderNumber]["category"]
        ? itemsInOrders[orderNumber]["category"]
        : "";
      let sub_category = itemsInOrders[orderNumber]["sub_category"]
        ? itemsInOrders[orderNumber]["sub_category"]
        : "";
      let brand = itemsInOrders[orderNumber]["brand"]
        ? itemsInOrders[orderNumber]["brand"]
        : "";

      category = category.toLowerCase();
      sub_category = sub_category.toLowerCase();
      brand = brand.toLowerCase();

      if (
        excludedProducts[itemID] == undefined &&
        (!offerCategory ||
          (offerCategory &&
            (category.includes(offerCategory) ||
              sub_category.includes(offerCategory) ||
              brand.includes(offerCategory))))
      ) {
        if (
          (itemsInOrders[orderNumber].item_delivered_qty &&
            itemsInOrders[orderNumber].mrp) ||
          (itemsInOrders[orderNumber].item_modified_qty &&
            itemsInOrders[orderNumber].mrp)
        ) {
          if (
            itemsInOrders[orderNumber].status
              .toLowerCase()
              .includes(`partially-delivered`) &&
            itemsInOrders[orderNumber].item_delivered_qty
          ) {
            // console.log( itemsInOrders[orderNumber].item_id+" ItemDeliveredQty is present::: "+itemsInOrders[orderNumber].order_qty+" - DeliveredQty:: "+itemsInOrders[orderNumber].item_delivered_qty)
            itemAmt =
              (Number(itemsInOrders[orderNumber].mrp) /
                Number(itemsInOrders[orderNumber].order_qty)) *
              Number(itemsInOrders[orderNumber].item_delivered_qty);
          } else if (
            (itemsInOrders[orderNumber].status
              .toLowerCase()
              .includes(`shipped`) ||
              itemsInOrders[orderNumber].status
                .toLowerCase()
                .includes(`partially-delivered`)) &&
            itemsInOrders[orderNumber].item_modified_qty
          ) {
            // console.log(itemsInOrders[orderNumber].item_id+" ItemModifiedQty is present::: "+itemsInOrders[orderNumber].order_qty+" - ModifiedQty:: "+itemsInOrders[orderNumber].item_modified_qty)
            itemAmt =
              (Number(itemsInOrders[orderNumber].mrp) /
                Number(itemsInOrders[orderNumber].order_qty)) *
              Number(itemsInOrders[orderNumber].item_modified_qty);
          } else {
            itemAmt = Number(itemsInOrders[orderNumber].mrp);
          }
        } else {
          itemAmt = Number(itemsInOrders[orderNumber].mrp);
        }
      }

      if (!isNaN(itemAmt) && itemAmt) {
        totalOrderAmount += Number(itemAmt);
      }
    });

    return totalOrderAmount;
  };

  useEffect(() => {
    Promise.all([
      fetch(
        `${orderingApiUrl}/offers?token=eyJhbGciOiJIUzI1NiIsInR5cCI6Ik`
      ).then((response) => response.json()),
      fire.database().ref("Offers_Custom_Level").once("value"),
    ])
      .then(([customerOffersData, customofferssnap]) => {
        let offersObj = {};
        let customOffersObj = customofferssnap.hasChildren()
          ? customofferssnap.val()
          : {};

        let priceDetailsList = [];

        if (customerOffersData.success == 1) {
          let customOffers = customerOffersData["data"] || [];

          for (let customOffer of customOffers) {
            let coupon_code = customOffer.coupon_code;
            offersObj[coupon_code] = customOffer;
          }
        }

        localStorage.setItem("offersObj", JSON.stringify(offersObj));
        localStorage.setItem(
          "customOffersObj",
          JSON.stringify(customOffersObj)
        );
        setOffersObject(offersObj);
        setCustomOffersObject(customOffersObj);
      })
      .catch((err) => {
        alert(`Error in fetching offers : ${err}`);
      });
  }, []);

  const getWeightFromItemDescription = (item_disc, orderQty) => {
    let itemWeight, orderWeight;
    if (item_disc != undefined) {
      let weightFromDesc = item_disc.substring(
        item_disc.lastIndexOf("-") + 1,
        item_disc.length
      );
      let itemWtArray = weightFromDesc.split(" ");
      itemWeight = itemWtArray[itemWtArray.length - 2];

      if (weightFromDesc.includes("gm") || weightFromDesc.includes("ml")) {
        itemWeight = itemWeight / 1000;
      }
      if (weightFromDesc.includes("1 pc") || weightFromDesc.includes("1 Pc")) {
        if (item_disc.includes("gm")) {
          var itemWtExtract = item_disc.split("gm");
          let itemWtExtract2 = itemWtExtract[0].split("-");
          itemWeight =
            Number(
              itemWtExtract2[itemWtExtract2.length - 1].replace(/[^0-9]/g, "")
            ) / 1000;
        } else if (item_disc.includes("kg")) {
          var itemWtExtract = item_disc.split("kg");
          itemWeight = Number(itemWtExtract[0].replace(/[^0-9]/g, ""));
        }
      }
      if (itemWeight <= 0) {
        itemWeight = 1;
      }
      if (orderQty) {
        orderWeight = itemWeight * Number(orderQty);
      } else {
        orderWeight = "";
      }
    }

    if (isNaN(orderWeight) || orderWeight == undefined) {
      itemWeight = 1;
      orderWeight = 1;
    }

    return orderWeight;
  };

  const revisedAmtForApiOffersWithExcludedProducts = (
    itemsInOrders,
    orderNo,
    excludedProducts,
    offerCategory,
    minimumBuyProduct,
    offerSubCategory,
    excludedCategory
  ) => {
    if (offerCategory) {
      offerCategory = offerCategory.toLowerCase();
    }

    if (offerSubCategory) {
      offerSubCategory = offerSubCategory.toLowerCase();
    }

    if (excludedCategory) {
      excludedCategory = excludedCategory.toLowerCase();
    }

    let totalOrderAmount = 0;
    let categoryProductCount = 0;
    let categoryProductWeight = 0;

    Object.keys(itemsInOrders).forEach((orderNumber) => {
      if (
        !orderNumber.includes(
          `${orderNo.split("-")[0]}-${orderNo.split("-")[1]}`
        )
      )
        return;

      let itemAmt = 0;
      let itemID = itemsInOrders[orderNumber].item_id;
      let itemDisc = itemsInOrders[orderNumber].item_disc;
      let itemQty = itemsInOrders[orderNumber].hasOwnProperty(
        "item_delivered_qty"
      )
        ? Number(itemsInOrders[orderNumber].item_delivered_qty)
        : itemsInOrders[orderNumber].hasOwnProperty("item_modified_qty")
        ? Number(itemsInOrders[orderNumber].item_modified_qty)
        : Number(itemsInOrders[orderNumber].order_qty);

      let itemWeight = getWeightFromItemDescription(itemDisc, itemQty);
      let caseSize = Number(itemsInOrders[orderNumber].case_size) || 1;

      let category = itemsInOrders[orderNumber]["category"]
        ? itemsInOrders[orderNumber]["category"]
        : "";
      let sub_category = itemsInOrders[orderNumber]["sub_category"]
        ? itemsInOrders[orderNumber]["sub_category"]
        : "";
      let brand = itemsInOrders[orderNumber]["brand"]
        ? itemsInOrders[orderNumber]["brand"]
        : "";

      category = category.toLowerCase();
      sub_category = sub_category.toLowerCase();
      brand = brand.toLowerCase();

      if (
        excludedProducts[itemID] == undefined &&
        (!excludedCategory ||
          (excludedCategory &&
            !excludedCategory.includes(category) &&
            !excludedCategory.includes(brand))) &&
        (!offerCategory ||
          (offerCategory &&
            (category.includes(offerCategory) ||
              sub_category.includes(offerCategory) ||
              brand.includes(offerCategory)))) &&
        (!offerSubCategory ||
          (offerSubCategory &&
            (category.includes(offerSubCategory) ||
              sub_category.includes(offerSubCategory) ||
              brand.includes(offerSubCategory))))
      ) {
        if (
          (itemsInOrders[orderNumber].item_delivered_qty &&
            itemsInOrders[orderNumber].mrp) ||
          (itemsInOrders[orderNumber].item_modified_qty &&
            itemsInOrders[orderNumber].mrp)
        ) {
          if (
            itemsInOrders[orderNumber].status
              .toLowerCase()
              .includes(`partially-delivered`) &&
            itemsInOrders[orderNumber].item_delivered_qty
          ) {
            // console.log( itemsInOrders[orderNumber].item_id+" ItemDeliveredQty is present::: "+itemsInOrders[orderNumber].order_qty+" - DeliveredQty:: "+itemsInOrders[orderNumber].item_delivered_qty)
            itemAmt =
              (Number(itemsInOrders[orderNumber].mrp) /
                Number(itemsInOrders[orderNumber].order_qty)) *
              Number(itemsInOrders[orderNumber].item_delivered_qty);
          } else if (
            (itemsInOrders[orderNumber].status
              .toLowerCase()
              .includes(`shipped`) ||
              itemsInOrders[orderNumber].status
                .toLowerCase()
                .includes(`partially-delivered`)) &&
            itemsInOrders[orderNumber].item_modified_qty
          ) {
            // console.log(itemsInOrders[orderNumber].item_id+" ItemModifiedQty is present::: "+itemsInOrders[orderNumber].order_qty+" - ModifiedQty:: "+itemsInOrders[orderNumber].item_modified_qty)
            itemAmt =
              (Number(itemsInOrders[orderNumber].mrp) /
                Number(itemsInOrders[orderNumber].order_qty)) *
              Number(itemsInOrders[orderNumber].item_modified_qty);
          } else {
            itemAmt = Number(itemsInOrders[orderNumber].mrp);
          }
        } else {
          itemAmt = Number(itemsInOrders[orderNumber].mrp);
        }
      }

      if (minimumBuyProduct) {
        if (
          minimumBuyProduct.includes(String(itemsInOrders[orderNumber].item_id))
        ) {
          let prodQty = Number(itemsInOrders[orderNumber].order_qty);

          if (itemsInOrders[orderNumber].item_modified_qty) {
            prodQty = Number(itemsInOrders[orderNumber].item_modified_qty);
          }

          if (itemsInOrders[orderNumber].item_delivered_qty) {
            prodQty = Number(itemsInOrders[orderNumber].item_delivered_qty);
          }
          itemAmt =
            (Number(itemsInOrders[orderNumber].mrp) /
              Number(itemsInOrders[orderNumber].order_qty)) *
            Number(prodQty);

          totalOrderAmount += Number(itemAmt);
          categoryProductCount += prodQty / caseSize;
          categoryProductWeight += itemWeight;
        }
      } else if (
        offerSubCategory &&
        (category.includes(offerSubCategory) ||
          sub_category.includes(offerSubCategory) ||
          brand.includes(offerSubCategory))
      ) {
        if (
          !excludedCategory ||
          (excludedCategory &&
            !excludedCategory.includes(category) &&
            !excludedCategory.includes(brand))
        ) {
          let prodQty = Number(itemsInOrders[orderNumber].order_qty);

          if (itemsInOrders[orderNumber].item_modified_qty) {
            prodQty = Number(itemsInOrders[orderNumber].item_modified_qty);
          }

          if (itemsInOrders[orderNumber].item_delivered_qty) {
            prodQty = Number(itemsInOrders[orderNumber].item_delivered_qty);
          }
          itemAmt =
            (Number(itemsInOrders[orderNumber].mrp) /
              Number(itemsInOrders[orderNumber].order_qty)) *
            Number(prodQty);

          if (!isNaN(itemAmt) && itemAmt) {
            totalOrderAmount += Number(itemAmt);
          }

          categoryProductCount += prodQty / caseSize;
          categoryProductWeight += itemWeight;
        }
      } else if (
        offerCategory &&
        (category.includes(offerCategory) ||
          sub_category.includes(offerCategory) ||
          brand.includes(offerCategory))
      ) {
        if (
          !excludedCategory ||
          (excludedCategory &&
            !excludedCategory.includes(category) &&
            !excludedCategory.includes(brand))
        ) {
          if (!isNaN(itemAmt) && itemAmt) {
            totalOrderAmount += Number(itemAmt);
          }

          let prodQty = Number(itemsInOrders[orderNumber].order_qty);

          if (itemsInOrders[orderNumber].item_modified_qty) {
            prodQty = Number(itemsInOrders[orderNumber].item_modified_qty);
          }

          if (itemsInOrders[orderNumber].item_delivered_qty) {
            prodQty = Number(itemsInOrders[orderNumber].item_delivered_qty);
          }

          categoryProductCount += prodQty / caseSize;
          categoryProductWeight += itemWeight;
        }
      } else if (
        !isNaN(itemAmt) &&
        itemAmt &&
        !offerCategory &&
        !offerSubCategory
      ) {
        totalOrderAmount += Number(itemAmt);
      }
    });

    return [totalOrderAmount, categoryProductCount, categoryProductWeight];
  };

  const getExcludedProducts = (excludedProductsStr) => {
    let excludedProductsList = excludedProductsStr.split(",");
    let firebaseExcludedProductsObj = {};

    for (let i = 0; i < excludedProductsList.length; i++) {
      let prod = excludedProductsList[i];
      firebaseExcludedProductsObj[prod] = prod;
    }

    return firebaseExcludedProductsObj;
  };

  const revisedOrderDiscount = (
    driver_id,
    itemsInOrders,
    orderNo,
    revisedOrderValue,
    couponCode,
    isUpdateRequired
  ) => {
    let offersObj =
      Object.keys(getOffersObject).length > 0
        ? getOffersObject
        : localStorage.getItem("offersObj")
        ? JSON.parse(localStorage.getItem("offersObj"))
        : {};

    let customOffersObj =
      Object.keys(getCustomOffersObject).length > 0
        ? getCustomOffersObject
        : localStorage.getItem("customOffersObj")
        ? JSON.parse(localStorage.getItem("customOffersObj"))
        : {};

    if (offersObj || customOffersObj) {
      console.log(offersObj);
      let cashbackOffersObj = {};

      //custom offers - firebase
      Object.keys(customOffersObj).map((customofferkey, customofferindex) => {
        let mode = customOffersObj[customofferkey].mode
          ? customOffersObj[customofferkey].mode
          : "";
        mode = mode.toLowerCase();

        if (mode == "instant") {
          let coupon_code = customOffersObj[customofferkey].coupon_code;
          let discountValue = customOffersObj[customofferkey]["discount_value"];
          let discountPercent =
            customOffersObj[customofferkey]["discount_percent"];
          let ele = {};
          ele["coupon_code"] = coupon_code;
          ele["discountValue"] = discountValue;
          ele["discountPercent"] = discountPercent;
          ele["excluded_products"] =
            customOffersObj[customofferkey]["excluded_products"];
          ele["minimum_order_value"] =
            customOffersObj[customofferkey]["minimum_order_value"];
          ele["category"] = customOffersObj[customofferkey]["category"]
            ? customOffersObj[customofferkey]["category"]
            : "";
          cashbackOffersObj[coupon_code] = ele;
        }
      });
      console.log("cashbackOffersObj");
      console.log(cashbackOffersObj);

      // customer offers
      Object.keys(offersObj).map((offerkey, offerindex) => {
        let mode = offersObj[offerkey].mode ? offersObj[offerkey].mode : "";
        mode = mode.toLowerCase();

        if (mode == "cashback" || mode == "instant") {
          let coupon_code = offersObj[offerkey].coupon_code;
          let discountValue = offersObj[offerkey]["discount_value"];
          let discountPercent = offersObj[offerkey]["discount_percent"];
          let multiple = offersObj[offerkey].multiple == "Y" ? true : false;

          let minimum_category_value =
            Number(offersObj[offerkey]["minimum_category_value"]) || 0;
          let minimum_category_unit =
            Number(offersObj[offerkey]["minimum_category_unit"]) || 0;
          let minimum_category_weight =
            Number(offersObj[offerkey]["minimum_category_weight"]) || 0;
          let maximum_order_value = offersObj[offerkey]["maximum_order_value"]
            ? Number(offersObj[offerkey]["maximum_order_value"])
            : null;
          let maximum_discount_amount = offersObj[offerkey][
            "maximum_discount_amount"
          ]
            ? Number(offersObj[offerkey]["maximum_discount_amount"])
            : null;

          let minimum_buy_product =
            offersObj[offerkey]["minimum_buy_product"] || "";
          let minimum_buy_product_unit =
            Number(offersObj[offerkey]["minimum_buy_product_unit"]) || 0;
          let minimum_buy_product_value =
            Number(offersObj[offerkey]["minimum_buy_product_value"]) || 0;
          let minimum_buy_product_weight =
            Number(offersObj[offerkey]["minimum_buy_product_weight"]) || 0;

          let ele = {};
          ele["coupon_code"] = coupon_code;
          ele["discountValue"] = discountValue;
          ele["discountPercent"] = discountPercent;
          ele["excluded_products"] =
            offersObj[offerkey]["excluded_products"] || {};
          ele["excluded_category"] =
            offersObj[offerkey]["excluded_category"] || "";
          ele["minimum_order_value"] =
            offersObj[offerkey]["minimum_order_value"];
          ele["category"] = offersObj[offerkey]["category"] || "";
          ele["sub_category"] = offersObj[offerkey]["sub_category"] || "";
          ele["minimum_category_unit"] = minimum_category_unit;
          ele["minimum_category_value"] = minimum_category_value;
          ele["minimum_category_weight"] = minimum_category_weight;
          ele["minimum_buy_product"] = minimum_buy_product;
          ele["minimum_buy_product_unit"] = minimum_buy_product_unit;
          ele["minimum_buy_product_value"] = minimum_buy_product_value;
          ele["minimum_buy_product_weight"] = minimum_buy_product_weight;
          ele["multiple"] = multiple;
          cashbackOffersObj[coupon_code] = ele;
        }
      });

      console.log(cashbackOffersObj);

      let temp_order_history = getOrderHistory;
      let order_disc = temp_order_history[driver_id][orderNo].order_disc
        ? Number(temp_order_history[driver_id][orderNo].order_disc)
        : 0;

      revisedOrderValue = Number(revisedOrderValue);

      let firstOrder = orderNo.split("-")[1];
      let firstOrderDiscount = 0;

      let lastChar = couponCode.slice(-1);
      if (lastChar == ",") {
        couponCode = couponCode.slice(0, -1);
      }

      if (couponCode.includes(",")) {
        //multiple coupons
        let couponCodeList = couponCode.split(",");
        let totalOrderDiscount = 0;

        for (let i = 0; i < couponCodeList.length; i++) {
          let coupon_code = couponCodeList[i];
          if (cashbackOffersObj) {
            if (cashbackOffersObj[coupon_code]) {
              let category = cashbackOffersObj[coupon_code]["category"]
                ? cashbackOffersObj[coupon_code]["category"]
                : "";
              let sub_category =
                cashbackOffersObj[coupon_code]["sub_category"] || "";
              let discount_value =
                cashbackOffersObj[coupon_code]["discountValue"];
              let discount_percent =
                cashbackOffersObj[coupon_code]["discountPercent"];
              let mov = cashbackOffersObj[coupon_code]["minimum_order_value"];
              let excludedProducts = cashbackOffersObj[coupon_code][
                "excluded_products"
              ]
                ? cashbackOffersObj[coupon_code]["excluded_products"]
                : {};

              let minimum_category_value =
                Number(
                  cashbackOffersObj[coupon_code]["minimum_category_value"]
                ) || 0;
              let minimum_category_unit =
                Number(
                  cashbackOffersObj[coupon_code]["minimum_category_unit"]
                ) || 0;
              let minimum_category_weight =
                Number(
                  cashbackOffersObj[coupon_code]["minimum_category_weight"]
                ) || 0;

              let minimum_buy_product_weight =
                Number(
                  cashbackOffersObj[coupon_code]["minimum_buy_product_weight"]
                ) || 0;
              let maximum_order_value = cashbackOffersObj[coupon_code][
                "maximum_order_value"
              ]
                ? Number(cashbackOffersObj[coupon_code]["maximum_order_value"])
                : null;
              let maximum_discount_amount = cashbackOffersObj[coupon_code][
                "maximum_discount_amount"
              ]
                ? Number(
                    cashbackOffersObj[coupon_code]["maximum_discount_amount"]
                  )
                : null;

              let minimum_buy_product =
                cashbackOffersObj[coupon_code]["minimum_buy_product"] || "";
              let minimum_buy_product_unit =
                Number(
                  cashbackOffersObj[coupon_code]["minimum_buy_product_unit"]
                ) || 0;
              let minimum_buy_product_value =
                Number(
                  cashbackOffersObj[coupon_code]["minimum_buy_product_value"]
                ) || 0;

              let excluded_category =
                cashbackOffersObj[coupon_code]["excluded_category"] || "";
              let multiple =
                cashbackOffersObj[coupon_code]["multiple"];

              let revisedAmtWithExcludedProductsList =
                revisedAmtForApiOffersWithExcludedProducts(
                  itemsInOrders,
                  orderNo,
                  excludedProducts,
                  category,
                  minimum_buy_product,
                  sub_category,
                  excluded_category
                );
              let revisedAmtWithExcludedProducts =
                revisedAmtWithExcludedProductsList[0].toFixed(2);
              revisedAmtWithExcludedProducts = Number(
                revisedAmtWithExcludedProducts
              );

              let productCount = revisedAmtWithExcludedProductsList[1];
              let productWeight = revisedAmtWithExcludedProductsList[2];

              let revisedOrderDisc = 0;
              if (discount_value) {
                if (multiple) {
                  if (minimum_buy_product) {
                    if (minimum_buy_product_unit > 0) {
                      discount_value *= Math.floor(
                        productCount / minimum_buy_product_unit
                      );
                    } else if (minimum_buy_product_weight > 0) {
                      discount_value *= Math.floor(
                        productWeight / minimum_buy_product_weight
                      );
                    } else {
                      discount_value *= Math.floor(
                        revisedAmtWithExcludedProducts /
                          minimum_buy_product_value
                      );
                    }
                  } else if (category) {
                    if (minimum_category_unit > 0) {
                      discount_value *= Math.floor(
                        productCount / minimum_category_unit
                      );
                    } else if (minimum_category_weight > 0) {
                      discount_value *= Math.floor(
                        productWeight / minimum_category_weight
                      );
                    } else {
                      discount_value *= Math.floor(
                        revisedAmtWithExcludedProducts / minimum_category_value
                      );
                    }
                  } else {
                    discount_value *= Math.floor(
                      revisedAmtWithExcludedProducts / mov
                    );
                  }

                  revisedOrderDisc = discount_value;
                } else {
                  if(minimum_buy_product) {
                    if (minimum_buy_product_unit > 0) {
                      if (productCount >= minimum_buy_product_unit) {
                        revisedOrderDisc = discount_value;
                      } else {
                        revisedOrderDisc = 0;
                      }
                    } else if (minimum_buy_product_weight > 0) {
                      if (productWeight >= minimum_buy_product_weight) {
                        revisedOrderDisc = discount_value;
                      } else {
                        revisedOrderDisc = 0;
                      }
                    } else {
                      if (revisedAmtWithExcludedProducts >= minimum_buy_product_value) {
                        revisedOrderDisc = discount_value;
                      } else {
                        revisedOrderDisc = 0;
                      }
                    }

                  } else if(category) {
                    if (minimum_category_unit > 0) {
                      if (productCount >= minimum_category_unit) {
                        revisedOrderDisc = discount_value;
                      } else {
                        revisedOrderDisc = 0;
                      }
                    } else if (minimum_category_weight > 0) {
                      if (productWeight >= minimum_category_weight) {
                        revisedOrderDisc = discount_value;
                      } else {
                        revisedOrderDisc = 0;
                      }
                    } else {
                      if (revisedAmtWithExcludedProducts >= minimum_category_value) {
                        revisedOrderDisc = discount_value;
                      } else {
                        revisedOrderDisc = 0;
                      }
                    }

                    
                  } else if(revisedAmtWithExcludedProducts>=mov) {
                    revisedOrderDisc = discount_value;
                  } else {
                    revisedOrderDisc = 0;
                  }


                }

                
              } else if (discount_percent) {
                if(minimum_buy_product) {
                  if (minimum_buy_product_unit > 0) {
                    if (productCount >= minimum_buy_product_unit) {
                      revisedOrderDisc =
                  (discount_percent / 100) * revisedAmtWithExcludedProducts;
                    } else {
                      revisedOrderDisc = 0;
                    }
                  } else if (minimum_buy_product_weight > 0) {
                    if (productWeight >= minimum_buy_product_weight) {
                      revisedOrderDisc =
                  (discount_percent / 100) * revisedAmtWithExcludedProducts;
                    } else {
                      revisedOrderDisc = 0;
                    }
                  } else {
                    if (revisedAmtWithExcludedProducts >= minimum_buy_product_value) {
                      revisedOrderDisc =
                  (discount_percent / 100) * revisedAmtWithExcludedProducts;
                    } else {
                      revisedOrderDisc = 0;
                    }
                  }

                } else if(category) {
                  if (minimum_category_unit > 0) {
                    if (productCount >= minimum_category_unit) {
                      revisedOrderDisc =
                  (discount_percent / 100) * revisedAmtWithExcludedProducts;
                    } else {
                      revisedOrderDisc = 0;
                    }
                  } else if (minimum_category_weight > 0) {
                    if (productWeight >= minimum_category_weight) {
                      revisedOrderDisc =
                  (discount_percent / 100) * revisedAmtWithExcludedProducts;
                    } else {
                      revisedOrderDisc = 0;
                    }
                  } else {
                    if (revisedAmtWithExcludedProducts >= minimum_category_value) {
                      revisedOrderDisc =
                  (discount_percent / 100) * revisedAmtWithExcludedProducts;
                    } else {
                      revisedOrderDisc = 0;
                    }
                  }


                } else if(revisedAmtWithExcludedProducts>=mov) {
                  revisedOrderDisc =
                  (discount_percent / 100) * revisedAmtWithExcludedProducts;
                } else {
                  revisedOrderDisc = 0;
                }
                
              }

              // if(revisedAmtWithExcludedProducts>=mov) {
              //   revisedOrderDisc = discount_value;
              // } else {
              //   revisedOrderDisc = 0;
              // }
              totalOrderDiscount += Number(revisedOrderDisc);
            }
          }
        }

        totalOrderDiscount += firstOrderDiscount;
        totalOrderDiscount = totalOrderDiscount.toFixed(2);

        if (isUpdateRequired) {
          updateOrderDiscountInFirebase(driver_id, orderNo, totalOrderDiscount);
          return;
        } else {
          return totalOrderDiscount;
        }
      } else {
        //single coupon
        if (cashbackOffersObj) {
          if (cashbackOffersObj[couponCode]) {
            let category = cashbackOffersObj[couponCode]["category"]
              ? cashbackOffersObj[couponCode]["category"]
              : "";
            let sub_category =
              cashbackOffersObj[couponCode]["sub_category"] || "";
            let discount_value = cashbackOffersObj[couponCode]["discountValue"];
            let discount_percent =
              cashbackOffersObj[couponCode]["discountPercent"];
            let mov = cashbackOffersObj[couponCode]["minimum_order_value"];
            let excludedProducts = cashbackOffersObj[couponCode][
              "excluded_products"
            ]
              ? cashbackOffersObj[couponCode]["excluded_products"]
              : {};

            let minimum_category_value =
              Number(cashbackOffersObj[couponCode]["minimum_category_value"]) ||
              0;
            let minimum_category_unit =
              Number(cashbackOffersObj[couponCode]["minimum_category_unit"]) ||
              0;
            let minimum_category_weight =
              Number(
                cashbackOffersObj[couponCode]["minimum_category_weight"]
              ) || 0;

            let minimum_buy_product_weight =
              Number(
                cashbackOffersObj[couponCode]["minimum_buy_product_weight"]
              ) || 0;

            let maximum_order_value = cashbackOffersObj[couponCode][
              "maximum_order_value"
            ]
              ? Number(cashbackOffersObj[couponCode]["maximum_order_value"])
              : null;
            let maximum_discount_amount = cashbackOffersObj[couponCode][
              "maximum_discount_amount"
            ]
              ? Number(cashbackOffersObj[couponCode]["maximum_discount_amount"])
              : null;

            let minimum_buy_product =
              cashbackOffersObj[couponCode]["minimum_buy_product"] || "";
            let minimum_buy_product_unit =
              Number(
                cashbackOffersObj[couponCode]["minimum_buy_product_unit"]
              ) || 0;
            let minimum_buy_product_value =
              Number(
                cashbackOffersObj[couponCode]["minimum_buy_product_value"]
              ) || 0;
            let excluded_category =
              cashbackOffersObj[couponCode]["excluded_category"] || "";
            let multiple =
              cashbackOffersObj[couponCode]["multiple"];

            let revisedAmtWithExcludedProductsList =
              revisedAmtForApiOffersWithExcludedProducts(
                itemsInOrders,
                orderNo,
                excludedProducts,
                category,
                minimum_buy_product,
                sub_category,
                excluded_category
              );
            let revisedAmtWithExcludedProducts =
              revisedAmtWithExcludedProductsList[0].toFixed(2);
            revisedAmtWithExcludedProducts = Number(
              revisedAmtWithExcludedProducts
            );

            let productCount = revisedAmtWithExcludedProductsList[1];
            let productWeight = revisedAmtWithExcludedProductsList[2];

            let revisedOrderDisc = 0;
            if (discount_value) {
              if (multiple) {
                if (minimum_buy_product) {
                  if (minimum_buy_product_unit > 0) {
                    discount_value *= Math.floor(
                      productCount / minimum_buy_product_unit
                    );
                  } else if (minimum_buy_product_weight > 0) {
                    discount_value *= Math.floor(
                      productWeight / minimum_buy_product_weight
                    );
                  } else {
                    discount_value *= Math.floor(
                      revisedAmtWithExcludedProducts / minimum_buy_product_value
                    );
                  }
                } else if (category) {
                  if (minimum_category_unit > 0) {
                    discount_value *= Math.floor(
                      productCount / minimum_category_unit
                    );
                  } else if (minimum_category_weight > 0) {
                    discount_value *= Math.floor(
                      productWeight / minimum_category_weight
                    );
                  } else {
                    discount_value *= Math.floor(
                      revisedAmtWithExcludedProducts / minimum_category_value
                    );
                  }
                } else {
                  discount_value *= Math.floor(
                    revisedAmtWithExcludedProducts / mov
                  );
                }

                revisedOrderDisc = discount_value;
              } else {
                if(minimum_buy_product) {
                  if (minimum_buy_product_unit > 0) {
                    if (productCount >= minimum_buy_product_unit) {
                      revisedOrderDisc = discount_value;
                    } else {
                      revisedOrderDisc = 0;
                    }
                  } else if (minimum_buy_product_weight > 0) {
                    if (productWeight >= minimum_buy_product_weight) {
                      revisedOrderDisc = discount_value;
                    } else {
                      revisedOrderDisc = 0;
                    }
                  } else {
                    if (revisedAmtWithExcludedProducts >= minimum_buy_product_value) {
                      revisedOrderDisc = discount_value;
                    } else {
                      revisedOrderDisc = 0;
                    }
                  }

                } else if(category) {
                  if (minimum_category_unit > 0) {
                    if (productCount >= minimum_category_unit) {
                      revisedOrderDisc = discount_value;
                    } else {
                      revisedOrderDisc = 0;
                    }
                  } else if (minimum_category_weight > 0) {
                    if (productWeight >= minimum_category_weight) {
                      revisedOrderDisc = discount_value;
                    } else {
                      revisedOrderDisc = 0;
                    }
                  } else {
                    if (revisedAmtWithExcludedProducts >= minimum_category_value) {
                      revisedOrderDisc = discount_value;
                    } else {
                      revisedOrderDisc = 0;
                    }
                  }

                  
                } else if(revisedAmtWithExcludedProducts>=mov) {
                  revisedOrderDisc = discount_value;
                } else {
                  revisedOrderDisc = 0;
                }


              }


              
            } else if (discount_percent) {
              if(minimum_buy_product) {
                if (minimum_buy_product_unit > 0) {
                  if (productCount >= minimum_buy_product_unit) {
                    revisedOrderDisc =
                (discount_percent / 100) * revisedAmtWithExcludedProducts;
                  } else {
                    revisedOrderDisc = 0;
                  }
                } else if (minimum_buy_product_weight > 0) {
                  if (productWeight >= minimum_buy_product_weight) {
                    revisedOrderDisc =
                (discount_percent / 100) * revisedAmtWithExcludedProducts;
                  } else {
                    revisedOrderDisc = 0;
                  }
                } else {
                  if (revisedAmtWithExcludedProducts >= minimum_buy_product_value) {
                    revisedOrderDisc =
                (discount_percent / 100) * revisedAmtWithExcludedProducts;
                  } else {
                    revisedOrderDisc = 0;
                  }
                }

              } else if(category) {
                if (minimum_category_unit > 0) {
                  if (productCount >= minimum_category_unit) {
                    revisedOrderDisc =
                (discount_percent / 100) * revisedAmtWithExcludedProducts;
                  } else {
                    revisedOrderDisc = 0;
                  }
                } else if (minimum_category_weight > 0) {
                  if (productWeight >= minimum_category_weight) {
                    revisedOrderDisc =
                (discount_percent / 100) * revisedAmtWithExcludedProducts;
                  } else {
                    revisedOrderDisc = 0;
                  }
                } else {
                  if (revisedAmtWithExcludedProducts >= minimum_category_value) {
                    revisedOrderDisc =
                (discount_percent / 100) * revisedAmtWithExcludedProducts;
                  } else {
                    revisedOrderDisc = 0;
                  }
                }

                
              } else if(revisedAmtWithExcludedProducts>=mov) {
                revisedOrderDisc =
                (discount_percent / 100) * revisedAmtWithExcludedProducts;
              } else {
                revisedOrderDisc = 0;
              }
              
            }

            revisedOrderDisc += firstOrderDiscount;
            revisedOrderDisc = revisedOrderDisc.toFixed(2);
            if (isUpdateRequired) {
              updateOrderDiscountInFirebase(
                driver_id,
                orderNo,
                revisedOrderDisc
              );
              return;
            } else {
              return revisedOrderDisc;
            }
          } else {
            let revisedOrderDisc = firstOrderDiscount;
            revisedOrderDisc = revisedOrderDisc.toFixed(2);
            if (isUpdateRequired) {
              updateOrderDiscountInFirebase(
                driver_id,
                orderNo,
                revisedOrderDisc
              );
              return;
            } else {
              return revisedOrderDisc;
            }
          }
        }
      }
    } else {
      if (isUpdateRequired) {
        return;
      } else {
        return 0;
      }
    }
  };

  const updateOrderDiscountInFirebase = (
    driver_id,
    orderNo,
    revisedOrderDisc
  ) => {
    let temp_order_history = getOrderHistory;
    let updateToAll = {};
    updateToAll["revised_order_disc"] = "" + revisedOrderDisc;

    for (let key in getOrdersPerShop[
      orderNo.split("-")[0] + orderNo.split("-")[1]
    ]) {
      let modified_url =
        getDB +
        "/" +
        driver_id +
        "/" +
        orderNo.split("-")[0] +
        "-" +
        orderNo.split("-")[1] +
        "-" +
        getOrdersPerShop[orderNo.split("-")[0] + orderNo.split("-")[1]][key];
      fire.database().ref(modified_url).update(updateToAll);
      let orderKey = `${orderNo.split("-")[0]}-${orderNo.split("-")[1]}-${
        getOrdersPerShop[orderNo.split("-")[0] + orderNo.split("-")[1]][key]
      }`;
      temp_order_history[driver_id][orderKey].revised_order_disc =
        updateToAll.revised_order_disc;
    }

    setOrderHistory(temp_order_history);
    generateItemSummary(driver_id);
    RenderOrdersDetails(driver_id, orderNo);
  };

  const updateRevisedOrderAmtInFirebase = (
    driver_id,
    orderNo,
    revisedOrderAmt
  ) => {
    let temp_order_history = getOrderHistory;
    let updateToAll = {};
    updateToAll["revised_order_amt"] = "" + revisedOrderAmt;

    let deliveryErrorsObj = {};
    deliveryErrorsObj["old_revised_order_amt"] =
      "" + getOrderHistory[driver_id][orderNo]["revised_order_amt"];
    deliveryErrorsObj["new_revised_order_amt"] = "" + revisedOrderAmt;
    deliveryErrorsObj["updated_by"] = usermodifying;
    deliveryErrorsObj["updated_at"] = UpdatedDate();

    let deliveryErrorParentKey = getDB;
    deliveryErrorParentKey = deliveryErrorParentKey.replace(
      "Driver_Orders_History",
      "Delivery_Errors"
    );

    for (let key in getOrdersPerShop[
      orderNo.split("-")[0] + orderNo.split("-")[1]
    ]) {
      let modified_url =
        getDB +
        "/" +
        driver_id +
        "/" +
        orderNo.split("-")[0] +
        "-" +
        orderNo.split("-")[1] +
        "-" +
        getOrdersPerShop[orderNo.split("-")[0] + orderNo.split("-")[1]][key];
      fire.database().ref(modified_url).update(updateToAll);
      let orderKey = `${orderNo.split("-")[0]}-${orderNo.split("-")[1]}-${
        getOrdersPerShop[orderNo.split("-")[0] + orderNo.split("-")[1]][key]
      }`;
      temp_order_history[driver_id][orderKey].revised_order_amt =
        updateToAll.revised_order_amt;

      let error_url =
        deliveryErrorParentKey +
        "/" +
        driver_id +
        "/" +
        orderNo.split("-")[0] +
        "-" +
        orderNo.split("-")[1] +
        "-" +
        getOrdersPerShop[orderNo.split("-")[0] + orderNo.split("-")[1]][key];
      fire.database().ref(error_url).update(deliveryErrorsObj);
    }

    setOrderHistory(temp_order_history);
    generateItemSummary(driver_id);
    RenderOrdersDetails(driver_id, orderNo);
  };

  const updateRevisedOrderDiscountInFirebase = (
    driver_id,
    orderNo,
    revisedOrderDisc
  ) => {
    let temp_order_history = getOrderHistory;
    let updateToAll = {};
    updateToAll["revised_order_disc"] = "" + revisedOrderDisc;

    let deliveryErrorsObj = {};
    deliveryErrorsObj["old_revised_order_disc"] =
      "" + getOrderHistory[driver_id][orderNo]["revised_order_disc"];
    deliveryErrorsObj["new_revised_order_disc"] = "" + revisedOrderDisc;
    deliveryErrorsObj["updated_by"] = usermodifying;
    deliveryErrorsObj["updated_at"] = UpdatedDate();

    let deliveryErrorParentKey = getDB;
    deliveryErrorParentKey = deliveryErrorParentKey.replace(
      "Driver_Orders_History",
      "Delivery_Errors"
    );

    for (let key in getOrdersPerShop[
      orderNo.split("-")[0] + orderNo.split("-")[1]
    ]) {
      let modified_url =
        getDB +
        "/" +
        driver_id +
        "/" +
        orderNo.split("-")[0] +
        "-" +
        orderNo.split("-")[1] +
        "-" +
        getOrdersPerShop[orderNo.split("-")[0] + orderNo.split("-")[1]][key];
      fire.database().ref(modified_url).update(updateToAll);
      let orderKey = `${orderNo.split("-")[0]}-${orderNo.split("-")[1]}-${
        getOrdersPerShop[orderNo.split("-")[0] + orderNo.split("-")[1]][key]
      }`;
      temp_order_history[driver_id][orderKey].revised_order_disc =
        updateToAll.revised_order_disc;

      let error_url =
        deliveryErrorParentKey +
        "/" +
        driver_id +
        "/" +
        orderNo.split("-")[0] +
        "-" +
        orderNo.split("-")[1] +
        "-" +
        getOrdersPerShop[orderNo.split("-")[0] + orderNo.split("-")[1]][key];
      fire.database().ref(error_url).update(deliveryErrorsObj);
    }

    setOrderHistory(temp_order_history);
    generateItemSummary(driver_id);
    RenderOrdersDetails(driver_id, orderNo);
  };

  const RenderOrdersDetails = async (driver_id, order_id) => {
    let label_map = {
      order_number: "Order Number",
      order_date: "Order Date",
      total_amt: "Total Amount",
      revised_order_disc: "Discount",
      cashback_redeemed: "Wallet",
      coupon_code: "Coupon Code",
      revised_amt: "Revised Amount",
      status: "Status",
      shop_name: "Shop Name",
      address1: "Address1",
      address2: "Address2",
      area: "Area",
      phone: "Phone",
      retailer_id: "Retailer ID",
      latitude: "Latitude",
      longitude: "Longitude",
      cash_amount: "Cash Amount",
      adjustment_amount: "Adjustment Amount",
      adjustment_reason: "Adjustment Reason",
      adjustment_order_number: "Adjustment OrderNumber",
      pending_amount: "Pending Amount",
      pending_reason: "Pending Reason",
      superkredit_amount: "SuperKredit Amount",
      payment_mode: "Payment Mode",
      paid_amount: "Paid Amount",
      total_amount_paid: "Total Amount Paid",
      reason: "Reason",
      card_amount: "Card Amount",
      card_transaction_id: "Card Transaction ID",
      card_image_url: "Card Image URL",
      cheque1_amount: "Cheque1 Amount",
      cheque1_number: "Cheque1 Number",
      cheque1_image_url: "Cheque1 Image URL",
      cheque2_image_url: "Cheque2 Image URL",
      paytm_image_url: "Paytm Image URL",
      paytm_image: "Paytm Image URL",
      razorpay_image_url: "Razorpay Image URL",
      razor_image: "Razorpay Image URL",
      cheque1_date: "Cheque1 Date",
      cheque2_amount: "Cheque2 Amount",
      cheque2_number: "Cheque2 Number",
      cheque2_date: "Cheque2 Date",
      cheque_image: "Cheque Image URL",
      returned_from: "Returned From",
      card2_amount: "Card2 Amount",
      card2_transaction_id: "Card2 Transaction ID",
      card2_image_url: "Card2 Image URL",
      bag_id: "Bag ID",
    };

    let temp = [];
    let temp2 = [];
    let static_order_render = [];
    let temp_header =
      getOrderHistory[driver_id][order_id].status &&
      (getOrderHistory[driver_id][order_id].status
        .toLowerCase()
        .indexOf("partial") != -1 ||
        getOrderHistory[driver_id][order_id].status
          .toLowerCase()
          .indexOf("shipped-modified") != -1 ||
        getOrderHistory[driver_id][order_id].status.toLowerCase() ===
          "shipped") ? (
        <FontAwesomeIcon icon={faTimes} />
      ) : null;
    let static_order_render_table_key = [
      "item_id",
      "item_desc",
      "order_qty",
      "mrp",
      temp_header,
    ];
    let static_order_render_table_header = [
      "Item ID",
      "Description",
      "Quantity",
      "Value",
      temp_header,
    ];
    let static_order_render_table = [];
    let static_order_render_table_body = [];
    let static_shop_render = [];
    let static_shop_keys = [
      "shop_name",
      "phone",
      "retailer_id",
      "retailer_name",
      "gst_number",
      "fssai_document_number",
    ];
    let static_customer_keys = [
      "retailer_name",
      "shop_name",
      "phone",
      "customer_id",
      "gst_number",
      "fssai_document_number",
    ];
    let static_address_render = [];
    let static_address_keys = ["address1", "address2", "area"];

    setNewDriverId(driver_id);
    setNewOrderId(order_id);

    //do unique inserts here
    setRevisedAmtPresent(false);
    setCardAmount(null);
    sessionStorage.removeItem("AmtDiff");
    let revisedOrderrDiscount = getOrderHistory[driver_id][order_id]
      .revised_order_disc
      ? getOrderHistory[driver_id][order_id].revised_order_disc
      : "";
    sessionStorage.setItem("revisedOrderDisc", revisedOrderrDiscount);
    setDBExtend(driver_id + "/" + order_id);
    const paymentExist = Object.keys(
      getOrderHistory[driver_id][order_id]
    ).includes("payment_details");

    //check for status on bulk updation
    if (
      (getOrderHistory[driver_id][order_id].status.toLowerCase() ===
        "processed" ||
        getOrderHistory[driver_id][order_id].status
          .toLowerCase()
          .includes("shipped") ||
        getOrderHistory[driver_id][order_id].status
          .toLowerCase()
          .includes("hold")) &&
      (validateIsAdminUser(
        JSON.parse(sessionStorage.getItem(`user`))[0].email
      ) ||
        !validateIsAdminUser(
          JSON.parse(sessionStorage.getItem(`user`))[0].email
        ))
    ) {
      setBulkUpdationConfirm(true);
    } else {
      setBulkUpdationConfirm(false);
    }

    let curr_order_payment_mode =
      getOrderHistory[driver_id][order_id].order_payment_mode;
    let curr_status = getOrderHistory[driver_id][order_id].status;
    let curr_credit_amt = getOrderHistory[driver_id][order_id].credit_amt;

    console.log(getOrderHistory[driver_id][order_id]["payment_details"]);

    let srevisedOrderAmt = getOrderHistory[driver_id][order_id]
      .revised_order_amt
      ? Number(getOrderHistory[driver_id][order_id].revised_order_amt)
      : Number(getOrderHistory[driver_id][order_id].total_amt);
    let sorderDisc = getOrderHistory[driver_id][order_id].order_disc
      ? Number(getOrderHistory[driver_id][order_id].order_disc)
      : 0;
    let srevisedOrderDisc = getOrderHistory[driver_id][order_id]
      .revised_order_disc
      ? Number(getOrderHistory[driver_id][order_id].revised_order_disc)
      : sorderDisc;
    let srevisedOrderAmtWithDisc = (
      srevisedOrderAmt +
      sorderDisc -
      srevisedOrderDisc
    ).toFixed(2);

    let retailerID = getOrderHistory[driver_id][order_id].retailer_id
      ? getOrderHistory[driver_id][order_id].retailer_id
      : "";

    if (curr_status == "Delivered") {
      curr_credit_amt = getOrderHistory[driver_id][order_id].credit_amt;
    } else if (curr_status == "Partially-Delivered") {
      curr_credit_amt = getOrderHistory[driver_id][order_id].revised_order_amt
        ? srevisedOrderAmtWithDisc
        : getOrderHistory[driver_id][order_id].revised_order_amt;
    }

    const temp_status =
      (getOrderHistory[driver_id][order_id].status &&
        (getOrderHistory[driver_id][order_id].status
          .toLowerCase()
          .indexOf("delivered") != -1 ||
          getOrderHistory[driver_id][order_id].status
            .toLowerCase()
            .indexOf("partial"))) != -1;
    if (paymentExist && temp_status) {
      if (!("0" in getOrderHistory[driver_id][order_id]["payment_details"])) {
        Object.keys(
          getOrderHistory[driver_id][order_id]["payment_details"]
        ).forEach((payment_item) => {
          if (
            getOrderHistory[driver_id][order_id]["payment_details"][
              payment_item
            ].length == 0
          )
            return;

          if (payment_item.toLowerCase().indexOf("total_amount_paid") != -1)
            return;

          if (payment_item.toLowerCase().indexOf("payment_mode") != -1) {
            temp2.push(
              <Form.Group>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    {label_map[payment_item]}
                  </InputGroupAddon>
                  <Input
                    value={
                      getOrderHistory[driver_id][order_id]["payment_details"][
                        payment_item
                      ]
                    }
                    readOnly
                  />
                </InputGroup>
              </Form.Group>
            );

            return;
          }

          temp2.push(
            <Form.Group>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  {label_map[payment_item]}
                </InputGroupAddon>
                <Input
                  value={
                    "₹ " +
                    getOrderHistory[driver_id][order_id]["payment_details"][
                      payment_item
                    ]
                  }
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          );
        });

        temp2.push(
          <Form>
            <br></br>
            <hr></hr>

            {getOrderHistory[driver_id][order_id].status.toLowerCase() ===
            "partially-delivered" ? (
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <Button style={{ zIndex: -10 }}>Revised Amount</Button>
                </InputGroupAddon>
                <Input value={"₹ " + srevisedOrderAmtWithDisc} readOnly />
              </InputGroup>
            ) : (
              ""
            )}

            <br></br>

            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <Button style={{ zIndex: -10 }}>Order Amount</Button>
              </InputGroupAddon>
              <Input
                value={"₹ " + getOrderHistory[driver_id][order_id].total_amt}
                readOnly
              />
            </InputGroup>

            <br></br>

            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <Button style={{ zIndex: -10 }}>Total Amount Paid</Button>
              </InputGroupAddon>
              <Input
                value={
                  "₹ " +
                  getOrderHistory[driver_id][order_id]["payment_details"][
                    "total_amount_paid"
                  ]
                }
                readOnly
              />
              {(validateIsAdminUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              ) ||
                (!deliveryEndFlagMap[driver_id] &&
                  !routeOrdersConsolidationFlag[driver_id] &&
                  (!validateIsOperationUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) ||
                    (validateIsOperationUser(
                      JSON.parse(sessionStorage.getItem(`user`))[0].email
                    ))))) &&
              driver_id != "9999" &&
              driver_id != "9998" &&
              finalUserEditAccess ? (
                <InputGroupAddon addonType="append">
                  <Button
                    color="secondary"
                    onClick={() => {
                      setModalEdit(true);
                      setOrderPaymentMode(curr_order_payment_mode);
                      setTotalSuperKreditAmt(curr_credit_amt);
                      setModalData([driver_id, order_id]);
                    }}
                  >
                    Edit
                  </Button>
                </InputGroupAddon>
              ) : (
                ""
              )}
            </InputGroup>

            <br></br>

            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <Button style={{ zIndex: -10 }}>Amount Difference</Button>
              </InputGroupAddon>
              <Input
                value={
                  "₹ " +
                  (
                    Number(srevisedOrderAmtWithDisc) -
                    Number(
                      getOrderHistory[driver_id][order_id]["payment_details"][
                        "total_amount_paid"
                      ]
                    )
                  ).toFixed(0)
                }
                readOnly
              />
            </InputGroup>
          </Form>
        );
        //temp2.push(<Button variant="outline-primary" style={{float:'right'}} onClick={()=>{setModalData([driver_id,order_id]);setModalEdit(true)}}>Edit</Button>)
      } else {
        //show in list accordion format

        let nodes = Object.keys(
          getOrderHistory[driver_id][order_id]["payment_details"]
        );

        nodes.splice(nodes.indexOf("total_amount_paid"), 1);

        nodes.forEach((key) => {
          let ctemp_2 = [];
          let finalPaymentDetails = Object.keys(
            getOrderHistory[driver_id][order_id]["payment_details"][key]
          );

          Object.keys(
            getOrderHistory[driver_id][order_id]["payment_details"][key]
          ).forEach((key2) => {
            console.log("payment key:");
            console.log(key2);
            if (key2.toLowerCase().indexOf("total") != -1) return;

            console.log(key2.toLowerCase().indexOf("payment_mode") != -1);

            if (key2.toLowerCase().indexOf("payment_mode") != -1) {
              ctemp_2.push(
                <Form.Group>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      {label_map[key2]}
                    </InputGroupAddon>
                    <Input
                      value={
                        getOrderHistory[driver_id][order_id]["payment_details"][
                          key
                        ][key2]
                      }
                      readOnly
                    />
                  </InputGroup>
                </Form.Group>
              );

              return;
            }
            if (
              (key2.toLowerCase().includes("image") ||
                key2.toLowerCase().includes("cheque")) &&
              !key2.toLowerCase().includes("amount")
            ) {
              ctemp_2.push(
                <Form.Group>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      {label_map[key2]}
                    </InputGroupAddon>
                    <a
                      target="_blank"
                      href={
                        getOrderHistory[driver_id][order_id]["payment_details"][
                          key
                        ][key2]
                      }
                      style={{ width: "60vw" }}
                    >
                      <Input
                        value={
                          getOrderHistory[driver_id][order_id][
                            "payment_details"
                          ][key][key2]
                        }
                        readOnly
                      />
                    </a>
                  </InputGroup>
                </Form.Group>
              );
            } else if (!key2.toLowerCase().includes("amount")) {
              ctemp_2.push(
                <Form.Group>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      {label_map[key2]}
                    </InputGroupAddon>
                    <Input
                      value={
                        getOrderHistory[driver_id][order_id]["payment_details"][
                          key
                        ][key2]
                      }
                      readOnly
                    />
                  </InputGroup>
                </Form.Group>
              );
            } else if (
              key2.toLowerCase().includes("amount") &&
              key2.toLowerCase().includes("cheque")
            ) {
              ctemp_2.push(
                <Form.Group>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      {label_map[key2]}
                    </InputGroupAddon>
                    <Input
                      value={
                        "₹ " +
                        getOrderHistory[driver_id][order_id]["payment_details"][
                          key
                        ][key2]
                      }
                      readOnly
                    />
                  </InputGroup>
                </Form.Group>
              );
            } else {
              ctemp_2.push(
                <Form.Group>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      Amount
                    </InputGroupAddon>
                    <Input
                      value={
                        "₹ " +
                        getOrderHistory[driver_id][order_id]["payment_details"][
                          key
                        ][key2]
                      }
                      readOnly
                    />
                  </InputGroup>
                </Form.Group>
              );
            }
          });

          temp2.push(
            <div>
              <Accordion>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey={key}>
                    Payment {Number(key) + 1}
                  </Accordion.Toggle>
                </Card.Header>

                <Accordion.Collapse eventKey={key}>
                  <Card.Body>{ctemp_2}</Card.Body>
                </Accordion.Collapse>
                
                {getOrderHistory[driver_id] &&
                  getOrderHistory[driver_id][order_id] &&
                  // getOrderHistory[driver_id][order_id].store_series === 'O' &&
                  getOrderHistory[driver_id][order_id].proof_of_delivery && (
                    <>
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey={`proof_${key}`}>
                          Proof of Delivery
                        </Accordion.Toggle>
                      </Card.Header>

                      <Accordion.Collapse eventKey={`proof_${key}`}>
                        <Card.Body>
                          <a href={getOrderHistory[driver_id][order_id].proof_of_delivery} target="_blank" rel="noopener noreferrer">
                            <img 
                              src={getOrderHistory[driver_id][order_id].proof_of_delivery} 
                              alt="Proof of Delivery" 
                              style={{ maxWidth: "100%", height: "180px", cursor: "pointer" }} 
                            />
                          </a>
                        </Card.Body>
                      </Accordion.Collapse>
                    </>
                  )}
              </Accordion>
            </div>
          );
          
        });

        temp2.push(
          <Form>
            <br></br>
            <hr></hr>
            {!(
              getOrderHistory[driver_id][order_id].status.toLowerCase() ===
              "delivered"
            ) ? (
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <Button style={{ zIndex: -10 }}>Revised Amount</Button>
                </InputGroupAddon>
                <Input value={"₹ " + srevisedOrderAmtWithDisc} readOnly />
              </InputGroup>
            ) : (
              ""
            )}

            <br></br>

            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <Button style={{ zIndex: -10 }}>Order Amount</Button>
              </InputGroupAddon>
              <Input
                value={"₹ " + getOrderHistory[driver_id][order_id].total_amt}
                readOnly
              />
            </InputGroup>

            <br></br>

            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <Button style={{ zIndex: -10 }}>Total Amount Paid</Button>
              </InputGroupAddon>
              <Input
                value={
                  "₹ " +
                  getOrderHistory[driver_id][order_id]["payment_details"][
                    "total_amount_paid"
                  ]
                }
                readOnly
              />
              {(validateIsAdminUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              ) ||
                (!deliveryEndFlagMap[driver_id] &&
                  !routeOrdersConsolidationFlag[driver_id] &&
                  (!validateIsOperationUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) ||
                    (validateIsOperationUser(
                      JSON.parse(sessionStorage.getItem(`user`))[0].email
                    ) )))) &&
              driver_id != "9999" &&
              driver_id != "9998" &&
              finalUserEditAccess ? (
                <InputGroupAddon addonType="append">
                  <Button
                    color="secondary"
                    onClick={() => {
                      setModalEdit(true);
                      setOrderPaymentMode(curr_order_payment_mode);
                      setTotalSuperKreditAmt(curr_credit_amt);
                      setModalData([driver_id, order_id]);
                    }}
                  >
                    Edit
                  </Button>
                </InputGroupAddon>
              ) : (
                ""
              )}
            </InputGroup>

            <br></br>

            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <Button style={{ zIndex: -10 }}>Amount Difference</Button>
              </InputGroupAddon>
              <Input
                value={
                  "₹ " +
                  (
                    srevisedOrderAmtWithDisc -
                    parseFloat(
                      getOrderHistory[driver_id][order_id]["payment_details"][
                        "total_amount_paid"
                      ]
                    )
                  ).toFixed(2)
                }
                readOnly
              />
            </InputGroup>
          </Form>
        );
      
      }
      // return (
      //   <>
      //     {temp2}
    
      //     {/* Global Modal to display full-size image */}
      //     <Modal show={showModal} onHide={handleClose} centered>
      //       <Modal.Body>
      //         <img src={modalImage} alt="Expanded Proof of Delivery" style={{ width: "100%" }} />
      //       </Modal.Body>
      //     </Modal>
      //   </>
      // );
    }

    const exact_order_id =
      order_id.split("-")[0] + "-" + order_id.split("-")[1]; //this hold the base order_id
    let reason = null;
    if (getOrderHistory[driver_id][order_id].reason)
      reason = getOrderHistory[driver_id][order_id].reason;

    let static_orders = {
      order_number: getOrderHistory[driver_id][order_id].order_number,
      order_date: getOrderHistory[driver_id][order_id].order_date,
      total_amt: getOrderHistory[driver_id][order_id].total_amt,
      revised_order_disc: getOrderHistory[driver_id][order_id]
        .revised_order_disc
        ? getOrderHistory[driver_id][order_id].revised_order_disc
        : getOrderHistory[driver_id][order_id].order_disc,
      cashback_redeemed: getOrderHistory[driver_id][order_id].cashback_redeemed,
      paid_amount:
        "payment_details" in getOrderHistory[driver_id][order_id]
          ? getOrderHistory[driver_id][order_id]["payment_details"][
              "total_amount_paid"
            ]
          : 0,
      status: getOrderHistory[driver_id][order_id].status,
      reason: reason,
    };
    let order_details = [];
    let temp_dict = [];
    let isAllOrdersDispatched = true;
    let bagStr = "";
    let bagSet = new Set();

    Object.keys(getOrderHistory[driver_id]).forEach((oid) => {
      var temp_item_status;
      if (oid.indexOf(exact_order_id) != -1) {
        order_details.push({
          item_id: getOrderHistory[driver_id][oid].item_id,
          item_disc: getOrderHistory[driver_id][oid].item_disc,
          order_qty: getOrderHistory[driver_id][oid].order_qty,
          mrp: getOrderHistory[driver_id][oid].mrp,
          item_status: getOrderHistory[driver_id][oid].item_status,
          item_delivered_qty:
            getOrderHistory[driver_id][oid].item_delivered_qty,
          item_reason: getOrderHistory[driver_id][oid].item_reason,
          order_id: oid,
          item_modified_qty: getOrderHistory[driver_id][oid].item_modified_qty,
          ordered_item_id: getOrderHistory[driver_id][oid].ordered_item_id,
          ordered_item_disc: getOrderHistory[driver_id][oid].ordered_item_disc,
          ordered_item_qty: getOrderHistory[driver_id][oid].ordered_item_qty,
          itemModifiedUpdatedBy:
            getOrderHistory[driver_id][oid].itemModifiedUpdatedBy,
        });
        static_orders.returned_from =
          getOrderHistory[driver_id][oid].returned_from;
        static_orders.total_amt = getOrderHistory[driver_id][oid].total_amt;
        static_orders.order_payment_mode =
          getOrderHistory[driver_id][oid].order_payment_mode;
        static_orders.coupon_code = getOrderHistory[driver_id][oid].coupon_code;

        let bagId = getOrderHistory[driver_id][oid].bag_id || "";

        if (bagId && !bagSet.has(bagId)) {
          bagStr += `${bagId},`;
          bagSet.add(bagId);
        }

        let revisedOrderAmt = getOrderHistory[driver_id][oid].revised_order_amt
          ? Number(getOrderHistory[driver_id][oid].revised_order_amt)
          : Number(getOrderHistory[driver_id][oid].total_amt);
        let orderDisc = getOrderHistory[driver_id][oid].order_disc
          ? Number(getOrderHistory[driver_id][oid].order_disc)
          : 0;
        let revisedOrderDisc = getOrderHistory[driver_id][oid]
          .revised_order_disc
          ? Number(getOrderHistory[driver_id][oid].revised_order_disc)
          : orderDisc;
        let revisedOrderAmtWithDisc = (
          revisedOrderAmt +
          orderDisc -
          revisedOrderDisc
        ).toFixed(2);

        if (static_orders.order_payment_mode == "Credit") {
          static_orders.revised_order_amt =
            getOrderHistory[driver_id][oid].revised_order_amt;
          static_orders.credit_amt = getOrderHistory[driver_id][oid].credit_amt;
          static_orders.revised_amt = getOrderHistory[driver_id][oid]
            .revised_order_amt
            ? revisedOrderAmtWithDisc
            : getOrderHistory[driver_id][oid].revised_order_amt;
        }

        if (
          getOrderHistory[driver_id][oid].status == "Shipped-Modified" ||
          getOrderHistory[driver_id][oid].status == "Partially-Delivered"
        ) {
          static_orders.revised_amt = getOrderHistory[driver_id][oid]
            .revised_order_amt
            ? revisedOrderAmtWithDisc
            : getOrderHistory[driver_id][oid].revised_order_amt; //  ---NEW
        }
      }

      let status = getOrderHistory[driver_id][oid].status;
      if (status != "Hold" && !status.includes("Shipped")) {
        isAllOrdersDispatched = false;
      }
    });

    if (bagStr) {
      bagStr = bagStr.slice(0, -1);
      static_orders.bag_id = bagStr;
    }

    setDispatchedVehicleFlag(isAllOrdersDispatched);

    Object.keys(static_orders).forEach((value) => {
      console.log(value);

      if (value == "status") {
        static_order_render.push(
          <Form.Group>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                {label_map[value]}
              </InputGroupAddon>
              <Form.Control type="input" value={static_orders[value]} />

              {((!deliveryEndFlagMap[driver_id] &&
                !routeOrdersConsolidationFlag[driver_id] &&
                  (validateIsExportUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) &&
                    (driver_id == "8888" || driver_id == "8701")) &&
                !validateIsOperationUser(
                  JSON.parse(sessionStorage.getItem(`user`))[0].email
                )) ||
                validateIsAdminUser(
                  JSON.parse(sessionStorage.getItem(`user`))[0].email
                )) &&
              driver_id != "9999" &&
              driver_id != "9998" &&
              finalUserEditAccess  ? (
                <DropdownButton
                  as={InputGroup.Append}
                  variant="outline-primary"
                  title="Edit"
                  id="input-group-dropdown-2"
                >
                  {
                    (
                    <>
                      {(static_orders[value].includes("Shipped") || static_orders[value].includes("Delivered") || validateIsAdminUser(JSON.parse(sessionStorage.getItem(`user`))[0].email)) && (
                        <Dropdown.Item
                          href="#"
                          name="Delivered"
                          onClick={(e) => {
                            e.preventDefault();
                            setModalData([driver_id, order_id]);
                            sessionStorage.setItem(`dateModified`, getDate);
                            if (!static_orders["order_number"].includes("R")) {
                              setOrderPaymentMode(
                                static_orders.order_payment_mode
                              );
                              setTotalSuperKreditAmt(static_orders.credit_amt);
                              setModalWithView(true);
                            } else {
                              fireBaseUpdateReturnDeliveredOrders(
                                driver_id,
                                order_id,
                                "Delivered"
                              );
                            }

                            //firebaseUpdate(driver_id,order_id,e.target.name)
                          }}
                        >
                          Delivered
                        </Dropdown.Item>
                      )}
                      {(static_orders[value].includes("Shipped") || static_orders[value].includes("Partial") || validateIsAdminUser(JSON.parse(sessionStorage.getItem(`user`))[0].email)) &&  (
                        <Dropdown.Item
                          href="#"
                          name="Partial"
                          onClick={(e) => {
                            e.preventDefault();
                            sessionStorage.setItem(`dateModified`, getDate);
                            setModalData([driver_id, order_id]);
                            if (!static_orders["order_number"].includes("R")) {
                              setOrderPaymentMode(
                                static_orders.order_payment_mode
                              );
                              setTotalSuperKreditAmt(static_orders.revised_amt);
                              setModalPartial(true);
                            } else {
                              fireBaseUpdateReturnDeliveredOrders(
                                driver_id,
                                order_id,
                                "Partially-Delivered"
                              );
                            }
                          }}
                        >
                          Partially Delivered
                        </Dropdown.Item>
                      )}
                      {(static_orders[value].includes("Shipped") || validateIsAdminUser(JSON.parse(sessionStorage.getItem(`user`))[0].email)) && (
                        <Dropdown.Item
                          href="#"
                          name="Cancel Order"
                          onClick={(e) => {
                            e.preventDefault();
                            setModalData([driver_id, order_id]);
                            setModalCancelled(true);
                          }}
                        >
                          Cancelled
                        </Dropdown.Item>
                      )}
                      {(static_orders[value].includes("Shipped") || validateIsAdminUser(JSON.parse(sessionStorage.getItem(`user`))[0].email)) && (
                        <Dropdown.Item
                          href="#"
                          name="Pending"
                          onClick={(e) => {
                            e.preventDefault();
                            setModalData([driver_id, order_id]);
                            setModalPending(true);
                          }}
                        >
                          Pending
                        </Dropdown.Item>
                      )}
                    </>
                  )}
                   {(validateIsAdminUser( 
                        JSON.parse(sessionStorage.getItem(`user`))[0].email
                      )) && (
                        <>
                      {/* <Dropdown.Item
                        href="#"
                        name="Hold"
                        onClick={(e) => {
                          firebaseOrderStatusUpdate(
                            driver_id,
                            order_id,
                            "Hold"
                          );
                        }}
                      >
                        Hold
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        href="#"
                        name="Shipped"
                        onClick={(e) => {
                          e.preventDefault();
                          firebaseOrderStatusUpdate(
                            driver_id,
                            order_id,
                            "Shipped"
                          );
                        }}
                      >
                        Shipped
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        name="Shipped-Modified"
                        onClick={(e) => {
                          e.preventDefault();
                          firebaseOrderStatusUpdate(
                            driver_id,
                            order_id,
                            "Shipped-Modified"
                          );
                        }}
                      >
                        Shipped Modified
                      </Dropdown.Item>
                    </>
                    )}
                </DropdownButton>
              ) : (
                ""
              )}
            </InputGroup>
          </Form.Group>
        );
        return;
      }

      if (value == "returned_from" && driver_id == "9999") {
        static_order_render.push(
          <div>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                {label_map[value]}
              </InputGroupAddon>
              <Input
                value={
                  static_orders["returned_from"]
                    ? static_orders["returned_from"]
                    : ""
                }
                readOnly
              />
            </InputGroup>
            <br />
          </div>
        );
        return;
      }

      if (value == "revised_order_disc") {
        if (static_orders["revised_order_disc"] != 0.0) {
          static_order_render.push(
            <div>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  {label_map[value]}
                </InputGroupAddon>
                <Input
                  value={
                    static_orders["revised_order_disc"]
                      ? static_orders["revised_order_disc"]
                      : ""
                  }
                  readOnly
                />
              </InputGroup>
              <br />
            </div>
          );
          return;
        } else {
          return;
        }
      }

      if (value == "cashback_redeemed") {
        if (static_orders["cashback_redeemed"] != 0.0) {
          static_order_render.push(
            <div>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  {label_map[value]}
                </InputGroupAddon>
                <Input
                  value={
                    static_orders["cashback_redeemed"]
                      ? static_orders["cashback_redeemed"]
                      : ""
                  }
                  readOnly
                />
              </InputGroup>
              <br />
            </div>
          );
          return;
        } else {
          return;
        }
      }

      if (
        value == "reason" &&
        static_orders.status &&
        static_orders.status.toLowerCase().indexOf("pending") == -1 &&
        static_orders.status.toLowerCase().indexOf("partial") == -1 &&
        static_orders.status.toLowerCase().indexOf("cancelled") == -1
      )
        return;

      if (value == "total_amt") {
        let totalAmountText =
          static_orders["revised_amt"] + " (" + static_orders[value] + ")";

        static_order_render.push(
          <div>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                {label_map[value]}
              </InputGroupAddon>
              <Input
                value={
                  static_orders["revised_amt"]
                    ? "" + totalAmountText
                    : static_orders[value]
                }
                readOnly
              />
            </InputGroup>
            <br />
          </div>
        );
        return;
      }

      if (
        value == "paid_amount" &&
        static_orders.status.includes("Delivered")
      ) {
        static_order_render.push(
          <div>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                {label_map[value]}
              </InputGroupAddon>
              <Input value={static_orders[value]} readOnly />
            </InputGroup>
            <br />
          </div>
        );
        return;
      }

      if (value == "bag_id") {
        static_order_render.push(
          <div>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                {label_map[value]}
              </InputGroupAddon>
              <Input value={static_orders[value]} readOnly />
            </InputGroup>
            <br />
          </div>
        );
        return;
      }

      if (
        static_orders[value] &&
        value != "revised_amt" &&
        value != "paid_amount" &&
        value != "order_payment_mode" &&
        value != "revised_order_amt" &&
        value != "credit_amt" &&
        value != "bag_id"
      ) {
        static_order_render.push(
          <div>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                {label_map[value]}
              </InputGroupAddon>
              <Input value={static_orders[value]} readOnly />
            </InputGroup>
            <br />
          </div>
        );
      }
    });

    Object.keys(static_order_render_table_key).forEach((key) => {
      if (!static_order_render_table_key[key]) return;
      static_order_render_table.push(
        <th>{static_order_render_table_header[key]}</th>
      );
    });

    let print_item_details = {};
    let replaced_item_details = {};

    //if partial or shipped-modified add delete item option
    Object.keys(order_details).forEach((key) => {
      let deliveredQty;
      let color = "black";
      if (
        order_details[key].item_status == "cancel" &&
        (getOrderHistory[driver_id][order_id].status
          .toLowerCase()
          .includes("shipped") ||
          getOrderHistory[driver_id][order_id].status
            .toLowerCase()
            .includes("partial"))
      ) {
        color = "white";
      }

      let itemAmount = order_details[key].mrp ? order_details[key].mrp : 0;
      let item_partial_color = color;
      let itemQty = Number(order_details[key].order_qty).toFixed(0);
      let originalItemQty = Number(order_details[key].order_qty).toFixed(0);
      if (
        (order_details[key].hasOwnProperty("item_delivered_qty") ||
          order_details[key].hasOwnProperty("item_modified_qty")) &&
        getOrderHistory[driver_id][order_id].status
          .toLowerCase()
          .includes("partial") &&
        Number(order_details[key].item_delivered_qty) != itemQty
      ) {
        deliveredQty =
          order_details[key].item_delivered_qty != undefined
            ? Number(order_details[key].item_delivered_qty)
            : Number(order_details[key].item_modified_qty);
      } else if (
        order_details[key].item_modified_qty != undefined &&
        getOrderHistory[driver_id][order_id].status
          .toLowerCase()
          .includes("shipped") &&
        Number(order_details[key].item_modified_qty) != itemQty
      ) {
        deliveredQty = Number(order_details[key].item_modified_qty);
      }

      if (
        typeof deliveredQty != "undefined" &&
        !isNaN(deliveredQty) &&
        (getOrderHistory[driver_id][order_id].status
          .toLowerCase()
          .includes("shipped") ||
          getOrderHistory[driver_id][order_id].status
            .toLowerCase()
            .includes("partial"))
      ) {
        print_item_details[order_details[key].item_id] = deliveredQty;
        itemAmount =
          Number(itemAmount * (deliveredQty / itemQty)).toFixed(2) +
          " [₹ " +
          itemAmount +
          "]";
        if (order_details[key].item_reason) {
          itemQty =
            deliveredQty.toFixed(2) +
            " [" +
            itemQty +
            "] - " +
            order_details[key].item_reason +
            " ";
        } else {
          itemQty = deliveredQty.toFixed(2) + " [" + itemQty + "] ";
        }
      }

      if (order_details[key].ordered_item_id) {
        replaced_item_details[order_details[key].ordered_item_id] =
          order_details[key].item_id;
        replaced_item_details[order_details[key].ordered_item_disc] =
          order_details[key].item_disc;
        replaced_item_details[order_details[key].ordered_item_qty] =
          order_details[key].ordered_item_qty;
      }

      let orderUpdatedBy = order_details[key].itemModifiedUpdatedBy
        ? order_details[key].itemModifiedUpdatedBy
        : "";
      let itemModifiedQty = order_details[key].item_modified_qty
        ? Number(order_details[key].item_modified_qty)
        : null;

      static_order_render_table_body.push([
        <tr
          className={
            order_details[key].item_status == "cancel"
              ? "bg-danger"
              : order_details[key].ordered_item_id
              ? "bg-warning"
              : "table-default"
          }
        >
          <td style={{ color: color }}>
            {order_details[key].item_id}{" "}
            {((!deliveryEndFlagMap[driver_id] &&
                !routeOrdersConsolidationFlag[driver_id] &&
                  (validateIsExportUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) &&
                    (driver_id == "8888" || driver_id == "8701")) &&
                !validateIsOperationUser(
                  JSON.parse(sessionStorage.getItem(`user`))[0].email
                )) ||
                validateIsAdminUser(
                  JSON.parse(sessionStorage.getItem(`user`))[0].email
                )) &&
                driver_id != "9999" &&
                driver_id != "9998" &&
                finalUserEditAccess &&
            order_details[key].ordered_item_id ? (
              <>
                <span>
                  (<del>{order_details[key].ordered_item_id}</del>)
                </span>
                <IconButton
                  size="small"
                  onClick={() => {
                    const userConfirmed = window.confirm(`Are you sure you want to undo the item ${order_details[key].item_id}?`);
                    if (userConfirmed){
                    handleReplaceItemDeletion(
                      driver_id,
                      order_details[key].order_id,
                      order_details[key].ordered_item_id,
                      order_details[key].ordered_item_disc,
                      getOrderHistory[driver_id][order_id].status,
                      order_details[key].item_id,
                      order_details[key].ordered_item_qty
                    );
                  }
                  }}
                >
                  <FontAwesomeIcon size="sm" icon={faUndo} />
                </IconButton>
              </>
            ) : null}
          </td>
          <td style={{ color: color }}>{order_details[key].item_disc}</td>

          {((!deliveryEndFlagMap[driver_id] &&
                !routeOrdersConsolidationFlag[driver_id] &&
                  (validateIsExportUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) &&
                    (driver_id == "8888" || driver_id == "8701")) &&
                !validateIsOperationUser(
                  JSON.parse(sessionStorage.getItem(`user`))[0].email
                )) ||
                validateIsAdminUser(
                  JSON.parse(sessionStorage.getItem(`user`))[0].email
                )) &&
                driver_id != "9999" &&
                driver_id != "9998" &&
                finalUserEditAccess && 
            (getOrderHistory[driver_id][order_id].status ==
              "Partially-Delivered" ||
              getOrderHistory[driver_id][order_id].status
                .toLowerCase()
                .includes("shipped")) ? (
            <td style={{ color: item_partial_color }}>
              {itemQty + " "}
              <IconButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  setItemData([
                    driver_id,
                    order_details[key].order_id,
                    getOrderHistory[driver_id][order_id].status,
                    Number(
                      getOrderHistory[driver_id][
                        `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
                          order_details[key].item_id
                        }`
                      ].case_size
                    ) || 1,
                  ]);
                  setItemQuant(itemQty);
                  setItemOrderQty(originalItemQty);
                  setItemDesc(order_details[key].item_disc);
                  setItemId(order_details[key].item_id);
                  setModifiedQty(itemModifiedQty);
                  setModalModifiedItemQty(true);
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
            </td>
          ) : !(
              (getOrderHistory[driver_id][order_id].status
                .toLowerCase()
                .includes("partial") &&
                !validateIsOperationUser(
                  JSON.parse(sessionStorage.getItem(`user`))[0].email
                )) ||
              (getOrderHistory[driver_id][order_id].status
                .toLowerCase()
                .includes("shipped") &&
                !validateIsBillingUser(
                  JSON.parse(sessionStorage.getItem(`user`))[0].email
                ))
            ) || order_details[key].item_status === "cancel" ? (
            <td style={{ color: color }}>{itemQty}</td>
          ) : ((!deliveryEndFlagMap[driver_id] &&
            !routeOrdersConsolidationFlag[driver_id] &&
              (validateIsExportUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              ) &&
                (driver_id == "8888" || driver_id == "8701")) &&
            !validateIsOperationUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            )) ||
            validateIsAdminUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            )) &&
            driver_id != "9999" &&
            driver_id != "9998" &&
            finalUserEditAccess ? (
            <td style={{ color: item_partial_color }}>
              {itemQty + " "}
              <IconButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  setItemData([
                    driver_id,
                    order_details[key].order_id,
                    getOrderHistory[driver_id][order_id].status,
                    Number(
                      getOrderHistory[driver_id][
                        `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
                          order_details[key].item_id
                        }`
                      ].case_size
                    ) || 1,
                  ]);
                  setItemQuant(itemQty);
                  setItemOrderQty(originalItemQty);
                  setItemDesc(order_details[key].item_disc);
                  setItemId(order_details[key].item_id);
                  setModifiedQty(itemModifiedQty);
                  deliveryEndFlagMap[driver_id]
                    ? setModalModifiedItemQty(false)
                    : setModalModifiedItemQty(true);
                }}
              >
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </IconButton>
            </td>
          ) : (
            <td style={{ color: color }}>{itemQty}</td>
          )}

          <td style={{ color: color }}>₹ {itemAmount}</td>
          {((!deliveryEndFlagMap[driver_id] &&
                !routeOrdersConsolidationFlag[driver_id] &&
                  (validateIsExportUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) &&
                    (driver_id == "8888" || driver_id == "8701")) &&
                !validateIsOperationUser(
                  JSON.parse(sessionStorage.getItem(`user`))[0].email
                )) ||
                validateIsAdminUser(
                  JSON.parse(sessionStorage.getItem(`user`))[0].email
                )) &&
                driver_id != "9999" &&
                driver_id != "9998" &&
                finalUserEditAccess &&
          (getOrderHistory[driver_id][order_id].status ==
            "Partially-Delivered" ||
            getOrderHistory[driver_id][order_id].status
              .toLowerCase()
              .includes("shipped")) ? (
            order_details[key].item_status == "cancel" &&
            !validateIsBillingUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            ) ? (
              <td>
                <IconButton
                  size="small"
                  style={{ color: color }}
                  onClick={(e) => {
                    e.preventDefault();
                    const userConfirmed = window.confirm(`Are you sure you want to undo the item ${order_details[key].item_id}?`);
                    if(userConfirmed){
                    fireBaseItemStatusUpdate(
                      driver_id,
                      order_details[key].order_id,
                      {
                        item_status: "",
                        item_reason: "",
                        item_primary_reason: "",
                      }
                    );
                  }
                  }}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </IconButton>
              </td>
            ) : (
              <td>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setItemData([
                      driver_id,
                      order_details[key].order_id,
                      getOrderHistory[driver_id][order_id].status,
                      Number(getOrderHistory[driver_id][order_id].case_size) ||
                        1,
                    ]);
                    setModalDeletedItemQty(true);
                  }}
                  style={{ color: "#e0421b" }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} size="sm" />
                </IconButton>
              </td>
            )
          ) : ((!deliveryEndFlagMap[driver_id] &&
            !routeOrdersConsolidationFlag[driver_id] &&
              (validateIsExportUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              ) &&
                (driver_id == "8888" || driver_id == "8701")) &&
            !validateIsOperationUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            )) ||
            validateIsAdminUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            )) &&
            driver_id != "9999" &&
            driver_id != "9998" &&
            finalUserEditAccess && 
            ((getOrderHistory[driver_id][order_id].status
              .toLowerCase()
              .includes("partial") &&
              !validateIsOperationUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              )) ||
              (getOrderHistory[driver_id][order_id].status
                .toLowerCase()
                .includes("shipped") &&
                !validateIsBillingUser(
                  JSON.parse(sessionStorage.getItem(`user`))[0].email
                ))) &&
            order_details[key].item_status != "cancel" ? (
            <td>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  setItemData([
                    driver_id,
                    order_details[key].order_id,
                    getOrderHistory[driver_id][order_id].status,
                    Number(getOrderHistory[driver_id][order_id].case_size) || 1,
                  ]);
                  setModalDeletedItemQty(true);
                }}
                style={{ color: "#e0421b" }}
              >
                <FontAwesomeIcon icon={faTrashAlt} size="sm" />
              </IconButton>
            </td>
          ) : ((!deliveryEndFlagMap[driver_id] &&
            !routeOrdersConsolidationFlag[driver_id] &&
              (validateIsExportUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              ) &&
                (driver_id == "8888" || driver_id == "8701")) &&
            !validateIsOperationUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            )) ||
            validateIsAdminUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            )) &&
            driver_id != "9999" &&
            driver_id != "9998" &&
            finalUserEditAccess &&
            ((getOrderHistory[driver_id][order_id].status
              .toLowerCase()
              .includes("partial")  &&
              order_details[key].item_modified_qty === undefined) ||
              (getOrderHistory[driver_id][order_id].status
                .toLowerCase()
                .includes("shipped"))) ? (
            <td>
              <IconButton
                size="small"
                style={{ color: color }}
                onClick={(e) => {
                  e.preventDefault();
                  const userConfirmed = window.confirm(`Are you sure you want to undo the item ${order_details[key].item_id}?`);
                  if(userConfirmed){
                  fireBaseItemStatusUpdate(
                    driver_id,
                    order_details[key].order_id,
                    {
                      item_status: "",
                      item_reason: "",
                      item_primary_reason: "",
                    }
                  );
                }
                }}
              >
                <FontAwesomeIcon icon={faUndo} />
              </IconButton>
            </td>
          ) : null}
        </tr>,
      ]);
    });

    //Adding Retailer Details

    getOrderHistory[driver_id][order_id]["retailer_id"] > 0
      ? Object.keys(static_shop_keys).forEach((key) => {
          static_shop_render.push(
            <p>
              <b>
                {getOrderHistory[driver_id][order_id][static_shop_keys[key]]}
              </b>
              <br />
            </p>
          );
        })
      : Object.keys(static_customer_keys).forEach((key) => {
          static_shop_render.push(
            <p>
              <b>
                {
                  getOrderHistory[driver_id][order_id][
                    static_customer_keys[key]
                  ]
                }
              </b>
              <br />
            </p>
          );
        });

    Object.keys(static_address_keys).forEach((key) => {
      static_address_render.push(
        <p>
          <b>
            {getOrderHistory[driver_id][order_id][static_address_keys[key]]}
          </b>
          <br />
        </p>
      );
    });

    console.log(getOffersObject);
    let couponCodeValue = getOrderHistory[driver_id][order_id].coupon_code
      ? getOrderHistory[driver_id][order_id].coupon_code
      : "";

    let offersObj =
      Object.keys(getOffersObject).length > 0
        ? getOffersObject
        : localStorage.getItem("offersObj")
        ? JSON.parse(localStorage.getItem("offersObj"))
        : {};

    let customOffersObj =
      Object.keys(getCustomOffersObject).length > 0
        ? getCustomOffersObject
        : localStorage.getItem("customOffersObj")
        ? JSON.parse(localStorage.getItem("customOffersObj"))
        : {};

    let freeProductOfferDiscrepanciesList = {};

    if (Object.keys(offersObj).length > 0 && couponCodeValue) {
      let couponCodeValueList = couponCodeValue.split(",");

      //supports freeproduct offer
      for (let x = 0; x < couponCodeValueList.length; x++) {
        let singleCouponCode = couponCodeValueList[x] || "";
        let orderOfferObj = {};

        Object.keys(customOffersObj).map((customofferkey, customofferindex) => {
          let offer_coupon_code = customOffersObj[customofferkey].coupon_code;
          let mode = customOffersObj[customofferkey].mode
            ? customOffersObj[customofferkey].mode
            : "";
          mode = mode.toLowerCase();

          if (mode == "freeproduct" && singleCouponCode == offer_coupon_code) {
            orderOfferObj = customOffersObj[customofferkey] || {};
          }
        });

        Object.keys(offersObj).map((offerkey, offerindex) => {
          let offer_coupon_code = offersObj[offerkey].coupon_code;
          let mode = offersObj[offerkey].mode ? offersObj[offerkey].mode : "";
          mode = mode.toLowerCase();

          if (mode == "freeproduct" && singleCouponCode == offer_coupon_code) {
            orderOfferObj = offersObj[offerkey] || {};
          }
        });

        if (Object.keys(orderOfferObj).length > 0) {
          // coupon present
          let existingSugarQuantity;
          let orderKey, orderStatus;
          let numSugarBags;

          let mov = orderOfferObj["minimum_order_value"];
          let offer_product_unit =
            Number(orderOfferObj["offer_product_unit"]) || 1;
          let minimum_buy_product = orderOfferObj["minimum_buy_product"];
          let offer_category = orderOfferObj["category"];
          let sub_category = orderOfferObj["sub_category"] || "";
          let offer_excluded_products =
            orderOfferObj["excluded_products"] || {};
          let minUnit = Number(orderOfferObj["minimum_product_unit"]) || 1;
          let excluded_category = orderOfferObj["excluded_category"] || "";

          let revisedAmtWithExcludedProductsList =
            revisedAmtForApiOffersWithExcludedProducts(
              getOrderHistory[driver_id],
              order_id,
              offer_excluded_products,
              offer_category,
              minimum_buy_product,
              sub_category,
              excluded_category
            );
          let revisedOrderAmount = revisedAmtWithExcludedProductsList[0];
          let productCount = revisedAmtWithExcludedProductsList[1];
          let productWeight = revisedAmtWithExcludedProductsList[2];

          let minimum_category_unit =
            Number(orderOfferObj.minimum_category_unit) || 0;
          let minimum_category_weight =
            Number(orderOfferObj.minimum_category_weight) || 0;
          let minimum_category_value =
            Number(orderOfferObj.minimum_category_value) || 0;

          let minimum_buy_product_unit =
            Number(orderOfferObj.minimum_buy_product_unit) || 0;
          let minimum_buy_product_value =
            Number(orderOfferObj.minimum_buy_product_value) || 0;
          let minimum_buy_product_weight =
            Number(orderOfferObj.minimum_buy_product_weight) || 0;

          let numFreeProductUnit = Math.floor(revisedOrderAmount / mov);
          
          if (minimum_buy_product) {
            if (minimum_buy_product_unit > 0) {
              if (productCount >= minimum_buy_product_unit) {
                numFreeProductUnit = Math.floor(
                  productCount / minimum_buy_product_unit
                );
              } else {
                numFreeProductUnit = 0;
              }
            } else if (minimum_buy_product_weight > 0) {
              if (productWeight >= minimum_buy_product_weight) {
                numFreeProductUnit = Math.floor(
                  productWeight / minimum_buy_product_weight
                );
              } else {
                numFreeProductUnit = 0;
              }
            } else {
              if (revisedOrderAmount >= minimum_buy_product_value) {
                numFreeProductUnit = Math.floor(
                  revisedOrderAmount / minimum_buy_product_value
                );
              } else {
                numFreeProductUnit = 0;
              }
            }
          } else if (offer_category) {
            if (minimum_category_unit > 0) {
              if (productCount >= minimum_category_unit) {
                numFreeProductUnit = Math.floor(
                  productCount / minimum_category_unit
                );
              } else {
                numFreeProductUnit = 0;
              }
            } else if (minimum_category_weight > 0) {
              if (productWeight >= minimum_category_weight) {
                numFreeProductUnit = Math.floor(
                  productWeight / minimum_category_weight
                );
              } else {
                numFreeProductUnit = 0;
              }
            } else {
              if (revisedOrderAmount >= minimum_category_value) {
                numFreeProductUnit = Math.floor(
                  revisedOrderAmount / minimum_category_value
                );
              } else {
                numFreeProductUnit = 0;
              }
            }
          }

          let freeSugarItem =
            orderOfferObj["offer_product"] || orderOfferObj["item_id"];

          numSugarBags = numFreeProductUnit * offer_product_unit;
          if (orderOfferObj["maximum_product_unit"] != null) {
            numSugarBags = Math.min(
              numSugarBags,
              Number(orderOfferObj["maximum_product_unit"])
            );
          }
          numSugarBags = Math.floor(numSugarBags);

          numSugarBags = numSugarBags >= minUnit ? numSugarBags : 0;

          if (freeSugarItem) {
            orderKey = `${order_id.split("-")[0]}-${
              order_id.split("-")[1]
            }-${freeSugarItem}`;
            orderStatus = getOrderHistory[driver_id][order_id].status;

            if (getOrderHistory[driver_id][orderKey]) {
              existingSugarQuantity = getOrderHistory[driver_id][orderKey][
                "item_delivered_qty"
              ]
                ? Number(
                    getOrderHistory[driver_id][orderKey]["item_delivered_qty"]
                  )
                : getOrderHistory[driver_id][orderKey]["item_modified_qty"]
                ? Number(
                    getOrderHistory[driver_id][orderKey]["item_modified_qty"]
                  )
                : getOrderHistory[driver_id][orderKey]["order_qty"]
                ? Number(getOrderHistory[driver_id][orderKey]["order_qty"])
                : 0;

              let offerele = {};
              offerele.existingSugarQuantity = existingSugarQuantity;
              offerele.orderKey = orderKey;
              offerele.orderStatus = orderStatus;
              offerele.numSugarBags = numSugarBags;
              offerele.singleCouponCode = singleCouponCode;
              offerele.freeProduct = freeSugarItem;

              if (freeProductOfferDiscrepanciesList[freeSugarItem]) {
                let prevOfferSameFreeItemQty =
                  Number(
                    freeProductOfferDiscrepanciesList[freeSugarItem]
                      .numSugarBags
                  ) || 0;
                offerele.numSugarBags += prevOfferSameFreeItemQty;
              }

              freeProductOfferDiscrepanciesList[freeSugarItem] = offerele;
            }
          }
        }
      }
    }

    //find total amount to be paid
    //check discount scenarios
    let totalAmountToBePaid = calculateTotalAmountToBePaid(
      getOrderHistory[driver_id],
      order_id
    );
    let order_disc = getOrderHistory[driver_id][order_id].order_disc
      ? Number(getOrderHistory[driver_id][order_id].order_disc)
      : 0;
    // totalAmountToBePaid=totalAmountToBePaid-order_disc;
    let totalOrderAmountValue = getOrderHistory[driver_id][order_id].total_amt;
    let diffInTotalAmt = 0;
    if (driver_id != "9999" && driver_id != "9998") {
      diffInTotalAmt =
        Number(totalOrderAmountValue) - Number(totalAmountToBePaid);
    }

    // if(getOrderHistory[driver_id][order_id]['status'].includes("Delivered") && driver_id != '9999') {
    // diffInTotalAmt = revisedAmt(getOrderHistory[driver_id], order_id).toFixed(2) - getOrderHistory[driver_id][order_id]["payment_details"]["total_amount_paid"]
    // }
    let revisedOrderAmtValue =
      getOrderHistory[driver_id][order_id].revised_order_amt;

    let finalPaidAmt = 0;
    if (getOrderHistory[driver_id][order_id]["payment_details"]) {
      finalPaidAmt = getOrderHistory[driver_id][order_id]["payment_details"][
        "total_amount_paid"
      ]
        ? Number(
            getOrderHistory[driver_id][order_id]["payment_details"][
              "total_amount_paid"
            ]
          )
        : 0;
    }
    let paidAmtDiff = srevisedOrderAmtWithDisc - finalPaidAmt;

    // revised_order_disc correction
    let originalRevisedOrderrDiscount = getOrderHistory[driver_id][order_id]
      .revised_order_disc
      ? Number(getOrderHistory[driver_id][order_id].revised_order_disc)
      : 0;
    let calcRevisedOrderDisc = 0;

    let originalOrderDisc = getOrderHistory[driver_id][order_id].order_disc
      ? Number(getOrderHistory[driver_id][order_id].order_disc)
      : 0;
    if (originalOrderDisc > 0) {
      calcRevisedOrderDisc = revisedOrderDiscount(
        driver_id,
        getOrderHistory[driver_id],
        order_id,
        getOrderHistory[driver_id][order_id]["revised_order_amt"],
        couponCodeValue,
        false
      );
    }

    calcRevisedOrderDisc = calcRevisedOrderDisc
      ? Number(calcRevisedOrderDisc)
      : 0;
    let diffInRevisedOrderDisc =
      originalRevisedOrderrDiscount - calcRevisedOrderDisc;

    let originalRevisedOrderAmt = getOrderHistory[driver_id][order_id]
      .revised_order_amt
      ? Number(getOrderHistory[driver_id][order_id].revised_order_amt)
      : 0;
    let calcRevisedOrderAmt = revisedAmt(
      getOrderHistory[driver_id],
      order_id
    ).toFixed(2);
    calcRevisedOrderAmt = calcRevisedOrderAmt ? Number(calcRevisedOrderAmt) : 0;
    let diffInRevisedOrderAmt = originalRevisedOrderAmt - calcRevisedOrderAmt;

    let originalOrderNumber = getOrderHistory[driver_id][order_id].order_number
      ? getOrderHistory[driver_id][order_id].order_number
      : "";
    let originalOrderStatus = getOrderHistory[driver_id][order_id].status
      ? getOrderHistory[driver_id][order_id].status
      : "";

    let orderErrorDisplayCheck = false;

    if (originalOrderNumber.includes("-R")) {
      if (originalOrderStatus.includes("Delivered")) {
        orderErrorDisplayCheck = true;
      }
    } else {
      orderErrorDisplayCheck = true;
    }

    let retailerPhone = getOrderHistory[driver_id][order_id].phone
      ? getOrderHistory[driver_id][order_id].phone
      : "";

    setRenderOrderDetails([
      <Col sm={10} style={{ overflowY: "scroll", maxHeight: "80vh" }}>
        {Number(getOrderHistory[driver_id][order_id].retailer_id) == 0 &&
        (isRoutePickingCompletedFlag[`${driver_id}-BAG`] ||
          isRoutePickingCompletedFlag[`${driver_id}-FMCG`] ||
          isRoutePickingCompletedFlag[`${driver_id}-HIGH_VALUE`] ||
          isRoutePickingCompletedFlag[`${driver_id}-LOOSE_PACK`]) ? (
          <Link to="#" target="_blank" style={{ float: "right" }}>
            <IconButton
              size={"small"}
              variant={"primary"}
              onClick={() => {
                localStorage.setItem(
                  "orderData",
                  JSON.stringify(getOrderHistory[driver_id])
                );
                sessionStorage.setItem(
                  "orderNumberToDisplay",
                  `${order_id.split("-")[0]}/${order_id.split("-")[1]}`
                );
                sessionStorage.setItem("driverId", driver_id);
                sessionStorage.setItem("orderDate", getDate);
                sessionStorage.setItem(
                  "item_qty_details",
                  JSON.stringify(print_item_details)
                );
                sessionStorage.setItem(
                  "replaced_item_details",
                  JSON.stringify(replaced_item_details)
                );
                sessionStorage.setItem("getDB", getDB);
                window.open("khetikaBill", "_blank");
              }}
            >
              <FontAwesomeIcon size="2x" icon={faPrint} />
            </IconButton>
          </Link>
        ) : validateIsAdminUser(
            JSON.parse(sessionStorage.getItem(`user`))[0].email
          ) ||
          (allowedRoutesSet.has(`/orderPrintDetails`) &&
            (isRoutePickingCompletedFlag[`${driver_id}-BAG`] ||
              isRoutePickingCompletedFlag[`${driver_id}-FMCG`] ||
              isRoutePickingCompletedFlag[`${driver_id}-HIGH_VALUE`] ||
              isRoutePickingCompletedFlag[`${driver_id}-LOOSE_PACK`])) ? (
          <IconButton
            style={{ float: "right" }}
            size={"small"}
            variant={"primary"}
            onClick={() => {
              localStorage.setItem(
                "orderData",
                JSON.stringify(getOrderHistory[driver_id])
              );
              sessionStorage.setItem(
                "orderNumberToDisplay",
                `${order_id.split("-")[0]}/${order_id.split("-")[1]}`
              );
              sessionStorage.setItem("driverId", driver_id);
              sessionStorage.setItem("orderDate", getDate);
              sessionStorage.setItem(
                "item_qty_details",
                JSON.stringify(print_item_details)
              );
              sessionStorage.setItem(
                "replaced_item_details",
                JSON.stringify(replaced_item_details)
              );
              sessionStorage.setItem("getDB", getDB);
              window.open(`orderPrintDetails`, "_blank");
            }}
          >
            <FontAwesomeIcon size="2x" icon={faPrint} />
          </IconButton>
        ) : null}

        <Tabs defaultActiveKey="orderInfo" id="uncontrolled-tab-example">
          <Tab eventKey="orderInfo" title="Order Info">
            <Form>
              <br />
              {(diffInTotalAmt > 5 || diffInTotalAmt < -5) &&
                orderErrorDisplayCheck &&
                finalUserEditAccess && (
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "#FF6347",
                      padding: "10px",
                      margin: "15px",
                      borderRadius: "5px",
                    }}
                  >
                    There is a discrepancy in the total amount for the orderNo{" "}
                    {`${order_id.split("-")[0]}/${order_id.split("-")[1]}`}{" "}
                    &nbsp;&nbsp;
                    <Button
                      size={"sm"}
                      variant={"primary"}
                      onClick={() => {
                        setTotalAmountUpdatePopupShow(true);
                        setTotalAmtToBePaid(totalAmountToBePaid);
                        setModalData([driver_id, order_id]);
                      }}
                    >
                      Update
                    </Button>
                  </div>
                )}
              {diffInRevisedOrderDisc != 0 &&
                finalUserEditAccess &&
                orderErrorDisplayCheck &&
                getOrderHistory[driver_id][order_id].revised_order_disc &&
                (originalOrderStatus == "Partially-Delivered" ||
                  originalOrderStatus == "Shipped-Modified") && (
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "#FF6347",
                      padding: "10px",
                      margin: "15px",
                      borderRadius: "5px",
                    }}
                  >
                    There is a discrepancy in the revised order discount for the
                    orderNo{" "}
                    {`${order_id.split("-")[0]}/${order_id.split("-")[1]}`}{" "}
                    &nbsp;&nbsp;
                    <Button
                      size={"sm"}
                      variant={"primary"}
                      onClick={() => {
                        setRevisedOrderDiscUpdatePopupShow(true);
                        setRevisedOrderDiscToBeUpdated(calcRevisedOrderDisc);
                        setModalData([driver_id, order_id]);
                      }}
                    >
                      Update
                    </Button>
                  </div>
                )}

              {diffInRevisedOrderAmt != 0 &&
                finalUserEditAccess && 
                getOrderHistory[driver_id][order_id].revised_order_amt &&
                originalOrderStatus == "Partially-Delivered" &&
                orderErrorDisplayCheck && (
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "#FF6347",
                      padding: "10px",
                      margin: "15px",
                      borderRadius: "5px",
                    }}
                  >
                    Revised order amount should be {`${calcRevisedOrderAmt}`}{" "}
                    &nbsp;&nbsp;
                    <Button
                      size={"sm"}
                      variant={"primary"}
                      onClick={() => {
                        setRevisedOrderAmtUpdatePopupShow(true);
                        setRevisedOrderAmtToBeUpdated(calcRevisedOrderAmt);
                        setModalData([driver_id, order_id]);
                      }}
                    >
                      Update
                    </Button>
                  </div>
                )}

              {(paidAmtDiff > 5 || paidAmtDiff < -5) &&
                getOrderHistory[driver_id][order_id].status
                  .toLowerCase()
                  .includes("delivered") &&
                orderErrorDisplayCheck &&
                !order_id.includes("OR") && (
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "#FF6347",
                      padding: "10px",
                      margin: "15px",
                      borderRadius: "5px",
                    }}
                  >
                    There is a discrepancy in the paid amount for the orderNo{" "}
                    {`${order_id.split("-")[0]}/${order_id.split("-")[1]}`}{" "}
                  </div>
                )}

              {Object.keys(freeProductOfferDiscrepanciesList).length > 0
                ? Object.keys(freeProductOfferDiscrepanciesList).map(
                    (offer_key, offer_index) => {
                      let offerErr =
                        freeProductOfferDiscrepanciesList[offer_key] || {};
                      if (
                        offerErr.numSugarBags !=
                          offerErr.existingSugarQuantity &&
                        offerErr.existingSugarQuantity !== undefined
                      )
                        return (
                          <div
                            style={{
                              display: "flex",
                              backgroundColor: "#FF6347",
                              padding: "10px",
                              margin: "15px",
                              borderRadius: "5px",
                            }}
                          >
                            Free product ({offerErr.freeProduct}) quantity
                            should be {offerErr.numSugarBags} based on updated
                            Order Value &nbsp;&nbsp;
                            {finalUserEditAccess && (getOrderHistory[driver_id][order_id].status
                              .toLowerCase()
                              .includes("shipped") || getOrderHistory[driver_id][order_id].status
                              .toLowerCase()
                              .includes("process")) && (
                              <Button
                                size={"sm"}
                                variant={"primary"}
                                onClick={() => {
                                  updateSugarQuantity(
                                    driver_id,
                                    order_id,
                                    offerErr.orderKey,
                                    offerErr.numSugarBags,
                                    offerErr.orderStatus
                                  );
                                }}
                              >
                                Update
                              </Button>
                            )}
                          </div>
                        );
                    }
                  )
                : null}

              {getOrderHistory[driver_id][order_id]["order_number"].includes(
                "-R"
              ) &&
                getOrderHistory[driver_id][order_id][
                  "customer_returned_items"
                ] == "Yes" &&
                driver_id != "9999" &&
                driver_id != "9998" && (
                  <div
                    style={{
                      display: "flex",
                      backgroundColor: "#76EE00",
                      padding: "10px",
                      margin: "15px",
                      borderRadius: "5px",
                    }}
                  >
                    Customer already returned items
                  </div>
                )}
              {static_order_render}
              <Form.Group>
                <Form.Label>
                  <b>Item Details</b>
                </Form.Label>
              </Form.Group>
            </Form>
            <Table striped bordered hover>
              <thead>
                <tr className="bg-dark text-white">
                  {static_order_render_table}
                </tr>
              </thead>
              <tbody>{static_order_render_table_body}</tbody>
            </Table>
          </Tab>

          <Tab eventKey="payments" title="Payments">
            <Form>
              <br />
              {temp2.length > 0 ? (
                temp2
              ) : (
                <div>No Payment Details Available.</div>
              )}
            </Form>
            <br />
            {validateIsAdminUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            ) && (
              <Button
                variant={"success"}
                onClick={() => {
                  updatePaymentsForDriverOrder(driver_id, order_id, retailerID);
                }}
              >
                Update Discounts
              </Button>
            )}
          </Tab>

          <Tab eventKey="retailer" title="Retailer">
            <Form></Form>
            <div style={{ marginTop: "50px" }}>
              <Card body className="text-center">
                <CardHeader>
                  Retailer Details{" "}
                  {/* {validateIsAdminUser(
                    JSON.parse(sessionStorage.getItem(`user`))[0].email
                  ) && ( */}
                  <Button
                    size={"sm"}
                    variant={"primary"}
                    onClick={() => {
                      updateRetailerForDriverOrder(
                        driver_id,
                        order_id,
                        retailerID,
                        retailerPhone
                      );
                    }}
                  >
                    ⟳
                  </Button>
                  {/* )} */}
                </CardHeader>
                <br />
                <CardText>{static_shop_render}</CardText>
              </Card>
            </div>
            <div>
              <Card body className="text-center">
                <CardHeader>Address</CardHeader>
                <br />
                <CardText>{static_address_render}</CardText>
              </Card>
            </div>
          </Tab>
        </Tabs>
      </Col>,
    ]);
    console.log(getOrderHistory[driver_id][order_id].status);
    setOrderStatusCheck(getOrderHistory[driver_id][order_id].status);
    setBackDrop(false);
  };

  const updatePaymentsForDriverOrder = (driver_id, order_id, retailerID) => {
    let firebaseOrderNumberList = order_id.split("-");
    let firstPartOfOrderNo = firebaseOrderNumberList[0];
    if (firstPartOfOrderNo.includes("_")) {
      firstPartOfOrderNo = firstPartOfOrderNo.replace("_", "-");
    }
    let firebaseOrderNumber =
      firstPartOfOrderNo + "-" + firebaseOrderNumberList[1];

    fire
      .database()
      .ref(`Orders_History/${retailerID}/`)
      .orderByKey()
      .startAt(`${firebaseOrderNumber}-`)
      .endAt(`${firebaseOrderNumber}-\uf8ff`)
      .once("value", (snapshot) => {
        if (snapshot.hasChildren()) {
          let orderData = snapshot.val();

          let temp_order_history = getOrderHistory;

          let updateToAll = {};

          Object.keys(orderData).map((key, index) => {
            updateToAll["order_disc"] = orderData[key]["order_disc"]
              ? orderData[key]["order_disc"]
              : "0";
            updateToAll["coupon_code"] = orderData[key][
              "coupon_code_order_level"
            ]
              ? orderData[key]["coupon_code_order_level"]
              : "";
            updateToAll["cashback_redeemed"] = orderData[key][
              "cashback_redeemed"
            ]
              ? orderData[key]["cashback_redeemed"]
              : "0";
          });

          let keyList = getItemKeys(driver_id);

          for (let key in keyList[
            order_id.split("-")[0] + order_id.split("-")[1]
          ]) {
            let orderItemKey = `${order_id.split("-")[0]}-${
              order_id.split("-")[1]
            }-${keyList[order_id.split("-")[0] + order_id.split("-")[1]][key]}`;
            let modified_url = getDB + "/" + driver_id + "/" + orderItemKey;
            fire.database().ref(modified_url).update(updateToAll);
            temp_order_history[driver_id][orderItemKey].order_disc =
              updateToAll["order_disc"];
            temp_order_history[driver_id][orderItemKey].coupon_code =
              updateToAll["coupon_code"];
            temp_order_history[driver_id][orderItemKey].cashback_redeemed =
              updateToAll["cashback_redeemed"];
          }

          setAlertType("success");
          setAlertMessage("Discounts updated succesfully");
          setAlertStatus(true);
          setOrderHistory(temp_order_history);
          generateItemSummary(driver_id);
          RenderOrdersDetails(driver_id, order_id);
          setBackDrop(false);
        } else {
          fire
            .database()
            .ref(`Orders_New/`)
            .orderByKey()
            .startAt(`${firebaseOrderNumber}-`)
            .endAt(`${firebaseOrderNumber}-\uf8ff`)
            .once("value", (snapshot) => {
              if (snapshot.hasChildren()) {
                let orderData = snapshot.val();

                let temp_order_history = getOrderHistory;

                let updateToAll = {};

                Object.keys(orderData).map((key, index) => {
                  updateToAll["order_disc"] = orderData[key]["order_disc"]
                    ? orderData[key]["order_disc"]
                    : "0";
                  updateToAll["coupon_code"] = orderData[key][
                    "coupon_code_order_level"
                  ]
                    ? orderData[key]["coupon_code_order_level"]
                    : "";
                  updateToAll["total_amt"] = orderData[key]["net_order_amt"]
                    ? orderData[key]["net_order_amt"]
                    : "0";
                  updateToAll["cashback_redeemed"] = orderData[key][
                    "cashback_redeemed"
                  ]
                    ? orderData[key]["cashback_redeemed"]
                    : "0";
                });

                let keyList = getItemKeys(driver_id);

                for (let key in keyList[
                  order_id.split("-")[0] + order_id.split("-")[1]
                ]) {
                  let orderItemKey = `${order_id.split("-")[0]}-${
                    order_id.split("-")[1]
                  }-${
                    keyList[order_id.split("-")[0] + order_id.split("-")[1]][
                      key
                    ]
                  }`;
                  let modified_url =
                    getDB + "/" + driver_id + "/" + orderItemKey;
                  fire.database().ref(modified_url).update(updateToAll);
                  temp_order_history[driver_id][orderItemKey].order_disc =
                    updateToAll["order_disc"];
                  temp_order_history[driver_id][orderItemKey].coupon_code =
                    updateToAll["coupon_code"];
                  temp_order_history[driver_id][
                    orderItemKey
                  ].cashback_redeemed = updateToAll["cashback_redeemed"];
                }

                setAlertType("success");
                setAlertMessage("Discounts updated succesfully");
                setAlertStatus(true);
                setOrderHistory(temp_order_history);
                generateItemSummary(driver_id);
                RenderOrdersDetails(driver_id, order_id);
                setBackDrop(false);
              } else {
                setAlertType("error");
                setAlertMessage("Order number not present");
                setAlertStatus(true);
              }
            });
        }
      });
  };

  const fetchBagIdForSmallPackItems = () => {
    let routeOrdersData = getOrderHistory || {};
    let orderNumberSet = new Set();
    let orderstring = "(";
    let varietyOrderMap = {};
    Object.keys(routeOrdersData).map((rkey, rindex) => {
      let routeData = routeOrdersData[rkey] || {};

      Object.keys(routeData).map((okey, oindex) => {
        let order_number = routeData[okey].order_number || "";
        let variety = routeData[okey].variety || "";
        let bag_id = routeData[okey].bag_id || "";
        if (variety == "SMALL PACK" && bag_id) {
          if (!varietyOrderMap[order_number]) {
            varietyOrderMap[order_number] = {};
            varietyOrderMap[order_number].bagid = `${bag_id}|`;
          } else {
            let bagStr = varietyOrderMap[order_number].bagid || "";
            if (!bagStr.includes(bag_id)) {
              varietyOrderMap[order_number].bagid += `${bag_id}|`;
            }
          }
        }
      });
    });

    let finalVarietyOrderMap = {};
    Object.keys(varietyOrderMap).map((key, index) => {
      let bagid = varietyOrderMap[key].bagid;
      bagid = bagid.slice(0, -1);

      if (!finalVarietyOrderMap[key]) {
        finalVarietyOrderMap[key] = {};
      }

      finalVarietyOrderMap[key].bagid = bagid;
    });

    setOrderVarietyMap(finalVarietyOrderMap);
    setItemDetailsStatus(true);
  };

  const updateRetailerForDriverOrder = (
    driver_id,
    order_id,
    retailerID,
    retailerPhone
  ) => {
    if (retailerID > 0) {
      fire
        .database()
        .ref(`Retailer_Master_New/${retailerPhone}`)
        .once("value", (snapshot) => {
          if (snapshot.hasChildren()) {
            let retailerData = snapshot.val();

            let temp_order_history = getOrderHistory;

            let updateToAll = {};
            updateToAll["address1"] = retailerData["address1"];
            updateToAll["address2"] = retailerData["address2"];
            updateToAll["fssai_document_number"] = retailerData[
              "fssai_document"
            ]
              ? retailerData["fssai_document"]["document_number"]
              : "";
            updateToAll["gst_number"] = retailerData["gst_number"];
            updateToAll["retailer_name"] = retailerData["retailer_name"];
            updateToAll["shop_name"] = retailerData["shop_name"];
            updateToAll["latitude"] = retailerData["latitude"];
            updateToAll["longitude"] = retailerData["longitude"];

            let keyList = getItemKeys(driver_id);

            for (let key in keyList[
              order_id.split("-")[0] + order_id.split("-")[1]
            ]) {
              let orderItemKey = `${order_id.split("-")[0]}-${
                order_id.split("-")[1]
              }-${
                keyList[order_id.split("-")[0] + order_id.split("-")[1]][key]
              }`;
              let modified_url = getDB + "/" + driver_id + "/" + orderItemKey;
              fire.database().ref(modified_url).update(updateToAll);
              temp_order_history[driver_id][orderItemKey].address1 =
                updateToAll["address1"];
              temp_order_history[driver_id][orderItemKey].address2 =
                updateToAll["address2"];
              temp_order_history[driver_id][
                orderItemKey
              ].fssai_document_number = updateToAll["fssai_document_number"];
              temp_order_history[driver_id][orderItemKey].gst_number =
                updateToAll["gst_number"];
              temp_order_history[driver_id][orderItemKey].retailer_name =
                updateToAll["retailer_name"];
              temp_order_history[driver_id][orderItemKey].shop_name =
                updateToAll["shop_name"];
            }

            setAlertType("success");
            setAlertMessage("Retailer updated succesfully");
            setAlertStatus(true);
            setOrderHistory(temp_order_history);
            generateItemSummary(driver_id);
            RenderOrdersDetails(driver_id, order_id);
            setBackDrop(false);
          } else {
            setAlertType("error");
            setAlertMessage("Retailer not present");
            setAlertStatus(true);
          }
        });
    } else {
      fire
        .database()
        .ref(`Society_Master/${retailerPhone}`)
        .once("value", (snapshot) => {
          if (snapshot.hasChildren()) {
            let retailerData = snapshot.val();

            let temp_order_history = getOrderHistory;

            let updateToAll = {};
            updateToAll["address1"] = retailerData["block"];
            updateToAll["address2"] = retailerData["address"];
            updateToAll["retailer_name"] = retailerData["name"];
            updateToAll["shop_name"] = retailerData["apartment"];

            let keyList = getItemKeys(driver_id);

            for (let key in keyList[
              order_id.split("-")[0] + order_id.split("-")[1]
            ]) {
              let orderItemKey = `${order_id.split("-")[0]}-${
                order_id.split("-")[1]
              }-${
                keyList[order_id.split("-")[0] + order_id.split("-")[1]][key]
              }`;
              let modified_url = getDB + "/" + driver_id + "/" + orderItemKey;
              fire.database().ref(modified_url).update(updateToAll);
              temp_order_history[driver_id][orderItemKey].address1 =
                updateToAll["address1"];
              temp_order_history[driver_id][orderItemKey].address2 =
                updateToAll["address2"];
              temp_order_history[driver_id][orderItemKey].retailer_name =
                updateToAll["retailer_name"];
              temp_order_history[driver_id][orderItemKey].shop_name =
                updateToAll["shop_name"];
            }

            setAlertType("success");
            setAlertMessage("Retailer updated succesfully");
            setAlertStatus(true);
            setOrderHistory(temp_order_history);
            generateItemSummary(driver_id);
            RenderOrdersDetails(driver_id, order_id);
            setBackDrop(false);
          } else {
            setAlertType("error");
            setAlertMessage("Retailer not present");
            setAlertStatus(true);
          }
        });
    }
  };

  const updateSugarQuantity = (
    driver_id,
    order_id,
    orderKey,
    numSugarBags,
    status
  ) => {
    let modified_url = getDB + "/" + driver_id + "/" + orderKey;

    let temp_order_history = getOrderHistory;

    let freeItemDetails = {};
    if (status.includes("Delivered")) {
      freeItemDetails["item_delivered_qty"] = "" + numSugarBags;
    } else if (status.includes("Shipped")) {
      freeItemDetails["item_modified_qty"] = "" + numSugarBags;
    }

    fire
      .database()
      .ref(modified_url)
      .update(freeItemDetails)
      .then(() => {
        if (status.includes("Delivered")) {
          temp_order_history[driver_id][orderKey]["item_delivered_qty"] =
            "" + numSugarBags;
        } else if (status.includes("Shipped")) {
          temp_order_history[driver_id][orderKey]["item_modified_qty"] =
            "" + numSugarBags;
        }
        setOrderHistory(temp_order_history);
        generateItemSummary(driver_id);
        RenderOrdersDetails(driver_id, order_id);
      });
  };

  // const clearStorageAfterRetrieving = (orderId) => {

  //   if (sessionStorage.getItem(`${orderId}`))
  //     sessionStorage.removeItem(`${orderId}`)

  // }

  const fireBaseItemQuantityRevert = (driver_id, order_id, status) => {
    setBackDrop(true);
    let current_url = getDB + "/" + driver_id + "/" + order_id;

    let temp_order_history = getOrderHistory;

    let data = {};
    if (status.includes("Shipped")) {
      let itemqty = temp_order_history[driver_id][order_id].order_qty;
      temp_order_history[driver_id][order_id].item_modified_qty = itemqty;
      data.item_modified_qty =
        temp_order_history[driver_id][order_id].order_qty;
      data.itemModifiedUpdatedAt = UpdatedDate();
      data.itemModifiedUpdatedBy = usermodifying;
    } else if (status.includes("Delivered")) {
      let itemqty = temp_order_history[driver_id][order_id].item_modified_qty;
      temp_order_history[driver_id][order_id].item_delivered_qty = itemqty;
      data.item_delivered_qty =
        temp_order_history[driver_id][order_id].item_modified_qty;
      data.itemDeliveredUpdatedAt = UpdatedDate();
      data.itemDeliveredUpdatedBy = usermodifying;
    }

    fire.database().ref(current_url).update(data);

    temp_order_history[driver_id][order_id].item_status = "";
    temp_order_history[driver_id][order_id].item_reason = "";

    let updateToAll = {};
    if (
      temp_order_history[driver_id][order_id].status &&
      temp_order_history[driver_id][order_id].status.includes("Shipped")
    ) {
      updateToAll["status"] = "Shipped-Modified";
    }

    let revisedOrderAmt = revisedAmt(
      temp_order_history[driver_id],
      order_id
    ).toFixed(2);
    updateToAll["revised_order_amt"] = revisedOrderAmt;

    for (let key in getOrdersPerShop[
      order_id.split("-")[0] + order_id.split("-")[1]
    ]) {
      let modified_url =
        getDB +
        "/" +
        driver_id +
        "/" +
        order_id.split("-")[0] +
        "-" +
        order_id.split("-")[1] +
        "-" +
        getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][key];
      fire.database().ref(modified_url).update(updateToAll);
      if (
        temp_order_history[driver_id][order_id].status &&
        temp_order_history[driver_id][order_id].status.includes("Shipped")
      ) {
        temp_order_history[driver_id][
          `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
            getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][
              key
            ]
          }`
        ].status = "Shipped-Modified";
      }
      temp_order_history[driver_id][
        `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
          getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][key]
        }`
      ].revised_order_amt = revisedOrderAmt;
    }

    setAlertType("success");
    setAlertMessage("Reverted item quantity ");
    setAlertStatus(true);
    setOrderHistory(temp_order_history);
    generateItemSummary(driver_id);
    RenderOrdersDetails(driver_id, order_id);
    setBackDrop(false);

    let secondPartOfOrderNumber = order_id.split("-")[1];
    let isFirstOrder = secondPartOfOrderNumber == "1000001" ? true : false;
    let couponCode = temp_order_history[driver_id][order_id].coupon_code
      ? temp_order_history[driver_id][order_id].coupon_code
      : "";
    let originalOrderDisc = getOrderHistory[driver_id][order_id].order_disc
      ? Number(getOrderHistory[driver_id][order_id].order_disc)
      : 0;
    if (originalOrderDisc > 0) {
      revisedOrderDiscount(
        driver_id,
        temp_order_history[driver_id],
        order_id,
        updateToAll["revised_order_amt"],
        couponCode,
        true
      );
    }
  };

  const handleReplaceItemDeletion = (
    driver_id,
    order_id,
    ordered_item_id,
    ordered_item_disc,
    status,
    oldItemId,
    orderedItemQty
  ) => {
    let updateShopOrderItemList = [];

    let replacedItem = {
      item_id: Number(ordered_item_id),
      item_disc: ordered_item_disc,
      status: status.toLowerCase() === "shipped" ? "Shipped-Modified" : status,
    };

    if (orderedItemQty) {
      //existing item is reverted
      let temp_order_history = getOrderHistory;
      let oldItemData = JSON.parse(
        JSON.stringify(temp_order_history[driver_id][order_id])
      );
      oldItemData.order_qty = orderedItemQty;
      oldItemData.item_id = replacedItem.item_id;
      oldItemData.item_disc = replacedItem.item_disc;
      oldItemData.status = replacedItem.status;
      oldItemData.replacement_after_delivery = "";

      //update existing item
      let originalOrderQty =
        Number(temp_order_history[driver_id][order_id].order_qty) -
        Number(orderedItemQty);
      originalOrderQty = "" + originalOrderQty;

      //add new item
      let newItemKey =
        order_id.split("-")[0] +
        "-" +
        order_id.split("-")[1] +
        "-" +
        ordered_item_id;
      let modified_url =
        getDB +
        "/" +
        driver_id +
        "/" +
        order_id.split("-")[0] +
        "-" +
        order_id.split("-")[1] +
        "-" +
        ordered_item_id;
      fire
        .database()
        .ref(modified_url)
        .update({ ...oldItemData })
        .then(() => {
          temp_order_history[driver_id][newItemKey] = oldItemData;
          temp_order_history[driver_id][newItemKey].item_id =
            replacedItem.item_id;
          temp_order_history[driver_id][newItemKey].item_disc =
            replacedItem.item_disc;
          temp_order_history[driver_id][newItemKey].status =
            replacedItem.status;
          temp_order_history[driver_id][newItemKey].ordered_item_id = "";
          temp_order_history[driver_id][newItemKey].ordered_item_disc = "";
          temp_order_history[driver_id][newItemKey].new_dealer_price = "";
          temp_order_history[driver_id][newItemKey].ordered_item_qty = "";
          temp_order_history[driver_id][newItemKey].replacement_after_delivery =
            "";

          let updateShopOrderItemList = [];
          for (let key in getOrdersPerShop[
            order_id.split("-")[0] + order_id.split("-")[1]
          ]) {
            let currItemId =
              getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][
                key
              ];
            updateShopOrderItemList.push(currItemId);
          }
          updateShopOrderItemList.push(ordered_item_id);
          let tempShopOrders = getOrdersPerShop;
          tempShopOrders[order_id.split("-")[0] + order_id.split("-")[1]] =
            updateShopOrderItemList;

          setOrdersPerShop(tempShopOrders);
          fire.database().ref(modified_url).child("ordered_item_id").remove();
          fire.database().ref(modified_url).child("ordered_item_disc").remove();
          fire.database().ref(modified_url).child("new_dealer_price").remove();
          fire.database().ref(modified_url).child("ordered_item_qty").remove();
          fire
            .database()
            .ref(modified_url)
            .child("replacement_after_delivery")
            .remove();

          let current_url = getDB + "/" + driver_id + "/" + order_id;
          fire
            .database()
            .ref(current_url)
            .update({ order_qty: originalOrderQty })
            .then(() => {
              temp_order_history[driver_id][order_id].ordered_item_id = "";
              temp_order_history[driver_id][order_id].ordered_item_disc = "";
              temp_order_history[driver_id][order_id].new_dealer_price = "";
              temp_order_history[driver_id][order_id].ordered_item_qty = "";
              temp_order_history[driver_id][order_id].order_qty =
                originalOrderQty;
              fire
                .database()
                .ref(current_url)
                .child("ordered_item_id")
                .remove();
              fire
                .database()
                .ref(current_url)
                .child("ordered_item_disc")
                .remove();
              fire
                .database()
                .ref(current_url)
                .child("new_dealer_price")
                .remove();
              fire
                .database()
                .ref(current_url)
                .child("ordered_item_qty")
                .remove();

              setAlertType("success");
              setAlertMessage("deleted replaced item");
              setAlertStatus(true);

              setOrderHistory(temp_order_history);
              generateItemSummary(driver_id);
              generatePaymentSummary(driver_id);
              RenderOrdersDetails(driver_id, order_id);
              setBackDrop(false);
              setModalModifiedItemQty(false);
            })
            .catch(function (error) {
              setBackDrop(false);
              setAlertMessage("Error occurred in deleting replaced Item");
              generatePaymentSummary(driver_id);
              RenderOrdersDetails(driver_id, order_id);
              setAlertType("error");
              setAlertStatus(true);
              setModalModifiedItemQty(false);
            });
        })
        .catch(function (error) {
          setBackDrop(false);
          setAlertMessage("Error occurred in reverting replaced Item");
          generatePaymentSummary(driver_id);
          RenderOrdersDetails(driver_id, order_id);
          setAlertType("error");
          setAlertStatus(true);
          setModalModifiedItemQty(false);
        });
    } else {
      //new item reverted
      let newItemKey =
        order_id.split("-")[0] +
        "-" +
        order_id.split("-")[1] +
        "-" +
        ordered_item_id;
      let temp_order_history = getOrderHistory;
      let oldItemData = JSON.parse(
        JSON.stringify(temp_order_history[driver_id][order_id])
      );
      oldItemData.item_id = replacedItem.item_id;
      oldItemData.item_disc = replacedItem.item_disc;
      oldItemData.status = replacedItem.status;
      oldItemData.replacement_after_delivery = "";

      let current_url = getDB + "/" + driver_id + "/" + order_id;
      fire.database().ref(current_url).remove();
      delete temp_order_history[driver_id][order_id];

      let modified_url =
        getDB +
        "/" +
        driver_id +
        "/" +
        order_id.split("-")[0] +
        "-" +
        order_id.split("-")[1] +
        "-" +
        ordered_item_id;

      fire
        .database()
        .ref(modified_url)
        .update({ ...oldItemData })
        .then(() => {
          let temp_order_history = getOrderHistory;
          temp_order_history[driver_id][newItemKey] = oldItemData;
          temp_order_history[driver_id][newItemKey].item_id =
            replacedItem.item_id;
          temp_order_history[driver_id][newItemKey].item_disc =
            replacedItem.item_disc;
          temp_order_history[driver_id][newItemKey].status =
            replacedItem.status;
          temp_order_history[driver_id][newItemKey].ordered_item_id = "";
          temp_order_history[driver_id][newItemKey].ordered_item_disc = "";
          temp_order_history[driver_id][newItemKey].new_dealer_price = "";
          temp_order_history[driver_id][newItemKey].replacement_after_delivery =
            "";
          setOrderHistory(temp_order_history);

          let updateShopOrderItemList = [];
          for (let key in getOrdersPerShop[
            order_id.split("-")[0] + order_id.split("-")[1]
          ]) {
            let currItemId =
              getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][
                key
              ];
            if (currItemId == oldItemId) {
              updateShopOrderItemList.push(ordered_item_id);
            } else {
              updateShopOrderItemList.push(currItemId);
            }
          }
          let tempShopOrders = getOrdersPerShop;
          tempShopOrders[order_id.split("-")[0] + order_id.split("-")[1]] =
            updateShopOrderItemList;

          setOrdersPerShop(tempShopOrders);

          setAlertType("success");
          setAlertMessage("deleted replaced item");
          setAlertStatus(true);

          setOrderHistory(temp_order_history);
          generateItemSummary(driver_id);
          generatePaymentSummary(driver_id);
          RenderOrdersDetails(driver_id, newItemKey);
          setBackDrop(false);
          setModalModifiedItemQty(false);
          fire.database().ref(modified_url).child("ordered_item_id").remove();
          fire.database().ref(modified_url).child("ordered_item_disc").remove();
          fire.database().ref(modified_url).child("new_dealer_price").remove();
          fire
            .database()
            .ref(modified_url)
            .child("replacement_after_delivery")
            .remove();
        })
        .catch(function (error) {
          setBackDrop(false);
          setAlertMessage("Error occurred in deleting replaced Item");
          generatePaymentSummary(driver_id);
          RenderOrdersDetails(driver_id, newItemKey);
          setAlertType("error");
          setAlertStatus(true);
          setModalModifiedItemQty(false);
        });

      setTimeout(function () {
        if (getBackDrop) {
          setBackDrop(false);
        }
      }, 3000);
    }
  };

  // const filterOrdersByCity = (cityValue) => {
  //   if (cityValue == "All") {
  //     setOrderHistory(getBackupOrderHistory);
  //   } else {
  //     let updatedDriverOrdersObj = {};
  //
  //     Object.keys(getBackupOrderHistory).map((rkey, rindex) => {
  //       let routeOrdersObj = getBackupOrderHistory[rkey];
  //
  //       let vehicleOrdersObj = {};
  //
  //       Object.keys(routeOrdersObj).map((okey, oindex) => {
  //         let ordersObj = routeOrdersObj[okey];
  //
  //         let city = routeOrdersObj[okey]["city"] || "";
  //         city = convertCity(city);
  //
  //         if (city == cityValue) {
  //           vehicleOrdersObj[okey] = ordersObj;
  //         }
  //       });
  //
  //       if (Object.keys(vehicleOrdersObj).length > 0) {
  //         updatedDriverOrdersObj[rkey] = vehicleOrdersObj;
  //       }
  //     });
  //
  //     setOrderHistory(updatedDriverOrdersObj);
  //   }
  //
  //   setBackDrop(false);
  // };

  const RenderWHDropdown = () => {
    // let whList = [
    //   "All",
    //   "Bhiwandi",
    //   "Virar",
    //   "Mira Road",
    //   "Vashi",
    //   "Wadala",
    //   "Goa"
    // ];
    // let whList = warehouseNames;
    const [whList,setWHlist] = useState([])

    useEffect(() => {
      warehouses
        .then(data => {
          console.log("Fetched Warehouses:", data);
          const allWarehouses = [
            "All",
             ...data.map(obj => (obj.warehouse))
           ];
          setWHlist(allWarehouses);
          console.log("All warehouses:", allWarehouses);
        })
        .catch(error => {
          console.error("Failed to fetch warehouses:", error);
        });
    }, []);

    let whDropdown = [];

    for (let i = 0; i < whList.length; i++) {
      whDropdown.push(
        <Dropdown.Item
          name={whList[i]}
          onClick={(e) => {
            e.preventDefault();
            sessionStorage.removeItem("key");
            sessionStorage.removeItem("filter");
            sessionStorage.setItem("warehouse", e.target.name);
            setWarehouse(e.target.name);
            setFilter(null);
            setDownloadOrders(true);
            setPrimaryDropDownMessage("Select Driver");
            setBackDrop(true);
            setBulkFilterStatus("");
          }}
        >
          {whList[i]}
        </Dropdown.Item>
      );
    }
    return whDropdown;
  };

    const RenderDrivers = (current_active) => {
      let userCity = sessionStorage.getItem("userCity") || "";
      let updatedRouteOrdersObj = {};
      let temp = [];
      let tempMoveRoute = [];
      Object.keys(getOrderHistory).forEach((driver) => {
        let vehicleOrders = getOrderHistory[driver] || {};
        let isCityOrderPresent = false;
        let cityRouteOrders = {};

        if (
          validateIsAdminUser(
            JSON.parse(sessionStorage.getItem(`user`))[0].email
          ) ||
          (!validateIsAdminUser(
            JSON.parse(sessionStorage.getItem(`user`))[0].email
          ) &&
            driver != "9998" &&
            driver != "9999")
        ) {
          Object.keys(vehicleOrders).map((key, index) => {
            let city = vehicleOrders[key]["city"] || "";

            if (
              validateIsAdminUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              )
            ) {
              if (
                (!userCity &&
                  (!warehouse || warehouse == "All" || city == warehouse)) ||
                (userCity && userCity == city)
              ) {
                isCityOrderPresent = true;
                cityRouteOrders[key] = vehicleOrders[key] || {};
              }
            } else if (
              (!userCity &&
                (!warehouse || warehouse == "All" || city == warehouse)) ||
              (userCity && userCity == city)
            ) {
              isCityOrderPresent = true;
              cityRouteOrders[key] = vehicleOrders[key] || {};
            }
          });
        }

        if (isCityOrderPresent) {
          let driverRegionId = String(driver).substring(0, 2);
          if (
            driver == "9999" ||
            driver == "9998" ||
            driver == "8989" ||
            driver == "8888" ||
            driver == "8889"
          ) {
            driverRegionId = driver;
          }
          temp.push(
            <Dropdown.Item
              name={driver}
              active={driver == current_active}
              onClick={async (e) => {
                e.preventDefault();
                setPrimaryDropDownMessage(e.target.name);
                setBackDrop(true);
                setDispatchedVehicleFlag(false);
                setDispatchedVehiclePopupOpen(false);
                setDispatchedVehicleSwitchChecked(false);
                setBulkFilterStatus("");
                RenderOrders(e.target.name);
                setBreadCrumb([e.target.name]);
              }}
            >
              {driver} - {driverRegionNameMap[driverRegionId] || ""}
            </Dropdown.Item>
          );
        
          tempMoveRoute.push({
            value: driver,
            label: `${driver} - ${driverRegionNameMap[driverRegionId] || ""}`
          });
          updatedRouteOrdersObj[driver] = cityRouteOrders;
        }
      });

      setRenderDriver([
        <InputGroup size="sm">
          <InputGroupAddon size="sm" addonType="prepend">
            Route
          </InputGroupAddon>
          <Dropdown style={{ display: "inline" }}>
            <Dropdown.Toggle variant="primary" id="dropdown-basic" size="sm">
              {getPrimaryDropDownMessage}
            </Dropdown.Toggle>
            <Dropdown.Menu>{temp}</Dropdown.Menu>
          </Dropdown>
        </InputGroup>,
      ]);
   
      setMoveDriverOptions([
        { value: 'Select Route', label: 'Select Route' },
        ...tempMoveRoute
      ]);
        // to show default value in source route
    setRouteToMoveSelected('Select Route');

      let temp_data = JSON.parse(sessionStorage.getItem("key"));
      let temp_filter = JSON.parse(sessionStorage.getItem("filter"));
      let vehicleReloadedRoute = sessionStorage.getItem("vehicleReloaded");

      if (temp_data) { 
        console.log("Inside if");
        RenderOrders(temp_data.driver_id);
        setBreadCrumb([temp_data.driver_id]);
        setBreadCrumb2(temp_data.order_id);
      } else if (temp_filter) {
        console.log("Inside else if");
        RenderOrders(temp_filter.driver_id);
        setBreadCrumb([temp_filter.driver_id]);
      } else if (vehicleReloadedRoute) {
        RenderOrders(vehicleReloadedRoute);
        setBreadCrumb([vehicleReloadedRoute]);
      } else {
        //but ensure data is available and this else block is called only once on load

        if (Object.keys(updatedRouteOrdersObj).length > 0 && getOnce) {
          setBreadCrumb([Object.keys(updatedRouteOrdersObj)[0]]);
          RenderOrders(Object.keys(updatedRouteOrdersObj)[0]);
          setPrimaryDropDownMessage(Object.keys(updatedRouteOrdersObj)[0]);
        }

        setOnce(false);
        setBackDrop(false);
      }
    };


  const moveDOHData = async (sourceId, destinationId) => {

    if (sourceId === destinationId) {
      toast('Source and destination route cannot be the same.', { type: toast.TYPE.ERROR });
      return; 
    }
    let selectedInputDate = sessionStorage.getItem("selectedInputDate");
    const sourcePath = `/Driver_Orders_History/${selectedInputDate.split("-")[2]}/${selectedInputDate.split("-")[1]}/${selectedInputDate}/${sourceId}`;
    const destinationPath = `/Driver_Orders_History/${selectedInputDate.split("-")[2]}/${selectedInputDate.split("-")[1]}/${selectedInputDate}/${destinationId}`;
  
    try {
      // Fetch the data from the source node
      const snapshot = await fire.database().ref(sourcePath).once('value');
      const data = snapshot.val();
  
      if (data) {
        // Update driver_id in the fetched data
        let updatedData = { ...data };
        Object.keys(data).forEach((key) => {
          updatedData[key].driver_id = destinationId;
        });
  
        
        // update(add to existing collection) the data at the destination path
        await fire.database().ref(destinationPath).update(updatedData);
  
        // Remove the original data from the source path
        await fire.database().ref(sourcePath).remove();
  
        // console.log("Data moved successfully!");
        toast(`Data moved successfully! from ${sourceId} to ${destinationId} route`, { type: toast.TYPE.SUCCESS });
        if (getRouteToMoveSelected != "Select Route") {
          RenderDrivers();  
        }
          
        setTimeout(() => {
          window.location.reload();  // to reflect latest changes after data is moved
      }, 4000);
              
      } else {
        toast(`Data NOT moved from ${sourceId} to ${destinationId} route`, { type: toast.TYPE.ERROR });
        // console.log("No data found at source path.");
      }
    } catch (error) {
      // console.error("Error moving data:", error);
      toast(`Data NOT moved from ${sourceId} to ${destinationId} route`, { type: toast.TYPE.ERROR });
    }
  };

  useEffect(() => {
    if (getPrimaryDropDownMessage != "Select Driver") RenderDrivers();
  }, [getPrimaryDropDownMessage]);

  useEffect(() => {
    if (getOrderHistory) {
      //ensure this is called only once
      // if(validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))){
      //   RenderCityDropdown()
      // }

      RenderDrivers();
    }
  }, [getOrderHistory]);

  //refresh page
  function applyFilter(filter) {
    sessionStorage.setItem(
      "filter",
      JSON.stringify({
        db_url: getDB,
        driver_id: getBreadCrumb[0],
        current_date: getDate.toString(),
        db_data: getOrderHistory,
        filter: filter,
      })
    );
    sessionStorage.setItem("currentDate", JSON.stringify(currentDate));
    sessionStorage.setItem("filterdate", currentDate);
    window.location.reload(false);
  }

  const pageDispatchVehicleUpdate = (dispatchRoute) => {
    console.log("pageDispatchVehicleUpdate");
    let isVehicleDispatched = opsDispatchedVehicleFlag;
    isVehicleDispatched[dispatchRoute] = true;

    setDispatchedVehicleSwitchChecked(true);
    window.location.reload();
  };

  useEffect(() => {
    if (getBreadCrumb[0] != undefined && getBreadCrumb[0] != null) {
      RenderDrivers(getBreadCrumb[0]);
      setPaymentButton([
        <div style={{ marginRight: "10px", float: "right" }}>
          <Button
            size="sm"
            style={{ marginRight: "10px", float: "right" }}
            onClick={(e) => {
              e.preventDefault();
              setPaymentSummaryVisibility(true);
            }}
          >
            Payment Details
          </Button>
          {/* {getBreadCrumb[0]!="8888" && getBreadCrumb[0]!="9999" && (validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) || validateIsOperationUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))) && <div style={{ marginRight: '10px', float: 'right' }}>
                Dispatched Vehicle? <Switch onChange={(val) => { setDispatchedVehiclePopupOpen(val); }} checked={dispatchedVehicleSwitchChecked?dispatchedVehicleSwitchChecked:opsDispatchedVehicleFlag[getBreadCrumb[0]]} disabled={(validateIsOperationUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) && !dispatchedVehicleFlag)?true:false} />
              </div>} */}
          <Button
            size="sm"
            style={{ marginRight: "10px", float: "right" }}
            onClick={(e) => {
              e.preventDefault();
              fetchBagIdForSmallPackItems();
            }}
          >
            Item Details
          </Button>
        </div>,
      ]);

      let temp_filter = getFilter;

      setFilterButton([
        <div
          style={{ marginRight: "10px", float: "right", marginLeft: "10px" }}
        >
          <InputGroup size="sm">
            <InputGroupAddon addonType="prepend">
              {temp_filter == null || temp_filter == ""
                ? "No Filter"
                : temp_filter == "partial"
                ? "Partially Delivered"
                : temp_filter == "cancel order"
                ? "Cancelled"
                : temp_filter == "shipped-modified"
                ? "Shipped Modified"
                : temp_filter[0].toUpperCase() + temp_filter.substr(1)}
            </InputGroupAddon>
            <DropdownButton
              size="sm"
              as={ButtonGroup}
              key={"right"}
              drop={"right"}
              variant={"outline-primary"}
              title={"Filter"}
            >
              <Dropdown.Item
                eventKey="1"
                onClick={(e) => {
                  e.preventDefault();
                  applyFilter("delivered");
                }}
                active={temp_filter == "delivered" ? true : false}
              >
                Delivered
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                onClick={(e) => {
                  e.preventDefault();
                  applyFilter("partial");
                }}
                active={temp_filter == "partial" ? true : false}
              >
                Partial Delivered
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="3"
                onClick={(e) => {
                  e.preventDefault();
                  applyFilter("cancel order");
                }}
                active={temp_filter == "cancel order" ? true : false}
              >
                Cancelled
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="4"
                onClick={(e) => {
                  e.preventDefault();
                  applyFilter("pending");
                }}
                active={temp_filter == "pending" ? true : false}
              >
                Pending
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="5"
                onClick={(e) => {
                  e.preventDefault();
                  applyFilter("hold");
                }}
                active={temp_filter == "hold" ? true : false}
              >
                Hold
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="6"
                onClick={(e) => {
                  e.preventDefault();
                  applyFilter("processed");
                }}
                active={temp_filter == "processed" ? true : false}
              >
                Processed
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="7"
                onClick={(e) => {
                  e.preventDefault();
                  applyFilter("shipped");
                }}
                active={temp_filter == "shipped" ? true : false}
              >
                Shipped
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="8"
                onClick={(e) => {
                  e.preventDefault();
                  applyFilter("shipped-modified");
                }}
                active={temp_filter == "shipped-modified" ? true : false}
              >
                Shipped Modified
              </Dropdown.Item>

              <Dropdown.Divider />
              <Dropdown.Item
                eventKey="9"
                onClick={(e) => {
                  e.preventDefault();
                  applyFilter("");
                }}
                active={temp_filter == null || temp_filter == "" ? true : false}
              >
                Reset
              </Dropdown.Item>
            </DropdownButton>
          </InputGroup>
        </div>,
      ]);
    } else {
      setFilterButton([]);
      setPaymentButton([]);
    }
  }, [getBreadCrumb]);

  const generateAlert = (message, type) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertStatus(true);
  };

  const bulkFilterStatusChange = () => {
    return (
      <div style={{ marginRight: "10px", float: "right" }}>
        <InputGroup size="sm">
          <DropdownButton
            size="sm"
            as={ButtonGroup}
            key={"right"}
            drop={"right"}
            variant={"outline-primary"}
            title={bulkFilterStatus ? bulkFilterStatus : "Vehicle Status"}
          >
            <Dropdown.Item
              eventKey="5"
              onClick={(e) => {
                if (
                  window.confirm(
                    `Do you want to update the statuses of all the ${getBreadCrumb[0]} - orders to Hold`
                  )
                ) {
                  setBulkFilterStatus("Hold");
                }
              }}
              active={bulkFilterStatus == "Hold" ? true : false}
            >
              Hold
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="5"
              onClick={(e) => {
                if (
                  window.confirm(
                    `Do you want to update the statuses of all the ${getBreadCrumb[0]} - orders to Shipped`
                  )
                ) {
                  setBulkFilterStatus("Shipped");
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                }
              }}
              active={bulkFilterStatus == "Shipped" ? true : false}
            >
              Shipped
            </Dropdown.Item>
          </DropdownButton>
        </InputGroup>
      </div>
    );
  };

  const pushBulkStatusUpdate = () => {
    setBulkUpdationConfirm(false);

    setChangeBulkStatus(true);
  };
  useEffect(() => {
    if (bulkFilterStatus) pushBulkStatusUpdate();
  }, [bulkFilterStatus]);

  useEffect(() => {
    if (changeBulkStatus) {
      let isHoldDbUpdate = false;
      if (bulkFilterStatus == "Hold") {
        let routeCity = "";
        Object.keys(getOrderHistory[getBreadCrumb[0]]).forEach((orderId) => {
          routeCity = getOrderHistory[getBreadCrumb[0]][orderId]["city"];
        });

        if (routeCity != "Bhiwandi" && routeCity) {
          isHoldDbUpdate = true;
          updateHoldAfterTransfer(getBreadCrumb[0]);
        }
      }

      Object.keys(getOrderHistory[getBreadCrumb[0]]).forEach((orderId) => {
        if (bulkFilterStatus == "Shipped") {
          if (
            getOrderHistory[getBreadCrumb[0]][orderId]["status"] == "Processed" ||
            getOrderHistory[getBreadCrumb[0]][orderId]["status"] == "Hold" 
          ) {
            fire
              .database()
              .ref(`${getDB}/${getBreadCrumb[0]}/${orderId}`)
              .update({ status: bulkFilterStatus })
              .then(() => {
                generateAlert(
                  `orders status bulk updated succesfully for vehcile ${getBreadCrumb[0]}`,
                  `success`
                );
              });
          }
        } else {
          if (
            getOrderHistory[getBreadCrumb[0]][orderId].hasOwnProperty(
              "status"
            ) &&
            (getOrderHistory[getBreadCrumb[0]][orderId]["status"] ==
              "Processed" ||
              getOrderHistory[getBreadCrumb[0]][orderId]["status"].includes(
                "Shipped"
              ))
          ) {
            fire
              .database()
              .ref(`${getDB}/${getBreadCrumb[0]}/${orderId}`)
              .update({ status: bulkFilterStatus })
              .then(() => {
                generateAlert(
                  `orders status bulk updated succesfully for vehicle ${getBreadCrumb[0]}`,
                  `success`
                );
              });
          }
        }
      });

      if (!isHoldDbUpdate) {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
  }, [changeBulkStatus]);

  function formatDeliveryDate(dt) {
    let dtlist = dt.split("-");
    let datee = dtlist[0];
    let month = dtlist[1];
    let year = dtlist[2];

    if (Number(datee) < 10) {
      datee = "0" + datee;
    }

    if (Number(month) < 10) {
      month = "0" + month;
    }

    return `${year}-${month}-${datee}`;
  }

  const updateHoldAfterTransfer = (route) => {
    let details = {};
    details["route"] = route;
    details["delivery_date"] = formatDeliveryDate(
      sessionStorage.getItem("selectedInputDate")
    );

    fetch(`${service_url}/hold-after-transfer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("apiToken"),
      },
      body: JSON.stringify(details),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        if (result["success"] != 1) {
          let errMessage = result["message"] ? result["message"] : "";
          alert("Error in updating hold after transfer : " + errMessage);
        } else {
          generateAlert(`updated hold after transfer`, `success`);

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((err) => {
        alert("Error in updating hold after transfer:" + err);
      });
  };

  const processExportConfirm = () => {
    console.log(JSON.parse(sessionStorage.getItem(`user`))[0].email);

    if (
      validateIsExportUser(
        JSON.parse(sessionStorage.getItem(`user`))[0].email
      ) && ! validateIsBillingUser(
        JSON.parse(sessionStorage.getItem(`user`))[0].email
      )
    ) {
      setDataDownload(true);
      return;
    }

    // if net_order_amt != sum(total_amt) - order_disc - cashback_redeemed

    let issueInExport = false;
    let orderSuperKreditObj = {};
    let orderValueCheckerObj = {};
    let routeIssues = "";

    Object.keys(getOrderHistory).forEach((routeKey) => {
      if (opsDispatchedVehicleFlag[routeKey]) {
        if (!routeOrdersConsolidationFlag[routeKey] && routeKey != "9998") {
          routeIssues += `${routeKey},`;
          issueInExport = true;
          return;
        }
      }

      Object.keys(getOrderHistory[routeKey]).forEach((orderKey) => {
        let ordered_amt = getOrderHistory[routeKey][orderKey]["total_amt"]
          ? Number(getOrderHistory[routeKey][orderKey]["total_amt"])
          : 0;
        let delivered_amt = revisedAmt(getOrderHistory[routeKey], orderKey);
        let order_number = getOrderHistory[routeKey][orderKey]["order_number"];
        let credit_amt = getOrderHistory[routeKey][orderKey]["credit_amt"]
          ? Number(getOrderHistory[routeKey][orderKey]["credit_amt"])
          : 0;

        let itemID = getOrderHistory[routeKey][orderKey]["item_id"];
        let mrp = getOrderHistory[routeKey][orderKey]["mrp"]
          ? Number(getOrderHistory[routeKey][orderKey]["mrp"])
          : 0;
        let order_disc = getOrderHistory[routeKey][orderKey]["order_disc"]
          ? Number(getOrderHistory[routeKey][orderKey]["order_disc"])
          : 0;
        let cashback_redeemed = getOrderHistory[routeKey][orderKey][
          "cashback_redeemed"
        ]
          ? Number(getOrderHistory[routeKey][orderKey]["cashback_redeemed"])
          : 0;

        let orderele = {};
        orderele["order_number"] = order_number;
        orderele["route"] = routeKey;
        orderele["orderkey"] = orderKey;
        orderele["ordered_amt"] = ordered_amt;
        orderValueCheckerObj[order_number] = orderele;
        if (
          getOrderHistory[routeKey][orderKey].hasOwnProperty("status") &&
          (!userCity || getOrderHistory[routeKey][orderKey]["city"] == userCity)
        ) {
          if (
            getOrderHistory[routeKey][orderKey]["status"] == "Ordered" ||
            getOrderHistory[routeKey][orderKey]["status"] == "Processed" ||
            (!vehicleNotReturnedMap[routeKey] &&
              getOrderHistory[routeKey][orderKey]["status"].includes("Shipped"))
          ) {
            generateAlert(
              `Data can't be exported when order no.${getOrderHistory[routeKey][orderKey].order_number} | ${getOrderHistory[routeKey][orderKey].driver_id} is in Ordered or Processed or Shipped state`,
              `error`
            );
            issueInExport = true;
            return;
          }
        }

        if (
          getOrderHistory[routeKey][orderKey].hasOwnProperty(
            "payment_details"
          ) &&
          getOrderHistory[routeKey][orderKey]["status"].includes("Delivered")
        ) {
          if (
            revisedAmt(getOrderHistory[routeKey], orderKey).toFixed(2) -
              getOrderHistory[routeKey][orderKey]["payment_details"][
                "total_amount_paid"
              ] >
            10
          ) {
            generateAlert(
              `Discrepancy in the payments. Can't export order ${getOrderHistory[routeKey][orderKey].order_number} | ${getOrderHistory[routeKey][orderKey].driver_id}`,
              `error`
            );
            issueInExport = true;
            return;
          }
        }
      });
    });
    console.log(orderSuperKreditObj);

    console.log(orderValueCheckerObj);
    console.log("orderValueCheckerObj");

    Object.keys(orderValueCheckerObj).map((key, index) => {
      let orderkey = orderValueCheckerObj[key].orderkey;
      let route = orderValueCheckerObj[key].route;
      let order_number = orderValueCheckerObj[key].order_number;
      let city = orderValueCheckerObj[key].city;
      let status = getOrderHistory[route][orderkey].status
        ? getOrderHistory[route][orderkey].status
        : "";

      let totalAmountToBePaid = calculateTotalAmountToBePaid(
        getOrderHistory[route],
        orderkey
      );
      console.log(totalAmountToBePaid);

      let totalOrderAmountValue = orderValueCheckerObj[key].ordered_amt;
      let diffInTotalAmt =
        Number(totalOrderAmountValue) - Number(totalAmountToBePaid);

      // revised_order_disc correction
      let originalRevisedOrderrDiscount = getOrderHistory[route][orderkey]
        .revised_order_disc
        ? Number(getOrderHistory[route][orderkey].revised_order_disc)
        : 0;
      let calcRevisedOrderDisc = 0;

      let couponCodeValue = getOrderHistory[route][orderkey].coupon_code
        ? getOrderHistory[route][orderkey].coupon_code
        : "";
      let originalOrderDisc = getOrderHistory[route][orderkey].order_disc
        ? Number(getOrderHistory[route][orderkey].order_disc)
        : 0;
      if (originalOrderDisc > 0) {
        calcRevisedOrderDisc = revisedOrderDiscount(
          route,
          getOrderHistory[route],
          orderkey,
          getOrderHistory[route][orderkey]["revised_order_amt"],
          couponCodeValue,
          false
        );
      }

      calcRevisedOrderDisc = calcRevisedOrderDisc
        ? Number(calcRevisedOrderDisc)
        : 0;
      let diffInRevisedOrderDisc =
        originalRevisedOrderrDiscount - calcRevisedOrderDisc;

      let originalRevisedOrderAmt = getOrderHistory[route][orderkey]
        .revised_order_amt
        ? Number(getOrderHistory[route][orderkey].revised_order_amt)
        : 0;
      let calcRevisedOrderAmt = revisedAmt(
        getOrderHistory[route],
        orderkey
      ).toFixed(2);
      calcRevisedOrderAmt = calcRevisedOrderAmt
        ? Number(calcRevisedOrderAmt)
        : 0;
      let diffInRevisedOrderAmt = originalRevisedOrderAmt - calcRevisedOrderAmt;

      if (
        ((!order_number.includes("R") && status == "Partially-Delivered") ||
          (status.includes("Delivered") && order_number.includes("R"))) &&
        route != "9999" &&
        route != "9998" &&
        diffInRevisedOrderAmt != 0 &&
        city &&
        city.includes(userCity) &&
        getOrderHistory[route][orderkey].revised_order_amt
      ) {
        generateAlert(
          `${route} : ${order_number} has revised order amount discrepancy with difference of ${diffInRevisedOrderAmt.toFixed(
            2
          )}`,
          `error`
        );
        issueInExport = true;
        return;
      }

      if (
        ((!order_number.includes("R") &&
          (status == "Partially-Delivered" || status == "Shipped-Modified")) ||
          (status.includes("Delivered") && order_number.includes("R"))) &&
        route != "9999" &&
        route != "9998" &&
        diffInRevisedOrderDisc != 0 &&
        city &&
        city.includes(userCity) &&
        getOrderHistory[route][orderkey].revised_order_disc
      ) {
        generateAlert(
          `${route} : ${order_number} has revised order discount discrepancy with difference of ${diffInRevisedOrderDisc.toFixed(
            2
          )}`,
          `error`
        );
        issueInExport = true;
        return;
      }

      if (
        (!order_number.includes("R") ||
          (status.includes("Delivered") && order_number.includes("R"))) &&
        route != "9999" &&
        route != "9998" &&
        city &&
        city.includes(userCity) &&
        (diffInTotalAmt > 10 || diffInTotalAmt < -10)
      ) {
        generateAlert(
          `${route} : ${order_number} has Order Value discrepancy with difference of ${diffInTotalAmt.toFixed(
            2
          )}`,
          `error`
        );
        issueInExport = true;
        return;
      }
    });

    if (routeIssues.length > 0) {
      routeIssues = routeIssues.slice(0, -1);
      generateAlert(
        `Data can't be exported when order consolidation not done for route ${routeIssues}`,
        `error`
      );
      return;
    }

    if (issueInExport == true) {
      return;
    }

    if (
      validateIsOperationUser(
        JSON.parse(sessionStorage.getItem(`user`))[0].email
      )
    ) {
      let dispatchVehicleIssues = "";
      Object.keys(getOrderHistory).forEach((routeKey) => {
        if (
          !opsDispatchedVehicleFlag[routeKey] &&
          routeKey != "9999" &&
          routeKey != "9998"
        ) {
          dispatchVehicleIssues += `${routeKey},`;
          return;
        }
      });

      if (dispatchVehicleIssues.length > 0) {
        dispatchVehicleIssues = dispatchVehicleIssues.slice(0, -1);
        generateAlert(
          `Data can't be exported when vehicle not dispatched for route ${dispatchVehicleIssues}`,
          `error`
        );
        return;
      } else {
        setDataDownload(true);
        // setShowFlag(true)
        return;
      }
    }

    if (!issueInExport) setConfirmPopupShow(true);
  };
  console.log(getDate);

  const firebaseSuperkreditFlagUpdate = (
    updatedOrderNumberStr,
    updatedOrderAmountStr
  ) => {
    console.log("firebaseSuperkreditFlagUpdate");
    console.log(getDate);

    let selecteddDate = new Date(getDate);
    let year = selecteddDate.getFullYear();
    let month = selecteddDate.getMonth() + 1;
    let date = selecteddDate.getDate();

    let newSelectedDate = `${date}-${month}-${year}`;

    let enddeliveryele = {};
    fire
      .database()
      .ref(`Driver_Allocation_History/${year}/${month}/${newSelectedDate}`)
      .once("value", (snapshot) => {
        if (snapshot.hasChildren()) {
          let driversData = snapshot.val();

          Object.keys(driversData).map((key, value) => {
            enddeliveryele[`/${key}/superkredit_flag`] = true;
            enddeliveryele[`/${key}/superkredit_orders`] =
              updatedOrderNumberStr;
            enddeliveryele[`/${key}/superkredit_amt_added`] =
              updatedOrderAmountStr;
          });
          console.log("enddeliveryele");
          console.log(enddeliveryele);

          if (Object.keys(enddeliveryele).length > 0) {
            fire
              .database()
              .ref(
                `Driver_Allocation_History/${year}/${month}/${newSelectedDate}`
              )
              .update(enddeliveryele)
              .then(() => {
                setAlertType("success");
                setAlertMessage("Updated SuperKredit value successfully");
                setAlertStatus(true);
                setSuperKreditEndFlag(true);
              });
          }
        }
      });
  };

  const firebaseCashbackFlagUpdate = (
    updatedOrderNumberStr,
    updatedOrderAmountStr
  ) => {
    console.log("firebaseCashbackFlagUpdate");

    let selecteddDate = new Date(getDate);
    let year = selecteddDate.getFullYear();
    let month = selecteddDate.getMonth() + 1;
    let date = selecteddDate.getDate();

    let newSelectedDate = `${date}-${month}-${year}`;

    let enddeliveryele = {};
    fire
      .database()
      .ref(`Driver_Allocation_History/${year}/${month}/${newSelectedDate}`)
      .once("value", (snapshot) => {
        if (snapshot.hasChildren()) {
          let driversData = snapshot.val();

          Object.keys(driversData).map((key, value) => {
            enddeliveryele[`/${key}/cashback_flag`] = true;
            enddeliveryele[`/${key}/cashback_orders`] = updatedOrderNumberStr;
            enddeliveryele[`/${key}/cashback_amt_added`] =
              updatedOrderAmountStr;
          });
          console.log("enddeliveryele");
          console.log(enddeliveryele);

          if (Object.keys(enddeliveryele).length > 0) {
            fire
              .database()
              .ref(
                `Driver_Allocation_History/${year}/${month}/${newSelectedDate}`
              )
              .update(enddeliveryele)
              .then(() => {
                setAlertType("success");
                setAlertMessage("Processed cashback successfully");
                setAlertStatus(true);
                setCashbackEndFlag(true);
              });
          }
        }
      });
  };

  const processSuperKreditOrders = () => {
    console.log("processSuperKreditOrders");
    let issueInExport = false;

    let currDeliveryDate = getDB;
    let currDeliveryDateList = currDeliveryDate.split("/");
    let finalDeliveryDate =
      currDeliveryDateList[currDeliveryDateList.length - 1];
    //1-12-2021
    let finalDeliveryDateList = finalDeliveryDate.split("-");
    let year = finalDeliveryDateList[2];
    let month = Number(finalDeliveryDateList[1]);
    let datte = Number(finalDeliveryDateList[0]);
    datte = datte < 10 ? "0" + datte : datte;
    month = month < 10 ? "0" + month : month;
    let formattedDeliveryDate = `${year}-${month}-${datte}`;
    console.log("formattedDeliveryDate:: " + formattedDeliveryDate);

    let orderSuperKreditObj = {};
    let internalSuperKreditObj = {};
    Object.keys(getOrderHistory).forEach((routeKey) => {
      Object.keys(getOrderHistory[routeKey]).forEach((orderKey) => {
        let ordered_amt = getOrderHistory[routeKey][orderKey]["total_amt"]
          ? Number(getOrderHistory[routeKey][orderKey]["total_amt"])
          : 0;
        let delivered_amt = revisedAmt(getOrderHistory[routeKey], orderKey);
        let order_number = getOrderHistory[routeKey][orderKey]["order_number"];
        let credit_amt = getOrderHistory[routeKey][orderKey]["credit_amt"]
          ? Number(getOrderHistory[routeKey][orderKey]["credit_amt"])
          : 0;
        let order_status = getOrderHistory[routeKey][orderKey]["status"];

        if (order_status == "Cancelled") {
          delivered_amt = 0;
        }

        let paymentDiff = credit_amt - delivered_amt;

        let paymentDets = {};
        if (
          getOrderHistory[routeKey][orderKey]["order_payment_mode"] ==
            "Internal_Credit" &&
          (order_status == "Cancelled" || order_status.includes("Delivered"))
        ) {
          if ("payment_details" in getOrderHistory[routeKey][orderKey]) {
            paymentDets =
              getOrderHistory[routeKey][orderKey]["payment_details"];

            let paymentMode = "";

            Object.keys(paymentDets).map((pkey, pindex) => {
              if (pkey != "total_amt_paid") {
                paymentMode = paymentDets[pkey]["payment_mode"]
                  ? paymentDets[pkey]["payment_mode"]
                  : "";
                if (paymentMode == "internalsuperkredit") {
                  let kreditele = {};
                  kreditele["delivery_date"] = formattedDeliveryDate;
                  kreditele["cheque_number"] = paymentDets[pkey][
                    "cheque1_number"
                  ]
                    ? paymentDets[pkey]["cheque1_number"]
                    : "";
                  kreditele["cheque_date"] = paymentDets[pkey]["cheque1_date"]
                    ? paymentDets[pkey]["cheque1_date"]
                    : "";
                  kreditele["cheque_amt"] = paymentDets[pkey]["cheque1_amount"]
                    ? paymentDets[pkey]["cheque1_amount"]
                    : "";
                  //cheque_number2, cheque_date2, cheque_amt2
                  if (paymentDets[pkey]["cheque2_amount"]) {
                    kreditele["cheque_number2"] = paymentDets[pkey][
                      "cheque2_number"
                    ]
                      ? paymentDets[pkey]["cheque2_number"]
                      : "";
                    kreditele["cheque_date2"] = paymentDets[pkey][
                      "cheque2_date"
                    ]
                      ? paymentDets[pkey]["cheque2_date"]
                      : "";
                    kreditele["cheque_amt2"] = paymentDets[pkey][
                      "cheque2_amount"
                    ]
                      ? paymentDets[pkey]["cheque2_amount"]
                      : "";
                  }
                  kreditele["orderKey"] = orderKey;
                  kreditele["phone"] =
                    getOrderHistory[routeKey][orderKey]["phone"];
                  kreditele["payment_diff"] = paymentDiff.toFixed(2);

                  kreditele["ordered_amt"] = ordered_amt;
                  kreditele["delivered_amt"] = delivered_amt.toFixed(2);
                  kreditele["credit_amt"] = credit_amt;
                  kreditele["status"] =
                    getOrderHistory[routeKey][orderKey]["status"];

                  kreditele["retailer_id"] =
                    getOrderHistory[routeKey][orderKey]["retailer_id"];
                  kreditele["order_number"] = order_number;
                  kreditele["credit_type"] = "Internal";
                  kreditele["payment_mode"] = paymentMode;
                  internalSuperKreditObj[order_number] = kreditele;
                } else if (paymentMode == "paymentpending") {
                  let kreditele = {};
                  kreditele["delivery_date"] = formattedDeliveryDate;
                  kreditele["orderKey"] = orderKey;
                  kreditele["phone"] =
                    getOrderHistory[routeKey][orderKey]["phone"];
                  kreditele["payment_diff"] = paymentDiff.toFixed(2);

                  kreditele["ordered_amt"] = ordered_amt;
                  kreditele["delivered_amt"] = delivered_amt.toFixed(2);
                  kreditele["credit_amt"] = credit_amt;
                  kreditele["status"] =
                    getOrderHistory[routeKey][orderKey]["status"];

                  kreditele["retailer_id"] =
                    getOrderHistory[routeKey][orderKey]["retailer_id"];
                  kreditele["order_number"] = order_number;
                  kreditele["credit_type"] = "Internal";
                  kreditele["payment_mode"] = paymentMode;
                  internalSuperKreditObj[order_number] = kreditele;
                }
              }
            });
          } else if (order_status == "Cancelled") {
            let kreditele = {};
            kreditele["orderKey"] = orderKey;
            kreditele["phone"] = getOrderHistory[routeKey][orderKey]["phone"];
            kreditele["payment_diff"] = paymentDiff.toFixed(2);

            kreditele["ordered_amt"] = ordered_amt;
            kreditele["delivered_amt"] = delivered_amt.toFixed(2);
            kreditele["credit_amt"] = credit_amt;
            kreditele["status"] = getOrderHistory[routeKey][orderKey]["status"];

            kreditele["retailer_id"] =
              getOrderHistory[routeKey][orderKey]["retailer_id"];
            kreditele["order_number"] = order_number;
            kreditele["credit_type"] = "Internal";
            internalSuperKreditObj[order_number] = kreditele;
          }
        }

        //external superkredit
        if (
          credit_amt > 0 &&
          getOrderHistory[routeKey][orderKey]["order_payment_mode"] ==
            "Credit" &&
          (order_status == "Cancelled" || order_status.includes("Delivered"))
        ) {
          let ele = {};
          ele["order_number"] = order_number;
          ele["delivery_date"] = formattedDeliveryDate;
          ele["ordered_amt"] = ordered_amt;
          ele["delivered_amt"] = delivered_amt.toFixed(2);
          ele["credit_amt"] = credit_amt;
          ele["phone"] = getOrderHistory[routeKey][orderKey]["phone"];
          ele["status"] = getOrderHistory[routeKey][orderKey]["status"];
          ele["payment_diff"] = paymentDiff.toFixed(2);
          ele["orderKey"] = orderKey;
          ele["routeKey"] = routeKey;
          ele["credit_type"] = "External";
          orderSuperKreditObj[order_number] = ele;
        }
        if (
          getOrderHistory[routeKey][orderKey].hasOwnProperty("status") &&
          (!userCity || getOrderHistory[routeKey][orderKey]["city"] == userCity)
        ) {
          if (
            getOrderHistory[routeKey][orderKey]["status"] == "Ordered" ||
            getOrderHistory[routeKey][orderKey]["status"] == "Processed" ||
            getOrderHistory[routeKey][orderKey]["status"].includes("Shipped")
          ) {
            generateAlert(
              `Data can't be exported when order no.${getOrderHistory[routeKey][orderKey].order_number} | ${getOrderHistory[routeKey][orderKey].driver_id} is in Ordered or Processed or Shipped state`,
              `error`
            );
            issueInExport = true;
            return;
          }
        }
      });
    });
    console.log(orderSuperKreditObj);

    console.log(internalSuperKreditObj);

    if (issueInExport == true) {
      return;
    } else if (
      Object.keys(orderSuperKreditObj).length > 0 ||
      Object.keys(internalSuperKreditObj).length > 0
    ) {
      setSuperZopKreditObj(internalSuperKreditObj);
      setSuperKreditObj(orderSuperKreditObj);
      setSuperKreditPopup(true);
    } else {
      setSuperKreditEndFlag(true);
      setSuperKreditPopup(true);
      generateAlert(`No Superkredit orders!`);
    }
  };

  const syncYesterdayDeliveredOrders = () => {
    Promise.all([
      fetch(`${service_url}/sync-yesterday-delivery-updates`).then((response) =>
        response.json()
      ),
    ])
      .then(([result]) => {
        setAlertType(result["success"] == 1 ? "info" : "error");
        setAlertMessage(result["message"]);
        setAlertStatus(true);
      })
      .catch((err) => {
        setAlertType("error");
        setAlertMessage(err);
        setAlertStatus(true);
      });
  };

  const processCashbackOrders = async () => {
    console.log("processCashbackOrders");

    let offersObj =
      Object.keys(getOffersObject).length > 0
        ? getOffersObject
        : localStorage.getItem("offersObj")
        ? JSON.parse(localStorage.getItem("offersObj"))
        : {};

    let cashbackOffersObj = {};
    Object.keys(offersObj).map((offerkey, offerindex) => {
      let mode = offersObj[offerkey].mode ? offersObj[offerkey].mode : "";
      mode = mode.toLowerCase();

      if (mode == "cashback") {
        let coupon_code = offersObj[offerkey].coupon_code;
        let discountValue = offersObj[offerkey]["discount_value"];
        let discountPercent = offersObj[offerkey]["discount_percent"];
        let ele = {};
        ele["coupon_code"] = coupon_code;
        ele["discountValue"] = discountValue;
        ele["discountPercent"] = discountPercent;
        ele["excluded_products"] = offersObj[offerkey]["excluded_products"];
        ele["category"] = offersObj[offerkey]["category"]
          ? offersObj[offerkey]["category"]
          : "";
        cashbackOffersObj[coupon_code] = ele;
      }
    });

    console.log(cashbackOffersObj);

    let orderCashbackObj = {};
    let orderNumberObj = {};
    Object.keys(getOrderHistory).forEach((routeKey) => {
      Object.keys(getOrderHistory[routeKey]).forEach((orderKey) => {
        let status = getOrderHistory[routeKey][orderKey]["status"]
          ? getOrderHistory[routeKey][orderKey]["status"]
          : "";

        if (status.includes("Delivered")) {
          let order_number =
            getOrderHistory[routeKey][orderKey]["order_number"];
          let coupon_code = getOrderHistory[routeKey][orderKey]["coupon_code"]
            ? getOrderHistory[routeKey][orderKey]["coupon_code"]
            : "";

          if (cashbackOffersObj) {
            //multiple coupons
            if (coupon_code.includes(",")) {
              //multiple coupons
              let couponCodeList = coupon_code.split(",");

              console.log(order_number);
              console.log(couponCodeList);

              for (let i = 0; i < couponCodeList.length; i++) {
                let origCouponCode = couponCodeList[i];
                if (cashbackOffersObj[origCouponCode]) {
                  let ele = {};
                  ele["order_number"] = order_number;
                  ele["retailer_id"] =
                    getOrderHistory[routeKey][orderKey]["retailer_id"];
                  ele["phone"] = getOrderHistory[routeKey][orderKey]["phone"];
                  ele["coupon_code"] = origCouponCode;
                  ele["category"] =
                    cashbackOffersObj[origCouponCode]["category"];
                  ele["discount_value"] =
                    cashbackOffersObj[origCouponCode]["discountValue"];
                  ele["discount_percent"] =
                    cashbackOffersObj[origCouponCode]["discountPercent"];
                  ele["status"] = status;
                  ele["route"] = routeKey;

                  if (!orderCashbackObj[order_number]) {
                    orderNumberObj[order_number] = orderKey;
                  }

                  ele["order_id"] = orderNumberObj[order_number];

                  let cashbackOrderKey = `${order_number}-${origCouponCode}`;

                  orderCashbackObj[cashbackOrderKey] = ele;
                }
              }
            } else {
              //single coupon
              if (cashbackOffersObj[coupon_code]) {
                let ele = {};
                ele["order_number"] = order_number;
                ele["retailer_id"] =
                  getOrderHistory[routeKey][orderKey]["retailer_id"];
                ele["phone"] = getOrderHistory[routeKey][orderKey]["phone"];
                ele["coupon_code"] = coupon_code;
                ele["category"] = cashbackOffersObj[coupon_code]["category"];
                ele["discount_value"] =
                  cashbackOffersObj[coupon_code]["discountValue"];
                ele["discount_percent"] =
                  cashbackOffersObj[coupon_code]["discountPercent"];
                ele["status"] = status;
                ele["route"] = routeKey;

                if (!orderCashbackObj[order_number]) {
                  orderNumberObj[order_number] = orderKey;
                }

                ele["order_id"] = orderNumberObj[order_number];

                let cashbackOrderKey = `${order_number}-${coupon_code}`;

                orderCashbackObj[cashbackOrderKey] = ele;
              }
            }
          }
        }
      });
    });
    console.log(orderCashbackObj);

    let updatedOrderCashbackObj = {};

    Object.keys(orderCashbackObj).map((key, index) => {
      let orderele = orderCashbackObj[key];
      let coupon_code = orderele.coupon_code;
      let routeKey = orderele.route;
      let orderKey = orderele.order_id;
      let offerCategory = cashbackOffersObj[coupon_code]["category"]
        ? cashbackOffersObj[coupon_code]["category"]
        : "";
      let delivered_amt = revisedAmtForOffersWithExcludedProducts(
        getOrderHistory[routeKey],
        orderKey,
        cashbackOffersObj[coupon_code]["excluded_products"],
        offerCategory
      );

      orderele["delivered_amt"] = delivered_amt.toFixed(2);
      updatedOrderCashbackObj[key] = orderele;
    });

    console.log(updatedOrderCashbackObj);

    if (Object.keys(updatedOrderCashbackObj).length > 0) {
      setCashbackObj(updatedOrderCashbackObj);
      setCashbackPopup(true);
    } else {
      generateAlert(`No Cashback orders!`);
    }
  };

  const sendNotifications = () => {
    fetch(
      "http://services2.superzop.com:3000/api/superzop/delivery/drivers_orders_history/sendnotificationforshippedorders",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": sessionStorage.getItem("x-access-token"),
        },
      }
    ).then((res) => {
      console.log(res);
      if (res.status == 200) {
        let driversnotificationele = {};
        fire
          .database()
          .ref(
            `Driver_Allocation_History/${currentDate.split("-")[2]}/${
              currentDate.split("-")[1]
            }/${currentDate}`
          )
          .once("value", (snapshot) => {
            if (snapshot.hasChildren()) {
              let driversData = snapshot.val();

              Object.keys(driversData).map((key, value) => {
                driversnotificationele[`${key}/notification_sent`] = true;
              });
              console.log(driversnotificationele);

              if (Object.keys(driversnotificationele).length > 0) {
                fire
                  .database()
                  .ref(
                    `Driver_Allocation_History/${currentDate.split("-")[2]}/${
                      currentDate.split("-")[1]
                    }/${currentDate}`
                  )
                  .update(driversnotificationele)
                  .then(() => {
                    setNotificationFlag(true);
                  });
              }
            }
          });

        setAlertType("info");
        setAlertMessage("Notifications sent successfully");
        setAlertStatus(true);
      } else {
        setAlertType("error");
        setAlertMessage("Error occured in sending Notifications");
        setAlertStatus(true);
      }
      return res.json();
    });
  };

  const getPaymentsDetails = (driver_id, order_id) => {
    let paymentDets = {};
    if ("payment_details" in getOrderHistory[driver_id][order_id]) {
      paymentDets = getOrderHistory[driver_id][order_id]["payment_details"];
    }
    return paymentDets;
  };

  const getCreditAmount = (driver_id, order_id) => {
    return getOrderHistory[driver_id][order_id]["credit_amt"]
      ? getOrderHistory[driver_id][order_id]["credit_amt"]
      : "0";
  };

  const fireBaseTotalAmountUpdate = (totalAmountToPay, driver_id, order_id) => {
    let temp_order_history = getOrderHistory;
    let itemsInOrders = temp_order_history[driver_id];

    let undeliveredItemAmt = 0;
    let totalAmtPaid = 0;
    Object.keys(itemsInOrders).forEach((orderNumber) => {
      if (
        !orderNumber.includes(
          `${order_id.split("-")[0]}-${order_id.split("-")[1]}`
        )
      )
        return;

      if (
        (itemsInOrders[orderNumber].item_delivered_qty &&
          itemsInOrders[orderNumber].mrp) ||
        (itemsInOrders[orderNumber].item_modified_qty &&
          itemsInOrders[orderNumber].mrp)
      ) {
        if (
          itemsInOrders[orderNumber].status
            .toLowerCase()
            .includes(`partially-delivered`) &&
          itemsInOrders[orderNumber].item_delivered_qty
        ) {
          // console.log( itemsInOrders[orderNumber].item_id+" ItemDeliveredQty is present::: "+itemsInOrders[orderNumber].order_qty+" - DeliveredQty:: "+itemsInOrders[orderNumber].item_delivered_qty)
          undeliveredItemAmt +=
            (Number(itemsInOrders[orderNumber].mrp) /
              Number(itemsInOrders[orderNumber].order_qty)) *
            (Number(itemsInOrders[orderNumber].order_qty) -
              Number(itemsInOrders[orderNumber].item_delivered_qty));
        } else if (
          (itemsInOrders[orderNumber].status
            .toLowerCase()
            .includes(`shipped`) ||
            itemsInOrders[orderNumber].status
              .toLowerCase()
              .includes(`partially-delivered`)) &&
          itemsInOrders[orderNumber].item_modified_qty
        ) {
          // console.log(itemsInOrders[orderNumber].item_id+" ItemModifiedQty is present::: "+itemsInOrders[orderNumber].order_qty+" - ModifiedQty:: "+itemsInOrders[orderNumber].item_modified_qty)
          undeliveredItemAmt +=
            (Number(itemsInOrders[orderNumber].mrp) /
              Number(itemsInOrders[orderNumber].order_qty)) *
            (Number(itemsInOrders[orderNumber].order_qty) -
              Number(itemsInOrders[orderNumber].item_modified_qty));
        }
      }
    });
    console.log("UndeliveredAmt:: " + undeliveredItemAmt);
    console.log("TotalOrderValue:: " + totalAmountToPay);
    if (!undeliveredItemAmt) {
      undeliveredItemAmt = 0;
    }

    let revisedOrderValue = (totalAmountToPay - undeliveredItemAmt).toFixed(2);

    let updateToAll = {};
    updateToAll["total_amt"] = "" + totalAmountToPay;
    updateToAll["revised_order_amt"] = "" + revisedOrderValue;

    let deliveryErrorsObj = {};
    deliveryErrorsObj["old_total_amt"] =
      getOrderHistory[driver_id][order_id]["total_amt"];
    deliveryErrorsObj["new_total_amt"] = "" + totalAmountToPay;

    if (getOrderHistory[driver_id][order_id]["revised_order_amt"]) {
      deliveryErrorsObj["old_revised_order_amt"] =
        "" + getOrderHistory[driver_id][order_id]["revised_order_amt"];
      deliveryErrorsObj["new_revised_order_amt"] = "" + revisedOrderValue;
    }

    deliveryErrorsObj["updated_by"] = usermodifying;
    deliveryErrorsObj["updated_at"] = UpdatedDate();

    let deliveryErrorParentKey = getDB;
    deliveryErrorParentKey = deliveryErrorParentKey.replace(
      "Driver_Orders_History",
      "Delivery_Errors"
    );

    for (let key in getOrdersPerShop[
      order_id.split("-")[0] + order_id.split("-")[1]
    ]) {
      let modified_url =
        getDB +
        "/" +
        driver_id +
        "/" +
        order_id.split("-")[0] +
        "-" +
        order_id.split("-")[1] +
        "-" +
        getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][key];
      fire.database().ref(modified_url).update(updateToAll);
      temp_order_history[driver_id][
        `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
          getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][key]
        }`
      ].total_amt = "" + totalAmountToPay;
      temp_order_history[driver_id][
        `${order_id.split("-")[0]}-${order_id.split("-")[1]}-${
          getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][key]
        }`
      ].revised_order_amt = "" + revisedOrderValue;

      let error_url =
        deliveryErrorParentKey +
        "/" +
        driver_id +
        "/" +
        order_id.split("-")[0] +
        "-" +
        order_id.split("-")[1] +
        "-" +
        getOrdersPerShop[order_id.split("-")[0] + order_id.split("-")[1]][key];
      fire.database().ref(error_url).update(deliveryErrorsObj);
    }

    setAlertType("success");
    setAlertMessage("Updated total amount ");
    setAlertStatus(true);
    setOrderHistory(temp_order_history);
    generateItemSummary(driver_id);
    RenderOrdersDetails(driver_id, order_id);
  };

  const getOrderRoute = () => {
    let route = "Not present";

    Object.keys(getOrderHistory).map((rkey, rindex) => {
      let routeOrders = getOrderHistory[rkey] || {};
      Object.keys(routeOrders).map((okey, oindex) => {
        if (routeOrders[okey].order_number.includes(searchOrderNumber)) {
          route = rkey;
        }
      });
    });

    setOrderRouteStr(route);
    return route;
  };

  return (
    <div>
      <NavBar />
      <div style={{ display: "none" }}>
        {getBreadCrumb.length > 0 ? (
          <Breadcrumb>
            <Breadcrumb.Item href="#">{getBreadCrumb[0]}</Breadcrumb.Item>
            {getBreadCrumb2.length > 0 ? (
              <Breadcrumb.Item active>{getBreadCrumb2}</Breadcrumb.Item>
            ) : null}
          </Breadcrumb>
        ) : null}
      </div>

      {
        <Navbar
          className="bg-light mr-auto justify-content-between"
          style={{ paddingTop: 100 }}
        >
          <Form inline>
            <InputGroup size="sm" style={{ marginRight: "20px" }}>
              <InputGroupAddon addonType="prepend">Date</InputGroupAddon>
              <DatePicker
                selected={getDate}
                onChange={handleChange}
                aria-describedby="basic-addon1"
                dateFormat="dd/MM/yyyy"
                minDate={(JSON.parse(sessionStorage.getItem(`user`))[0].user_type == 'sz-admin') ? subDays(new Date(), 7) : null} 
                  />
            </InputGroup>
            {userCity == "" && (
              <InputGroup size="sm" style={{ marginRight: "20px" }}>
                <InputGroupAddon addonType="prepend">WH</InputGroupAddon>
                <DropdownButton
                  as={InputGroup.Append}
                  variant="outline-primary"
                  title={warehouse ? warehouse : "All"}
                  id="wh-dropdown"
                >
                  {RenderWHDropdown()}
                </DropdownButton>
              </InputGroup>
            )}
            {(validateIsAdminUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            ) ||
              validateIsSystemsUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              )) &&
              getRenderCity}
            {getRenderDriver} {getFilterButton} {getMessage} {getPaymentButton}
            {getOrderHistory
              ? bulkUpdationConfirm
                ? bulkFilterStatusChange()
                : ""
              : ""}
            {(allowedRoutesSet.has(`/orderReport`) ||
              validateIsAdminUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              )) && (
              <Link to={"/orderReport"}>
                <Button
                  size={"sm"}
                  variant={"success"}
                  style={{ marginRight: "10px", marginLeft: "10px" }}
                >
                  Order Report
                </Button>
              </Link>
            )}
            {(validateIsAdminUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            ) ||
              (allowedRoutesSet.has(`/orderBulkPrint`) &&
                (isRoutePickingCompletedFlag[`${getBreadCrumb[0]}-BAG`] ||
                  isRoutePickingCompletedFlag[`${getBreadCrumb[0]}-FMCG`] ||
                  isRoutePickingCompletedFlag[
                    `${getBreadCrumb[0]}-HIGH_VALUE`
                  ] ||
                  isRoutePickingCompletedFlag[
                    `${getBreadCrumb[0]}-LOOSE_PACK`
                  ] ||
                  getBreadCrumb[0] > 8000 || 
                  (getBreadCrumb[0] && getBreadCrumb[0].includes('F'))))) && (
              <Button
                style={{ marginRight: "10px", marginLeft: "10px" }}
                size={"sm"}
                variant={"primary"}
                onClick={() => {
                  sessionStorage.setItem("routeIdToPrint", getBreadCrumb[0]);
                  sessionStorage.setItem("dateToPrint", getDate);
                  sessionStorage.setItem("getDB", getDB);
                  localStorage.setItem(
                    "orderHistory",
                    JSON.stringify(getOrderHistory)
                  );
                  sessionStorage.setItem("varietyList", JSON.stringify([]));
                  window.open(`/orderBulkPrint`, "_blank");
                }}
              >
                <FontAwesomeIcon size="2x" icon={faPrint} />
              </Button>
            )}
            {(allowedRoutesSet.has(`/addreturnadjustmentorders`) ||
              validateIsAdminUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              )) && (
              <Link
                to={"/addreturnadjustmentorders"}
                style={{ float: "right", marginRight: "10px" }}
              >
                <Button size={"sm"} variant={"warning"}>
                  +Order
                </Button>
              </Link>
            )}
            {/*(allowedRoutesSet.has(`/superkredit-orders`) || validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))) && <Link to={"/superkreditreports"} style={{ float: "right",marginRight: "10px" }}><Button size={"sm"} variant={"warning"}>Reports</Button></Link>*/}
            {/* <Link to={"/neworderbulkprint"} target={"_blank"} style={{ float: "right" }}><Button size={"sm"} variant={"primary"} onClick={() => { sessionStorage.setItem("routeIdToPrint",getBreadCrumb[0]);sessionStorage.setItem("dateToPrint",getDate);localStorage.setItem("orderHistory",JSON.stringify(getOrderHistory)) }}>New Bulk Print</Button></Link> */}
            {(validateIsAdminUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            ) ||
              ((validateIsFinanceUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              ) ||
                validateIsFinanceHeadUser(
                  JSON.parse(sessionStorage.getItem(`user`))[0].email
                )) &&
                (userCity == "" || userCity == "Mumbai"))) &&
              getOrderHistory && (
                <div style={{ marginRight: "10px", marginLeft: "20px" }}>
                  <Button
                    size={"sm"}
                    variant={superKreditEndFlag ? "secondary" : "success"}
                    onClick={() => {
                      sessionStorage.setItem("getDB", getDB);
                      localStorage.setItem(
                        "orderHistory",
                        JSON.stringify(getOrderHistory)
                      );
                      window.open("/superkredit-orders", "_blank");
                    }}
                  >
                    Process SuperKredit Orders
                  </Button>
                </div>
              )}
            {(validateIsAdminUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            ) ||
              userCity == "" ||
              userCity == "Mumbai") &&
              getOrderHistory && (
                <div style={{ marginRight: "10px", marginLeft: "20px" }}>
                  <Button
                    size={"sm"}
                    variant={"secondary"}
                    onClick={() => {
                      setDiscrepancyPopup(true);
                    }}
                  >
                    Discrepancies
                  </Button>
                </div>
              )}
            {validateIsExportUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            ) &&
              getOrderHistory && (
                <div style={{ marginRight: "10px", marginLeft: "20px" }}>
                  <Button
                    size={"sm"}
                    variant={"success"}
                    onClick={() => {
                      processExportConfirm();
                    }}
                  >
                    Export Data |{" "}
                    {`${getDate.getDate()}-${
                      getDate.getMonth() + 1
                    }-${getDate.getFullYear()}`}
                  </Button>
                </div>
              )}
            {(validateIsAdminUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            ) ||
              validateIsOperationUser(
                JSON.parse(sessionStorage.getItem(`user`))[0].email
              )) &&
              `${getDate.getDate()}-${
                getDate.getMonth() + 1
              }-${getDate.getFullYear()}` ===
                `${new Date().getDate()}-${
                  new Date().getMonth() + 1
                }-${new Date().getFullYear()}` && (
                <div style={{ marginRight: "10px", marginLeft: "20px" }}>
                  <Button
                    size="sm"
                    disabled={notificationFlag}
                    onClick={() => sendNotifications()}
                  >
                    Notify Retailers
                  </Button>
                </div>
              )}
            {validateIsAdminUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            ) &&
              getOrderHistory && (
                <div style={{ marginRight: "10px", marginLeft: "20px" }}>
                  <Button
                    size={"sm"}
                    variant={"outline-danger"}
                    onClick={() => {
                      setDeliveryErrorsPopup(true);
                    }}
                  >
                    Delivery Errors
                  </Button>
                </div>
              )}
            {validateIsAdminUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            ) && (
              <Button
                size="sm"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  processCashbackOrders();
                }}
              >
                Cashback Orders
              </Button>
            )}
            {validateIsAdminUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
            ) && (
              <Button
                size="sm"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  syncYesterdayDeliveredOrders();
                }}
              >
                Sync Yesterday Delivered Orders
              </Button>
            )}
            <Button
              size="sm"
              onClick={() => {
                setShowVehicleStatusModal(true);
              }}
            >
              Show vehicle status
            </Button>{" "}
            &nbsp;
            <div
              style={{
                marginRight: "10px",
                marginLeft: "20px",
                marginTop: "5px",
              }}
            >
              <Form.Control
                type="text"
                placeholder="Enter Order No. "
                size={"sm"}
                onChange={(e) => {
                  setSearchOrderNumber(e.target.value);
                }}
              />
              <Button
                size={"sm"}
                variant={"outline-danger"}
                onClick={() => {
                  getOrderRoute();
                }}
              >
                Get Route
              </Button>
            </div>
            {orderRouteStr && (
              <p style={{ paddingTop: "20px" }}>{orderRouteStr}</p>
            )}

          {(validateIsAdminUser(
              JSON.parse(sessionStorage.getItem(`user`))[0].email
              )) &&
            <div
              style={{
                display:"flex",
                alignItems: "center",
                margin:"20px"
              }}
            >                            
              {/* {getMoveDriver}              */}
              <InputGroup size="sm">        
              <InputGroupAddon addonType="prepend">Source Route</InputGroupAddon>
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="moveroute-dropdown-basic" size="sm">
                {getRouteToMoveSelected}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {moveDriverOptions.map((option) => (
                  <Dropdown.Item
                    key={option.value}
                    onClick={() => setRouteToMoveSelected(option.value)}
                    disabled={option.value === 'Select Route'}
                  >
                    {option.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>
              <Button
                size={"sm"}
                variant={"outline-danger"}
                onClick={() => {
                   if (getRouteToMoveSelected !== 'Select Route') {
                moveDOHData(getRouteToMoveSelected,getPrimaryDropDownMessage);
              } else {
                toast("Please select a route to move", { type: toast.TYPE.WARNING });
              }
                  
                }}
              >
                Move this Route to Current
              </Button>
            </div> }          
          </Form>
        </Navbar>
      }

      <br></br>

      <Container fluid>
        <Row>
          {getRenderOrder ? getRenderOrder : null}

          {getRenderOrderDetails ? getRenderOrderDetails : null}
        </Row>
      </Container>
      {confirmPopupShow ? (
        <ExportConfirmPopup
          dateOfExport={getDate}
          endDelCurrentFlag={deliveryEndFlagMap[getModalData[0]] || false}
          show={confirmPopupShow}
          onHide={() => setConfirmPopupShow(false)}
          showFlag={showFlag}
          getOrderHistory={getOrderHistory}
          getAccessibleRoutes={getAccessibleRoutes}
        />
      ) : (
        ""
      )}
      {dataDownload ? (
        <ExportDeliveryDetails
          multipleDownload={(status) => setDataDownload(status)}
          downloadDate={`${getDate.getDate()}-${
            getDate.getMonth() + 1
          }-${getDate.getFullYear()}`}
          getOrderHistory={getOrderHistory}
        />
      ) : (
        ""
      )}

      {getModal ? (
        <ModalView
          ModalValue={getModal}
          parentCallBack={setModal}
          driver_id={getModalData[0]}
          order_id={getModalData[1]}
          orderPaymentMode={orderPaymentMode}
          totalSuperKreditAmt={totalSuperKreditAmt}
          paymentDetails={getPaymentsDetails(getModalData[0], getModalData[1])}
          credit_amt={getCreditAmount(getModalData[0], getModalData[1])}
          callback={fireBasePartialWithMulti}
        />
      ) : null}
      {getModalWithView ? (
        <ModalWithStatus
          ModalValue={getModalWithView}
          parentCallBack={setModalWithView}
          orderPaymentMode={orderPaymentMode}
          totalSuperKreditAmt={totalSuperKreditAmt}
          status={sessionStorage.setItem(`status_current`, `Delivered`)}
          driver_id={getModalData[0]}
          order_id={getModalData[1]}
          paymentDetails={getPaymentsDetails(getModalData[0], getModalData[1])}
          credit_amt={getCreditAmount(getModalData[0], getModalData[1])}
          callback={fireBasePartialWithMulti}
        />
      ) : null}
      {getModalEdit ? (
        <ModalEdit
          ModalValue={getModalEdit}
          parentCallBack={setModalEdit}
          driver_id={getModalData[0]}
          order_id={getModalData[1]}
          orderPaymentMode={orderPaymentMode}
          totalSuperKreditAmt={totalSuperKreditAmt}
          paymentDetails={getPaymentsDetails(getModalData[0], getModalData[1])}
          credit_amt={getCreditAmount(getModalData[0], getModalData[1])}
          callback={fireBasePartialWithMulti}
        />
      ) : null}
      {getModalPartial ? (
        <ModalPartial
          ModalValue={getModalPartial}
          parentCallBack={setModalPartial}
          orderPaymentMode={orderPaymentMode}
          totalSuperKreditAmt={totalSuperKreditAmt}
          status={sessionStorage.setItem(
            `status_current`,
            `Partially-Delivered`
          )}
          driver_id={getModalData[0]}
          order_id={getModalData[1]}
          paymentDetails={getPaymentsDetails(getModalData[0], getModalData[1])}
          credit_amt={getCreditAmount(getModalData[0], getModalData[1])}
          callback={fireBasePartialWithMulti}
        />
      ) : null}

      {getModalCancelled ? (
        <ModalCancelled
          ModalValue={getModalCancelled}
          parentCallBack={setModalCancelled}
          driver_id={getModalData[0]}
          order_id={getModalData[1]}
          callback={fireBasePendingUpdate}
        />
      ) : null}
      {getModalPending ? (
        <ModalPending
          ModalValue={getModalPending}
          parentCallBack={setModalPending}
          driver_id={getModalData[0]}
          order_id={getModalData[1]}
          callback={fireBasePendingUpdate}
        />
      ) : null}
      {getModalModifiedItemQty ? (
        <ModalModifiedItemQty
          ModalValue={getModalModifiedItemQty}
          parentCallBack={setModalModifiedItemQty}
          driver_id={getItemData[0]}
          order_id={getItemData[1]}
          order_status={getItemData[2]}
          case_size={getItemData[3]}
          item_qty={itemOrderQty}
          item_id={itemId}
          ordered_item_id={orderedItemId}
          ordered_item_desc={orderedItemDesc}
          item_desc={itemDesc}
          item_modified_qty={getModifiedQty}
          callback={fireBaseItemQuantityUpdate}
        />
      ) : null}
      {getModalDeletedItemQty ? (
        <ModalDeletedItemQty
          ModalValue={getModalDeletedItemQty}
          parentCallBack={setModalDeletedItemQty}
          driver_id={getItemData[0]}
          order_id={getItemData[1]}
          order_status={getItemData[2]}
          callback={fireBaseItemStatusUpdate}
        />
      ) : null}
      {getBackDrop ? <Backdrop parentLoadStatus={getBackDrop} /> : null}
      {getAlertStatus ? (
        <Alert
          message={getAlertMessage}
          type={getAlertType}
          open={getAlertStatus}
          setParentAlert={setAlertStatus}
        />
      ) : null}
      {getPaymentSummaryVisibility ? (
        <PaymentModal
          statusCheck={orderStatusCheck}
          getSelectedDate={getDate}
          getSelectedDriver={getPrimaryDropDownMessage}
          getPaymentSummaryVisibility={getPaymentSummaryVisibility}
          setPaymentSummaryVisibility={setPaymentSummaryVisibility}
          tableData={getPaymentSummary}
          allRouteOrdersData={getOrderHistory}
          generateAlert={generateAlert}
          getAccessibleRoutes={getAccessibleRoutes}
        />
      ) : null}
      {getItemDetailsStatus ? (
        <ItemDetails
          getItemDetailsStatus={getItemDetailsStatus}
          setItemDetailsStatus={setItemDetailsStatus}
          getSelectedDate={getDate}
          getSelectedDriver={getPrimaryDropDownMessage}
          getItemDetails={getItemDetails}
          allRouteOrdersData={getOrderHistory}
          getOrderVarietyMap={getOrderVarietyMap}
          generateAlert={generateAlert}
          getAccessibleRoutes={getAccessibleRoutes}
          freshTotalLoadedCrateCount={freshTotalLoadedCrateCount}
        />
      ) : null}

      {superKreditPopup ? (
        <SuperKreditValueUpdatePopup
          show={superKreditPopup}
          onHide={() => setSuperKreditPopup(false)}
          superKreditPopup={superKreditPopup}
          superKreditObj={superKreditObj}
          getSuperZopKreditObj={getSuperZopKreditObj}
          superKreditEndFlag={superKreditEndFlag}
          callback={firebaseSuperkreditFlagUpdate}
        />
      ) : null}

      {cashbackPopup ? (
        <CashbackUpdatePopup
          show={cashbackPopup}
          onHide={() => setCashbackPopup(false)}
          cashbackPopup={cashbackPopup}
          cashbackObj={cashbackObj}
          cashbackEndFlag={cashbackEndFlag}
          callback={firebaseCashbackFlagUpdate}
        />
      ) : null}

      {totalAmountUpdatePopupShow ? (
        <TotalAmountModalPopup
          show={totalAmountUpdatePopupShow}
          onHide={() => setTotalAmountUpdatePopupShow(false)}
          totalAmtToBePaid={totalAmtToBePaid}
          driver_id={getModalData[0]}
          order_id={getModalData[1]}
          callback={fireBaseTotalAmountUpdate}
        />
      ) : null}

      {revisedOrderDiscUpdatePopupShow ? (
        <RevisedOrderDiscountModalPopup
          show={revisedOrderDiscUpdatePopupShow}
          onHide={() => setRevisedOrderDiscUpdatePopupShow(false)}
          revisedOrderDiscToBeUpdated={revisedOrderDiscToBeUpdated}
          driver_id={getModalData[0]}
          order_id={getModalData[1]}
          callback={updateRevisedOrderDiscountInFirebase}
        />
      ) : null}

      {revisedOrderAmtUpdatePopupShow ? (
        <RevisedOrderAmountModalPopup
          show={revisedOrderAmtUpdatePopupShow}
          onHide={() => setRevisedOrderAmtUpdatePopupShow(false)}
          revisedOrderAmtToBeUpdated={revisedOrderAmtToBeUpdated}
          driver_id={getModalData[0]}
          order_id={getModalData[1]}
          callback={updateRevisedOrderAmtInFirebase}
        />
      ) : null}

      {receivedReturnedProductsModalOpen ? (
        <RecievedReturnProductsModal
          show={receivedReturnedProductsModalOpen}
          onHide={() => setReceivedReturnedProductsModalOpen(false)}
          driver_id={getModalData[0]}
          order_id={getModalData[1]}
          getOrderHistory={getOrderHistory}
          selectedReturnOrderStatus={selectedReturnOrderStatus}
          getDB={getDB}
          receivedReturnedProductsModalOpen={receivedReturnedProductsModalOpen}
          callback={updateLocalRouteOrders}
        />
      ) : null}

      {dispatchedVehiclePopupOpen ? (
        <VehicleDispatchModalPopup
          show={dispatchedVehiclePopupOpen}
          onHide={() => setDispatchedVehiclePopupOpen(false)}
          driver_id={getPrimaryDropDownMessage}
          selectDate={getDate}
          callback={pageDispatchVehicleUpdate}
        />
      ) : null}

      {getDeliveryErrorsPopup ? (
        <DeliveryErrorsModalPopup
          show={getDeliveryErrorsPopup}
          onHide={() => setDeliveryErrorsPopup(false)}
          getDB={getDB}
        />
      ) : null}
      {getDiscrepancyPopup ? (
        <DiscrepancyPopup
          show={getDiscrepancyPopup}
          onHide={() => setDiscrepancyPopup(false)}
          getOrderHistory={getOrderHistory}
          getDB={getDB}
        />
      ) : null}
      {showVehicleStatusModal ? (
        <VehicleStatusModal
          pickingDate={pickingDate}
          show={showVehicleStatusModal}
          setShow={setShowVehicleStatusModal}
          cityList={cityList}
          date={currentDate}
          allocationHistoryRaw={getAllocationHistory}
          ordersHistoryRaw={getOrderHistory}
        />
      ) : (
        ""
      )}

      <div style={{ paddingBottom: 40 }} />
      <FooterComponent />
    </div>
  );
}

export default Orders;
