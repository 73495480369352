import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../Navigation bar/Navbar";
import FooterComponent from "../Footer/footer";

import DatePicker from "react-datepicker";
import { subDays } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import VehicleRoutingMapOrders from "./VehicleRoutingMapOrders";
import AllVehicleMapOrders from "./AllVehicleMapOrders";
import { InputGroupAddon } from "reactstrap";
import { Dropdown, DropdownButton, InputGroup, Button } from "react-bootstrap";
import fire from "../../Configs/firebase";
import { warehouses } from '../../indexedDBUtility.js';

const VehicleRouting = (props) => {
  const [routeDate, setRouteDate] = useState(new Date());
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    warehouses
      .then(data => {
        console.log("Fetched Warehouses:", data);
        const allWarehouses =  [
          "All",
           ...data.map(obj => (obj.warehouse))
         ];
        setCityList(allWarehouses);
        console.log("All warehouses:", allWarehouses);
      })
      .catch(error => {
        console.error("Failed to fetch warehouses:", error);
      });
  }, []);

  const [cityListRaw, setCityListRaw] = useState({});
  const [regionsRaw, setRegionsRaw] = useState({});
  const [routeVehicleList, setRouteVehicleList] = useState([]);
  const [routeVehicleListToRender, setRouteVehicleListToRender] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedVehicleId, setSelectedVehicleId] =
    useState("Select Vehicle ID");
  const [selectedCity, setSelectedCity] = useState("Select WareHouse");
  const [mapOrdersData, setMapOrdersData] = useState([]);
  const [orderListForSelectedRoute, setOrderListForSelectedRoute] = useState(
    []
  );
  const [mapAllVehicleOrdersData, setMapAllVehicleOrdersData] = useState([]);
  //contains all the driver allocation details for the given date
  const [allDriverInfo, setAllDriverInfo] = useState({});
  //contains driver data to be displayed in the menu bar for the current selected route
  const [driverInfo, setDriverInfo] = useState([]);
  const [showOnlyAllocatedRoutes, setShowOnlyAllocatedRoutes] = useState(false);
  //This variable contains the city value data that can be accessed by this particular user.
  const loggedInUserCity = sessionStorage.getItem("userCity") || "";
  const loggedInUserType = sessionStorage.getItem("userType");

  useEffect(() => {
    sessionStorage.setItem("selectedDate", new Date());
    let deliverydate = sessionStorage.getItem("deliverydate");
    let vehicle = sessionStorage.getItem("vehicle");
    if (deliverydate != null && vehicle != null) {
      // console.log("booyah");
      fetchVehicleDetails(new Date(deliverydate));
      setRouteDate(new Date(deliverydate));
      setSelectedVehicleId(vehicle);
      fetchRedirectionOrdersDetailsForVehicle(vehicle, new Date(deliverydate));
    } else {
      // console.log("running else block");
      // fetchVehicleDetails(new Date());
      fetchCityList();
    }
  }, []);

  useEffect(() => {
    let filteredRouteVehList = [];

    if (showOnlyAllocatedRoutes) {
      routeVehicleList.forEach((item, index) => {
        if (item.routeNo !== "All" && item.routePhone !== "unallocated") {
          filteredRouteVehList.push(item);
        }
      });
    } else {
      filteredRouteVehList = routeVehicleList || [];
    }
    setRouteVehicleListToRender(filteredRouteVehList);
  }, [showOnlyAllocatedRoutes]);

  const fetchCityList = () => {
    fire
      .database()
      .ref("Region_List")
      .once("value")
      .then((snapshot) => {
        let cities = [];
        let citiesRaw = {};
        const data = snapshot.val();
        Object.keys(data).forEach((key, index) => {
          if (
            loggedInUserCity === "" ||
            data[key].city === loggedInUserCity ||
            loggedInUserType === "Admin"
          ) {
            if (data[key].city in citiesRaw) {
              citiesRaw[data[key].city].push(data[key].id);
            } else {
              cities.push(data[key].city);
              citiesRaw[data[key].city] = [data[key].id];
            }
          }
        });
        console.log("setting cities", citiesRaw);
        // setCityList(cities);
        setCityListRaw(citiesRaw);
        setRegionsRaw(data);
      })
      .catch((err) => {
        console.log(
          "An error occured while attempting to fetch the warehouse list",
          err
        );
      });
  };

  const fetchRedirectionOrdersDetailsForVehicle = (
    selectedVehicleId,
    routedt
  ) => {
    // console.log("fetchOrdersDetailsForVehicle");
    // console.log(selectedVehicleId);
    // console.log(routedt);
    let year = routedt.getFullYear();
    let month = routedt.getMonth() + 1;
    let date = routedt.getDate() + "-" + month + "-" + year;
    let routeId = selectedVehicleId.split("-")[1];
    let phone = selectedVehicleId.split("-")[0];
    sessionStorage.removeItem("deliverydate");
    sessionStorage.removeItem("vehicle");

    fire
      .database()
      .ref(
        "Driver_Orders_History/" +
          year +
          "/" +
          month +
          "/" +
          date +
          "/" +
          routeId +
          "/"
      )
      .once("value", (snapshot) => {
        if (snapshot.hasChildren()) {
          let vehordersdata = snapshot.val();
          // console.log(vehordersdata);
          let finalVehicleOrdersList = [];

          let orderNumberSet = new Set();
          Object.keys(vehordersdata).forEach((value) => {
            let order_number = vehordersdata[value]["order_number"];
            if (!orderNumberSet.has(order_number)) {
              let deliveryStatus = vehordersdata[value]["status"];
              let updatedAt;
              if (vehordersdata[value]["updatedAt"]) {
                updatedAt = vehordersdata[value]["updatedAt"];
              } else {
                updatedAt = "";
              }

              finalVehicleOrdersList.push({
                latitude: vehordersdata[value].latitude,
                longitude: vehordersdata[value].longitude,
                priority: vehordersdata[value].priority,
                status: deliveryStatus,
                orderNumber: order_number,
                vehicle: vehordersdata[value]["vehicle"],
                shop: vehordersdata[value]["shop_name"],
                area: vehordersdata[value]["area"],
                address:
                  vehordersdata[value]["address1"] +
                  " " +
                  vehordersdata[value]["address2"],
                updatedAt: updatedAt,
              });
              orderNumberSet.add(order_number);
            }
          });
          // console.log(finalVehicleOrdersList);
          finalVehicleOrdersList.sort(function (a, b) {
            var keyA = a.priority,
              keyB = b.priority;
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
          setMapOrdersData(finalVehicleOrdersList);
          // console.log(finalVehicleOrdersList);
        }
      });
  };

  const fetchAllVehicleOrderDetails = (city) => {
    //Function that connects with order_history and gets the details
    // console.log("fetchAllVehicleOrderDetails");
    let year = routeDate.getFullYear();
    let month = routeDate.getMonth() + 1;
    let date = routeDate.getDate() + "-" + month + "-" + year;

    fire
      .database()
      .ref("Driver_Orders_History/" + year + "/" + month + "/" + date + "/")
      .once("value", (snapshot) => {
        if (snapshot.hasChildren()) {
          let vehordersdata = snapshot.val();
          console.log(vehordersdata);
          let finalOrdersList = [];

          Object.keys(vehordersdata).map((key, index) => {
            if (
              vehordersdata[key][Object.keys(vehordersdata[key])[0]].city ===
              city
            ) {
              let vehicleSpecificOrders = vehordersdata[key];
              let orderNumberSet = new Set();
              let vehOrdersList = [];
              Object.keys(vehicleSpecificOrders).map((vskey, vsindex) => {
                // console.log("check key", key.slice(0, 2));
                // console.log("check all orders", vehicleSpecificOrders[vskey]);
                let order_number = vehicleSpecificOrders[vskey]["order_number"];
                let latitude = vehicleSpecificOrders[vskey]["latitude"];
                let longitude = vehicleSpecificOrders[vskey]["longitude"];
                let retailer_id = vehicleSpecificOrders[vskey]["retailer_id"];
                let shop_name = vehicleSpecificOrders[vskey]["shop_name"];
                let status = vehicleSpecificOrders[vskey]["status"];
                let priority = vehicleSpecificOrders[vskey]["priority"];

                if (!orderNumberSet.has(order_number)) {
                  orderNumberSet.add(order_number);
                  vehOrdersList.push({
                    route: key,
                    order_number: order_number,
                    latitude: latitude,
                    longitude: longitude,
                    retailer_id: retailer_id,
                    shop_name: shop_name,
                    status: status,
                    priority: priority,
                  });
                }
              });
              finalOrdersList.push({ route: key, orderdetails: vehOrdersList });
            }
          });
          // console.log(finalOrdersList);
          setMapAllVehicleOrdersData(finalOrdersList);
        }
      });
  };

  const fetchVehicleDetails = (routeDate, city) => {
    if (!routeDate || !city) {
      console.log("Did not receive date and city");
      return;
    }

    // console.log("fetchVehicleDetails");
    // console.log("routeDate", routeDate);
    // console.log("city", city);
    let year = routeDate.getFullYear();
    let month = routeDate.getMonth() + 1;
    let date = routeDate.getDate() + "-" + month + "-" + year;
    const allRouteListDict = {};
    let driverDetails = {};
    let phoneRouteDict = [];

    fire
      .database()
      .ref("Driver_Orders_History/" + year + "/" + month + "/" + date + "/")
      .once("value", (snapshot) => {
        if (snapshot.hasChildren()) {
          const snap_data = snapshot.val();
          //!Save the retrived details in some other global variable
          Object.keys(snap_data).forEach((key) => {
            //Include only those routes depending on the city

            if (snap_data[key][Object.keys(snap_data[key])[0]].city === city) {
              allRouteListDict[key] = "unallocated";
            }
          });
        }

        fire
          .database()
          .ref(
            "Driver_Allocation_History/" + year + "/" + month + "/" + date + "/"
          )
          .once("value", (snapshot) => {
            if (snapshot.hasChildren()) {
              const snap_data = snapshot.val();
              driverDetails = snap_data;
              // console.log("DEBUG snap_data allocation", snap_data);
              Object.keys(snap_data).forEach((key) => {
                if (snap_data[key].current_Route_Id in allRouteListDict) {
                  allRouteListDict[snap_data[key].current_Route_Id] = key;
                }
              });

              // console.log(
              //   "DEBUG allRouteListDict",
              //   allRouteListDict,
              //   Object.keys(allRouteListDict).length
              // );
            }

            Object.keys(allRouteListDict).forEach((key) => {
              phoneRouteDict.push({
                routeNo: key,
                routePhone: allRouteListDict[key],
                regionName: regionsRaw[key.slice(0, 2)].region,
              });
            });

            //Sort the routes
            phoneRouteDict.sort(function (a, b) {
              var keyA = a.routeNo,
                keyB = b.routeNo;
              if (keyA < keyB) return -1;
              if (keyA > keyB) return 1;
              return 0;
            });
            let allRouteList = [];
            //Push All routes dropdown item
            allRouteList.push({ routeNo: "All", routePhone: "All" });
            //Concat the above with phoneRouteDict (main object)
            phoneRouteDict = allRouteList.concat(phoneRouteDict);
            //Set route vehicle list is what is being used for the dropdown options
            setRouteVehicleList(phoneRouteDict);
            setRouteVehicleListToRender(phoneRouteDict);
            setShowOnlyAllocatedRoutes(false);
            setAllDriverInfo(driverDetails);
          });
      });
  };

  const refetchVehicleDetails = (city) => {
    let selected_city = "";
    if (!city || city === "") {
      selected_city = selectedCity;
    } else {
      selected_city = city;
    }
    setRouteVehicleListToRender([]);
    setSelectedVehicleId("Select Vehicle ID");
    setDriverInfo({});
    let selectedDate = sessionStorage.getItem("selectedDate");
    fetchVehicleDetails(new Date(selectedDate), selected_city);
  };

  const handleChange = (selecteddate) => {
    setLoading(true);
    setSelectedVehicleId("Select Vehicle ID");
    setRouteDate(new Date(selecteddate));
    setRouteVehicleList([]);
    setRouteVehicleListToRender([]);
    setShowOnlyAllocatedRoutes(false);
    fetchVehicleDetails(selecteddate, selectedCity);
    sessionStorage.setItem("selectedDate", selecteddate);
    setDriverInfo({});
  };

  const populateDriverInfo = (identifier) => {
    //Assumes the identifier is in the format phone-routeNo
    let phone = identifier.split("-")[0];
    if (phone === "unallocated") {
      setDriverInfo({});
      return;
    }

    //Get the driver details from allDriverInfo
    let driverData = allDriverInfo[phone];
    // console.log("driverData", driverData);
    setDriverInfo(driverData);
  };

  const fetchOrdersDetailsForVehicle = (selectedVehicleId) => {
    // console.log("fetchOrdersDetailsForVehicle");
    // console.log(selectedVehicleId);
    // console.log(routeDate);
    let year = routeDate.getFullYear();
    let month = routeDate.getMonth() + 1;
    let date = routeDate.getDate() + "-" + month + "-" + year;
    let routeId = selectedVehicleId.split("-")[1];
    let phone = selectedVehicleId.split("-")[0];
    // console.log(
    //   "Driver_Orders_History/" +
    //     year +
    //     "/" +
    //     month +
    //     "/" +
    //     date +
    //     "/" +
    //     routeId +
    //     "/"
    // );

    fire
      .database()
      .ref(
        "Driver_Orders_History/" +
          year +
          "/" +
          month +
          "/" +
          date +
          "/" +
          routeId +
          "/"
      )
      .once("value", (snapshot) => {
        if (snapshot.hasChildren()) {
          let vehordersdata = snapshot.val();

          // console.log(vehordersdata);
          let finalVehicleOrdersList = [];
          let orders_list = [];
          let orderNumberSet = new Set();
          Object.keys(vehordersdata).forEach((value) => {
            let order_number = vehordersdata[value]["order_number"];
            orders_list.push(value);
            if (!orderNumberSet.has(order_number)) {
              let deliveryStatus = vehordersdata[value]["status"];
              let updatedAt;
              if (vehordersdata[value]["updatedAt"]) {
                updatedAt = vehordersdata[value]["updatedAt"];
              } else {
                updatedAt = "";
              }

              finalVehicleOrdersList.push({
                latitude: vehordersdata[value].latitude,
                longitude: vehordersdata[value].longitude,
                priority: vehordersdata[value].priority,
                status: deliveryStatus,
                orderNumber: order_number,
                vehicle: vehordersdata[value]["vehicle"],
                shop: vehordersdata[value]["shop_name"],
                area: vehordersdata[value]["area"],
                address:
                  vehordersdata[value]["address1"] +
                  " " +
                  vehordersdata[value]["address2"],
                updatedAt: updatedAt,
              });
              orderNumberSet.add(order_number);
            }
          });
          // console.log(finalVehicleOrdersList);
          finalVehicleOrdersList.sort(function (a, b) {
            var keyA = a.priority,
              keyB = b.priority;
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
          setMapOrdersData(finalVehicleOrdersList);
          setOrderListForSelectedRoute(orders_list);
          // console.log(finalVehicleOrdersList);
        }
      });
  };

  //UI Elements
  const VehicleDropdown = () => {
    let rows;
    if (routeVehicleListToRender.length > 0) {
      //<Dropdown.Item key="All" name="All" onClick={(e)=>{e.preventDefault();alert(e.target.name)}}>All</Dropdown.Item>
      rows = routeVehicleListToRender.map((routeVehicle, index) => {
        let routeNumber = routeVehicle["routeNo"];
        let vehcPhone = routeVehicle["routePhone"];
        let regionName = routeVehicle["regionName"];
        if (routeNumber == "All") {
          return (
            <Dropdown.Item
              key="All"
              name="All"
              onClick={(e) => {
                e.preventDefault();
                setSelectedVehicleId(e.target.name);
                fetchAllVehicleOrderDetails(selectedCity);
              }}
            >
              All
            </Dropdown.Item>
          );
        } else {
          return (
            <Dropdown.Item
              key={vehcPhone + "-" + routeNumber}
              name={vehcPhone + "-" + routeNumber}
              onClick={(e) => {
                e.preventDefault();
                setSelectedVehicleId(e.target.name);
                fetchOrdersDetailsForVehicle(e.target.name);
                populateDriverInfo(e.target.name);
              }}
            >
              {vehcPhone + "-" + routeNumber + " (" + regionName + ")"}
            </Dropdown.Item>
          );
        }
      });
    } else {
      rows = [];
    }

    return (
      <>
        {selectedCity !== "Select WareHouse" ? (
          <DropdownButton
            id="dropdown-basic-button"
            title={
              selectedVehicleId != "Select Vehicle ID"
                ? selectedVehicleId
                : "Select Vehicle ID"
            }
            size="sm"
            variant="secondary"
            onSelect={() => {
              // setMapOrdersData([]);
            }}
          >
            {rows}
          </DropdownButton>
        ) : (
          ""
        )}
      </>
    );
  };

  const CityDropdown = () => {
    let rows;
    if (cityList.length > 0) {
      //<Dropdown.Item key="All" name="All" onClick={(e)=>{e.preventDefault();alert(e.target.name)}}>All</Dropdown.Item>
      rows = cityList.map((item, index) => {
        return (
          <Dropdown.Item
            key={item}
            name={item}
            onClick={(e) => {
              e.preventDefault();
              setSelectedCity(item);
              refetchVehicleDetails(item);
            }}
          >
            {item}
          </Dropdown.Item>
        );
      });
    } else {
      rows = [];
    }
    return (
      <>
        <DropdownButton
          id="dropdown-basic-button"
          title={
            selectedCity !== "Select WareHouse"
              ? selectedCity
              : "Select WareHouse"
          }
          size="sm"
          variant="secondary"
          onSelect={() => {}}
        >
          {rows}
        </DropdownButton>
      </>
    );
  };

  const FilterRoutesCheckbox = () => {
    return (
      <>
        <input
          style={{ height: "15px", width: "15px" }}
          type="checkbox"
          id="routeFilter"
          name="routeFilter"
          value="routeFilter"
          checked={showOnlyAllocatedRoutes}
          onChange={(event) => {
            setShowOnlyAllocatedRoutes(event.target.checked);
          }}
        />
        <label
          style={{ marginLeft: "5px", fontWeight: "bold" }}
          htmlFor="routeFilter"
        >
          {" "}
          List only allocated routes
        </label>
      </>
    );
  };

  return (
    <>
      <NavBar />
      <div style={{ paddingTop: 100 }}>
        <div className="row">
          <div style={{ marginRight: 10 }}>
            <InputGroup size="sm" style={{ marginLeft: 20 }}>
              <InputGroupAddon addonType="prepend">
                Delivery Date
              </InputGroupAddon>
              <DatePicker
                dateFormat={"dd/MM/yyyy"}
                selected={routeDate}
                onChange={handleChange}
                minDate={(JSON.parse(sessionStorage.getItem(`user`))[0].user_type == 'sz-admin') ?  subDays(new Date(), 7) : null} 
              />
            </InputGroup>
          </div>
          {cityList.length > 0 ? (
            <CityDropdown />
          ) : loading ? (
            <div>Loading WareHouse details...</div>
          ) : (
            <div>No WareHouse Found</div>
          )}
          <div className="col-md-2">
            <FilterRoutesCheckbox />
          </div>
          <div className="col-md-2">
            {selectedCity === "Select WareHouse" ? (
              <div></div>
            ) : routeVehicleListToRender.length === 0 ? (
              <div>Loading vehicle details...</div>
            ) : (
              <VehicleDropdown />
            )}
          </div>

          <div style={{ marginRight: 10 }}>
            <Button
              variant="primary"
              onClick={(e) => {
                // console.log("summary btn clicked");
                // props.history.push("/delivery-summary");
                window.open("/delivery-summary", "_blank");
              }}
            >
              Summary
            </Button>
          </div>
          <div style={{ marginRight: 5 }}>
            <Button
              variant="primary"
              onClick={(e) => {
                // console.log("summary btn clicked");
                // props.history.push("/delivery-summary");
                window.open("/driver-performance", "_blank");
              }}
            >
              Driver Details
            </Button>
          </div>
          <div style={{ marginRight: 5 }}>
            <Button
              variant="primary"
              onClick={(e) => {
                // console.log("summary btn clicked");
                // props.history.push("/delivery-summary");
                window.open("/pending-escalations", "_blank");
              }}
            >
              Pending 2+
            </Button>
          </div>
        </div>
      </div>
      {}
      {selectedVehicleId === "All" ? (
        <AllVehicleMapOrders
          mapOrdersData={mapAllVehicleOrdersData}
          selectedWarehouse={selectedCity}
          routeDate={routeDate}
        />
      ) : selectedVehicleId != "Select Vehicle ID" &&
        mapOrdersData.length > 0 ? (
        <VehicleRoutingMapOrders
          mapOrdersData={mapOrdersData}
          orderListForSelectedRoute={orderListForSelectedRoute}
          selectedVehicleId={selectedVehicleId}
          routeDate={routeDate}
          driverInfo={driverInfo}
          refetchVehicleDetails={refetchVehicleDetails}
          cityList={cityList}
          selectedWarehouse={selectedCity}
          selectedRouteId={
            selectedVehicleId.split("-")[1]
              ? selectedVehicleId.split("-")[1]
              : null
          }
        />
      ) : null}
      <FooterComponent />
    </>
  );
};

export default VehicleRouting;
